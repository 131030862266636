import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_revive_ti = createIndicator({
    id: 'daddy960-revive-ti',
    displayName: '大戶散戶動能',
    enabledOn(symbol, data, channel) {
        return true;
        // 選擇權標碼字數大於8，故排除
        const isOption = symbol.length >= 8;
        // 證交所編列指數皆為英文，故排除
        const isAllEnglish = /^[A-Za-z]+$/.test(symbol);
        const prefix = symbol.substring(0, 2);
        const suffix = symbol.substring(2);
        const isStockFutures = data?.type !== channel.os_futures &&
            !isOption &&
            !isAllEnglish &&
            (/^[A-Z]{2}$/?.test(prefix) || suffix === 'F-1');
        return isStockFutures;
    },
    constructorScope: {
        init(context, inputcallback) {
            const ticker = this.PineJS.Std.ticker(context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#trade_info';
            context.new_sym(symbol, this.PineJS.Std.period(context));
        },
        main(context, inputcallback) {
            /*
              open: 'unfilled_lot_diff',
              high: 'unfilled_count_diff',
              low: 'filled_count_diff',
              close: 'filled_count_diff',
              volume: 'filled_count_diff',
            */
            context.select_sym(1);
            const o = this.PineJS.Std.open(context);
            const h = this.PineJS.Std.high(context);
            const l = this.PineJS.Std.low(context);
            return [o, l, h];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Area
                    plottype: 5,
                    trackPrice: false,
                    transparency: 60,
                    color: '#ff0022',
                },
                plot_1: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Area
                    plottype: 5,
                    trackPrice: false,
                    transparency: 60,
                    color: '#00ff00',
                },
                plot_2: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 2,
                    // Plot type is Line
                    plottype: 2,
                    trackPrice: false,
                    transparency: 30,
                    color: '#ffff00',
                },
            },
            // Precision is 0 digit, only integer
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '大戶委託口差',
                histogramBase: 0,
            },
            plot_1: {
                title: '散戶成交筆差',
                histogramBase: 0,
            },
            plot_2: {
                title: '市場委託口差',
                histogramBase: 0,
            },
        },
        inputs: [],
    },
});

import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { VscDebugRestart } from 'react-icons/vsc';
import { scrollbar2Css } from '~/css/scrollbarCss';
//bumpy
const masterTeachingStore = proxy({
    /** 波動 `發散|收斂` */
    bumpy: '',
    /** 方向 `多|中性|空` */
    type: '',
    /** 週期 `長期|短期` */
    interval: '',
    /** step */
    step: 0,
});
export const MasterTeaching = memo(function MasterTeaching() {
    const state = useSnapshot(masterTeachingStore);
    const selected = state.bumpy !== '' && state.type !== '' && state.interval !== '';
    const selectedAnswer = selected && masterAnswer.bumpy[state.bumpy]?.[state.type]?.[state.interval];
    const [updatedAnimation, setUpdatedAnimation] = useState(false);
    useEffect(() => {
        if (selected) {
            setUpdatedAnimation(true);
        }
        setTimeout(() => {
            setUpdatedAnimation(false);
        }, 500);
    }, [state.bumpy, state.type, state.interval, selected]);
    return (<classes.container>
      <classes.question.container>
        <classes.question.title>Q1.您認為接下來波動會如何發展?</classes.question.title>
        <classes.question.selecter selected={state.step === 1}>
          <div>波動</div>
          <Button onClick={() => {
            masterTeachingStore.bumpy = 'divergence';
        }} color={state.bumpy === 'divergence' ? 'cyan' : 'gray'}>
            由小變大
          </Button>
          <Button onClick={() => {
            masterTeachingStore.bumpy = 'convergence';
        }} color={state.bumpy === 'convergence' ? 'cyan' : 'gray'}>
            由大變小
          </Button>
        </classes.question.selecter>
        <classes.question.title>Q2.您認為接下來指數方向會偏哪個方向?</classes.question.title>
        <classes.question.selecter selected={state.step === 1}>
          <div>方向</div>
          <Button onClick={() => {
            masterTeachingStore.type = 'long';
        }} color={state.type === 'long' ? 'red' : 'gray'}>
            多
          </Button>
          <Button onClick={() => {
            masterTeachingStore.type = 'bumpy';
        }} color={state.type === 'bumpy' ? 'cyan' : 'gray'}>
            中性
          </Button>
          <Button onClick={() => {
            masterTeachingStore.type = 'short';
        }} color={state.type === 'short' ? 'green' : 'gray'}>
            空
          </Button>
        </classes.question.selecter>
        <classes.question.title>Q3.您認為個狀況會持續多久?</classes.question.title>
        <classes.question.selecter selected={state.step === 1}>
          <div>週期</div>
          <Button onClick={() => {
            masterTeachingStore.interval = 'longTerm';
        }} color={state.interval === 'longTerm' ? 'cyan' : 'gray'}>
            長
          </Button>
          <Button onClick={() => {
            masterTeachingStore.interval = 'shortTerm';
        }} color={state.interval === 'shortTerm' ? 'cyan' : 'gray'}>
            短
          </Button>
        </classes.question.selecter>
      </classes.question.container>

      <classes.answer.container>
        <classes.answer.text css={css `
            animation: ${updatedAnimation === true && fadeIn} 1.5s;
          `}>
          {selected ? selectedAnswer : '請依序選擇您的答案'}
        </classes.answer.text>
      </classes.answer.container>
      <classes.askAgain onClick={() => {
            masterTeachingStore.bumpy = '';
            masterTeachingStore.type = '';
            masterTeachingStore.interval = '';
            masterTeachingStore.step = 0;
        }}>
        <VscDebugRestart color='#ffffff'/>
        再問一次軍師
      </classes.askAgain>
      <classes.warring>
        注意：軍師的回答並非操作建議，而是幫助新手釐清自己的判斷要使用什麼策略會比較適合。
      </classes.warring>
    </classes.container>);
});
const fadeIn = keyframes `
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    question: {
        container: styled.div `
      ${fill_vertical_all_center};
      height: 260px;
      gap: 4px;
    `,
        title: styled.div `
      ${fill_horizontal_cross_center};
      height: 32px;
    `,
        selecter: styled.div `
      ${fill_horizontal_cross_center};
      height: 40px;
      gap: 8px;
      animation: ${fadeIn} 1.2s linear forwards;
      background-color: ${props => (props.selected ? '#252525' : '#121212')};
      border-radius: 8px;
      padding: 0 16px;
    `,
    },
    answer: {
        container: styled.div `
      ${fill_vertical_cross_center};
      height: calc(100% - 356px);
      background-color: #252525;
      border-radius: 8px;
      transition: 1s;
    `,
        text: styled.div `
      ${scrollbar2Css};
      ${fill_vertical_cross_center};
      white-space: pre-wrap;
      padding: 8px;
    `,
    },
    askAgain: styled.div `
    ${fill_horizontal_all_center};
    width: auto;
    height: 32px;
    padding: 0 8px;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
      background-color: #343434;
    }
  `,
    warring: styled.div `
    ${fill_horizontal_all_center};
    height: 40px;
    font-size: 14px;
  `,
};
/** 波動放大-看多-長週期 */
const answer_divergence_long_longTerm = '\n你的判斷是:波動由小變大、方向偏多、剩餘時間長。\n\n買進買權，可考慮價外的履約價。\n如果有把握波動會越來越大，可以考慮做更價外的履約價，或是之後隨著行情發展可以平倉已獲利的買方部位並改買新的價外履約價。\n想要用垂直價差來取代買方的交易者，可以考慮做買權看多價差，但要注意！雖然需要支出的權利金變少，但行情若持續大漲，你的獲利是有限的';
/** 波動放大-看多-短週期 */
const answer_divergence_long_shortTerm = '你的判斷是:波動由小變大、方向偏多、剩餘時間短。\n\n買進買權，可考慮接近價平的履約價。\n因為剩餘的時間不多，價外履約價的成功機率不高。\n想要用垂直價差來取代買方的交易者，考量到流動性的問題(成交量)，一般來說會選擇賣權看多價差。';
/** 波動放大-中性-長週期 */
const answer_divergence_bumpy_longTerm = '\n你的判斷是:波動由小變大、方向中性、剩餘時間長。\n\n雙買，可考慮價外的履約價，也就是所謂的買進勒式。\n如果判斷是超大行情，可以做得更價外。';
/** 波動放大-中性-短週期 */
const answer_divergence_bumpy_shortTerm = '\n你的判斷是:波動由小變大、方向中性、剩餘時間短。\n\n雙買，可考慮價平的履約價，也就是所謂的買進跨式。\n因為剩餘的時間不多，價外履約價的成功機率不高。\n如果是結算日當天，與其雙買倒不如等行情突破後再選邊站。延伸閱讀：結算日買方當沖(https://opkevin.cc/expiry-day-trading)';
/** 波動放大-看空-長週期 */
const answer_divergence_short_longTerm = '\n你的判斷是:波動由小變大、方向偏空、剩餘時間長。\n\n買進賣權，可考慮較價外的履約價。\n如果有把握波動會越來越大，可以考慮做更價外的履約價，或是之後隨著行情發展可以平倉已獲利的買方部位並改買新的價外履約價。\n想要用垂直價差來取代買方的交易者，可以考慮做賣權看空價差，但要注意！雖然需要支出的權利金變少，但行情若持續大跌，你的獲利是有限的。';
/** 波動放大-看空-短週期 */
const answer_divergence_short_shortTerm = '\n你的判斷是:波動由小變大、方向偏空、剩餘時間短。\n\n買進賣權，可考慮價平的履約價。\n因為剩餘的時間不多，價外履約價的成功機率不高。\n想要用垂直價差來取代買方的交易者，考量到流動性的問題(成交量)，一般來說會選擇買權看空價差。';
/** 波動收斂-看空-長週期 */
const answer_convergence_short_longTerm = '\n你的判斷是:波動由大變小、方向偏空、剩餘時間長。\n\n賣出買權，價平或價外都可以，但價外對賣方而言勝率較高，且剩餘時間多還有時間價值可賺，可考慮價外的履約價。\n以買權看空價差取代賣出買權，可預防突發性的意外。\n注意！如果波動是由大變小的初始階段，因為波動還很高的情況下指數漲跌可能還很劇烈，做純賣方仍有風險，可以等到波動再降一些時賣方再進場，或者用價差取代。\n波動由大變小的初始階段，願意多冒一點風險可以考慮用買進賣權來取代賣出買權，偷吃一點豆腐，若成功吃到豆腐後要記得停利平倉，或是轉變成價差。';
/** 波動收斂-看空-短週期 */
const answer_convergence_short_shortTerm = '\n你的判斷是:波動由大變小、方向偏空、剩餘時間短。\n\n賣出買權，價平或價外都可以，但價外履約價如果獲利空間不足，那就只能考慮價平附近的履約價了。\n以買權看空價差取代賣出買權，可預防突發性的意外，但如果獲利空間不夠大，不需要硬做。';
/** 波動收斂-中性-長週期 */
const answer_convergence_bumpy_longTerm = '\n你的判斷是:波動由大變小、方向中性、剩餘時間長。\n\n雙賣，價平或價外都可以，但價外對賣方而言勝率較高，且剩餘時間多還有時間價值可賺，可考慮價外的履約價，也就是所謂的賣出勒式。\n以兀鷹取代雙賣，可預防突發性的意外。\n注意！如果波動是由大變小的初始階段，因為波動還很高的情況下指數漲跌可能還很劇烈，做純賣方仍有風險，可以等到波動再降一些時賣方再進場，或者用兀鷹取代。延伸閱讀：兀鷹介紹(https://opkevin.cc/butterfly-condor-jadelizard)';
/** 波動收斂-中性-短週期 */
const answer_convergence_bumpy_shortTerm = '\n你的判斷是:波動由大變小、方向中性、剩餘時間短。\n\n雙賣，價平或價外都可以，但價外履約價如果獲利空間不足，那就只能考慮價平附近的履約價了，如果是價平也就是所謂的賣出跨式。\n以蝴蝶取代雙賣，可預防突發性的意外。\n注意！如果波動是由大變小的初始階段，因為波動還很高的情況下指數漲跌可能還很劇烈，做純賣方仍有風險，可以等到波動再降一些時賣方再進場，或者用蝴蝶取代。延伸閱讀：蝴蝶介紹(https://opkevin.cc/butterfly-condor-jadelizard)';
/** 波動收斂-看多-長週期 */
const answer_convergence_long_longTerm = '\n你的判斷是:波動由大變小、方向偏多、剩餘時間長。\n\n賣出賣權，價平或價外都可以，但價外對賣方而言勝率較高，且剩餘時間多還有時間價值可賺，可考慮價外的履約價。\n以賣權看多價差取代賣出賣權，可預防突發性的意外。\n注意！如果波動是由大變小的初始階段，因為波動還很高的情況下指數漲跌可能還很劇烈，做純賣方仍有風險，可以等到波動再降一些時賣方再進場，或者用價差取代。\n波動由大變小的初始階段，願意多冒一點風險可以考慮用買進買權來取代賣出賣權，偷吃一點豆腐，若成功吃到豆腐後要記得停利平倉，或是轉變成價差。';
/** 波動收斂-看多-短週期 */
const answer_convergence_long_shortTerm = '\n你的判斷是:波動由大變小、方向偏多、剩餘時間短。\n\n賣出賣權，價平或價外都可以，但價外履約價如果獲利空間不足，那就只能考慮價平附近的履約價了。\n以賣權看多價差取代賣出賣權，可預防突發性的意外，但如果獲利空間不夠大，不需要硬做。';
const masterAnswer = {
    bumpy: {
        divergence: {
            long: {
                longTerm: answer_divergence_long_longTerm,
                shortTerm: answer_divergence_long_shortTerm,
            },
            bumpy: {
                longTerm: answer_divergence_bumpy_longTerm,
                shortTerm: answer_divergence_bumpy_shortTerm,
            },
            short: {
                longTerm: answer_divergence_short_longTerm,
                shortTerm: answer_divergence_short_shortTerm,
            },
        },
        convergence: {
            long: {
                longTerm: answer_convergence_long_longTerm,
                shortTerm: answer_convergence_long_shortTerm,
            },
            bumpy: {
                longTerm: answer_convergence_bumpy_longTerm,
                shortTerm: answer_convergence_bumpy_shortTerm,
            },
            short: {
                longTerm: answer_convergence_short_longTerm,
                shortTerm: answer_convergence_short_shortTerm,
            },
        },
    },
};

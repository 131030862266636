import styled from '@emotion/styled';
import { memo } from 'react-tracked';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol';
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import ThemeLayout from '~/components/theme/ThemeLayout';
import SimpleQuoteBodyItem from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { css } from '@emotion/react';
import { createTheme, Paper } from '@mui/material';
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        text: { primary: '#333333' },
        background: { paper: '#eeeeee' },
    },
});
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: { primary: '#ffffff' },
        background: { paper: '#222222' },
    },
});
const styledScrollBarCss = css `
  ${scrollbar2Css}/* height: calc(100% - 20px); */
`;
export const SimpleQuoteList = memo(function SimpleQuoteListCombinedWithfirebaseSymbol(props) {
    const currentSymbol = useChartingSymbol(props.chart)();
    const changeIndicators = useChartingChangeIndicatorsFn(props.chart);
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart);
    const showStockFutures = props.showStockFutures ?? true;
    return (<ThemeLayout lightTheme={lightTheme} darkTheme={darkTheme}>
      <div css={css `
          ${flex.v.default};
          width: 100%;
          height: 100%;
        `}>
        <DefaultTableHeader />
        {props.quoteValue.length === 0 ? (<div css={css `
              ${flex.v.allCenter};
              width: 100%;
              height: calc(100% - 20px);
            `}>
            沒有商品
          </div>) : (<div css={styledScrollBarCss}>
            {props.quoteValue?.map((data, index) => {
                const symbol = data?.symbol;
                return (<div key={index} onClick={event => {
                        props.handleClick?.(symbol);
                        if (currentSymbol !== symbol) {
                            changeSymbolInterval({ symbol });
                        }
                        if (props.indicators) {
                            changeIndicators(props.indicators);
                        }
                    }}>
                  <SimpleQuoteBodyItem.Big currentSymbol={currentSymbol} symbol={symbol} showStockFutures={showStockFutures}/>
                </div>);
            })}
          </div>)}
      </div>
    </ThemeLayout>);
});
// 因為SimpleQuoteHeader會影響useSignalr2QuoteSort輸出標的的排序，所以額外拉出新的元件
const DefaultTableHeader = memo(function DefaultTableHeader() {
    return (<Paper variant={'outlined'} css={css `
        ${flex.h.crossCenter};
        width: 100%;
        height: 40px;
        cursor: pointer;
        font-size: 14px;
        user-select: none;
      `}>
      <_HeaderCell width={5}>{/* <VscDebugRestart size={'20px'} /> */}</_HeaderCell>
      <_HeaderCell width={22} name='商品'/>
      <_HeaderCell width={23} name='成交價'/>
      <_HeaderCell width={25} name='漲跌'/>
      <_HeaderCell width={26} name='漲跌幅%'/>
    </Paper>);
});
const _HeaderCell = memo(function _HeaderCell({ width, clickDelegate, isSelected, arrow, name, children }) {
    return (<div css={css `
        ${flex.h.allCenter};
        width: ${width}%;
        text-decoration: ${isSelected ? 'underline' : ''};
        text-decoration-color: ${isSelected ? '#ffffff' : '#777777'};
        &:hover {
          border-radius: 5px;
        }
      `} onClick={clickDelegate}>
      {name}
      {arrow}
      {children}
    </div>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #181a21;
    gap: 4px;
    padding: 8px;
  `,
    symbolList: styled.div `
    ${scrollbar2Css};
    height: calc(50% - 128px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
    padding: 2px;
    align-content: flex-start;
  `,
    symbolItem: styled.div `
    flex: 1 0 calc(50% - 4px);
    height: 102px;
    border-radius: 6px;
    background-color: #1f222b;
    max-width: 50%;
  `,
    sortContent: styled.div `
    ${fill_horizontal_cross_center};
    gap: 12px;
    height: 32px;
    background-color: #23262f;
    padding: 0 4px;
    border-radius: 4px;
    user-select: none;
    font-size: 14px;
    & > div {
      ${fill_horizontal_cross_center};
      width: auto;
      font-size: 14px;
    }
  `,
    symbolTitle: styled.div `
    ${fill_horizontal_all_center};
    height: 24px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    background-color: ${props => props.color};
  `,
};

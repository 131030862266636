import styled from '@emotion/styled';
import { memo, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import StockKbarBoxView from '~/pages/freeman/component/stockbarBoxView';
import StockContribtuionTable from '~/pages/futuresai/monitors/component/StockContribtuionTable';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
export const ValueStocksContent = memo(function ValueStocksContent() {
    const state = useSnapshot(staticStore);
    const twseWeight = useStockWeightResource('TWSE');
    const tw50Stock = state.symbol.tw50;
    const tw50Data = useMemo(() => {
        return twseWeight
            .filter(([k]) => tw50Stock?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [tw50Stock, twseWeight]).slice(0, 12);
    const tw50Symbol = tw50Data.map(data => data.symbol) ?? [''];
    const tw50Value = signalrHooks2.useQuotes(tw50Symbol);
    useEffect(() => {
        signalrStore2.addQuote(tw50Symbol);
        return () => {
            signalrStore2.removeQuote(tw50Symbol);
        };
    }, [JSON.stringify(tw50Symbol)]);
    return (<classes.container>
      <classes.header>權值股前十大排行</classes.header>
      <classes.body.stockBar>
        <StockKbarBoxView quote={tw50Value}/>
      </classes.body.stockBar>
      <classes.header>點數貢獻排行</classes.header>
      <classes.body.table>
        <StockContribtuionTable.Display limit={15}/>
      </classes.body.table>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    header: styled.div `
    ${fill_horizontal_all_center};
    height: 28px;
    background-color: #343948;
    border-radius: 4px;
    font-size: 14px;
  `,
    body: {
        stockBar: styled.div `
      height: 216px;
      width: 100%;
      border-radius: 4px;
    `,
        table: styled.div `
      ${scrollbar2Css};
      height: calc(100% - 280px);
    `,
    },
};

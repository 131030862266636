import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { yaya168Stock_initStrategies } from './yaya168Stock_initStrategies';
import { yaya168Stock_initStyling } from './yaya168Stock_initStyling';
import { Yaya168Stock_SidePane1 } from './yaya168Stock_SidePane1';
import { Yaya168Stock_SidePane2 } from './yaya168Stock_SidePane2';
import { Yaya168Stock_TopBar } from './yaya168Stock_TopBar';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
export const yaya168Stock_init = (templateProps) => {
    yaya168Stock_initStyling(templateProps);
    yaya168Stock_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/yaya168/loginView.jpg'] }]}/>);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../yaya168/chartingTheme.css',
    };
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web_stock');
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: 'D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        overrides: store.charting.darkOverrides,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: false,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 336px 1fr;

    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Drawer2 Chart'
      'Drawer1 Drawer2 Chart';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Yaya168Stock_TopBar;
    templateProps.layout.Drawer1 = Yaya168Stock_SidePane1;
    templateProps.layout.Drawer2 = Yaya168Stock_SidePane2;
    templateProps.hooks.add(useSignalrStart2_1);
};

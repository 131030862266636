import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { edwinGroupName } from '../WatchListGroupName';
import KbarWatchListQuote from '../quote/KbarWatchListQuote';
import { proxy, useSnapshot } from 'valtio';
import { sortBy } from 'lodash';
import dayAPI from '~/utils/dayAPI';
import { Button } from '@mantine/core';
import { edwin_store } from '../../edwin_store';
import { css } from '@emotion/react';
export const useBig3StockDataStore = proxy({
    sortKey: 'none',
    orderKey: 'desc',
});
export const WatchListContent = memo(function WatchListContent(props) {
    const edwinState = useSnapshot(edwin_store);
    const state = useSnapshot(useBig3StockDataStore);
    const value = props.quoteValue;
    const data = props.data;
    const watchGroup = edwinState.watchGroup;
    const upDate = dayAPI(data.map(s => s.date)[0]).format('YYYY-MM-DD');
    const newData = value.map(s => {
        const matchingData = data.find(item => item.symbol === s.symbol);
        if (matchingData) {
            return {
                signalr: s,
                big3: matchingData,
            };
        }
        else {
            return {
                signalr: s,
                big3: {
                    date: '',
                    symbol: s.symbol,
                    foreigner_diff_volume: NaN,
                    trust_diff_volume: NaN,
                    dealer_diff_volume: NaN,
                    dealer_hedge_diff_volume: NaN,
                    big3_diff_volume: NaN,
                    foreigner_diff_value: NaN,
                    trust_diff_value: NaN,
                    dealer_diff_value: NaN,
                    dealer_hedge_diff_value: NaN,
                    big3_diff_value: NaN,
                },
            };
        }
    });
    //這邊是做另一種資料排序
    //外資與投信買賣超排序
    const sortData = () => {
        if (state.sortKey === 'foreigner' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.big3.foreigner_diff_value);
        }
        if (state.sortKey === 'foreigner' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.big3.foreigner_diff_value).reverse();
        }
        if (state.sortKey === 'trust' && state.orderKey === 'asc') {
            return sortBy(newData, datum => datum.big3.trust_diff_value);
        }
        if (state.sortKey === 'trust' && state.orderKey === 'desc') {
            return sortBy(newData, datum => datum.big3.trust_diff_value).reverse();
        }
        return newData;
    };
    return (<styleds.container>
      <styleds.updateContent>籌碼資料日期: {upDate}</styleds.updateContent>
      <styleds.header>
        <div css={css `
            display: flex;
            gap: 4px;
          `}>
          {edwinGroupName.map(name => {
            return (<Button key={name} onClick={() => (edwin_store.watchGroup = name)} size='xs' color={edwinState.watchGroup === name ? 'yellow' : 'gray'}>
                群組{name.slice(-1)}
              </Button>);
        })}
        </div>
        <KbarWatchListQuote.header />
      </styleds.header>
      <styleds.body>
        {sortData()?.map((quote, index) => {
            return (<KbarWatchListQuote.body data={quote.big3} key={index} quote={quote.signalr}/>);
        })}
      </styleds.body>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    updateContent: styled.div `
    ${fill_horizontal_cross_center};
    height: 18px;
    line-height: 18px;
    font-size: 13px;
  `,
    header: styled.div `
    width: 100%;
    height: 64px;
    flex-shrink: 0;
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: calc(100% - 72px);
    gap: 2px;
  `,
};

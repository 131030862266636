import { weng888_Kbar } from '~/trades/indicators/weng888/weng888_Kbar';
import { store } from '~/pages/heineken_template/_private/store';
import { weng888_dayTrade } from '~/trades/indicators/weng888/weng888_dayTrade';
import { weng888_dayTradeKbar } from '~/trades/indicators/weng888/weng888_dayTradeKbar';
import { stock_signal } from '~/trades/indicators/weng888/stock_signal';
import { weng888_dayPower } from '~/trades/indicators/weng888/weng888_dayPower';
import { weng888_gapTrade } from '~/trades/indicators/weng888/weng888_gapTrade';
import { weng888_bandTrade } from '~/trades/indicators/weng888/weng888_bandTrade';
import { stock_big3 } from '~/trades/indicators/futuresai/stock_chips/stock_big3';
import { stock_dealer } from '~/trades/indicators/futuresai/stock_chips/stock_dealer';
import { stock_foreigner } from '~/trades/indicators/futuresai/stock_chips/stock_foreigner';
import { stock_trust } from '~/trades/indicators/futuresai/stock_chips/stock_trust';
// 日線級別才有三法指標
export const weng888Stock_daysBandTrade = [weng888_dayPower, weng888_Kbar, stock_big3];
export const weng888Stock_hoursBandTrade = [weng888_dayPower, weng888_Kbar];
// 日線級別才有三法指標
export const weng888Stock_holdingTrade = [weng888_dayPower, weng888_bandTrade, stock_big3];
export const weng888Stock_dayTrade = [weng888_dayTrade, weng888_dayTradeKbar];
export const weng888Stock_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '市場熱度(日)',
            indicators: weng888Stock_daysBandTrade,
            interval: '1D',
        },
        {
            displayName: '市場熱度(60)',
            indicators: weng888Stock_hoursBandTrade,
            interval: '60',
        },
        {
            displayName: '當沖',
            indicators: weng888Stock_dayTrade,
            interval: '3',
        },
    ],
};
export const weng888Stock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...weng888Stock_daysBandTrade,
        ...weng888Stock_dayTrade,
        ...weng888Stock_holdingTrade,
        ...[stock_signal, weng888_gapTrade, stock_dealer, stock_foreigner, stock_trust],
    ];
    // 預設顯示
    store.charting.indicators = weng888Stock_daysBandTrade;
    store.charting.symbol = 'TSEA';
    store.charting.interval = '1D';
    store.charting.initStrategy({
        configs: [...weng888Stock_strategiesGroup.main],
    });
};

import styled from '@emotion/styled';
import { Alert, Snackbar } from '@mui/material';
import { memo, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { NotifyModule } from '~/modules/SDK/NotifyModule/NotifyModule';
import dayAPI from '~/utils/dayAPI';
import { MessageModal } from './MessageModal';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { css } from '@emotion/react';
const notifyMessageStore = proxy({
    index: 0,
});
export const MessageTimeButton = memo(function TimeButtom(props) {
    const notifyMessageState = useSnapshot(notifyMessageStore);
    const onState = props.messageNumber === notifyMessageState.index;
    const theme = useThemeStore(t => t.theme);
    const indexNumber = notifyMessageStore.index + 1;
    return (<classes.messageSelect selected={onState} className={theme} onClick={() => (notifyMessageStore.index = props.messageNumber)}>
      第{indexNumber}則訊息({props.timeText})
    </classes.messageSelect>);
});
const notify1 = new NotifyModule({ name: 'notify_morerich' });
export const ManualSignalMain = memo(function ManualSignalMain(props) {
    const { isPc } = useMedia();
    notify1.store.useLogs({ agent: props.agent, limit: props.limit }, { pollingInterval: 5000 });
    const modal = props.modal ?? false;
    const theme = useThemeStore(t => t.theme);
    const notifyState = useSnapshot(notify1.store);
    //------------------------------------------------------
    /** 過濾訊息:只顯示當日訊息 */
    const messageContext = notifyState.logs.filter(datum => dayAPI(datum.datetime).format('MM-DD') === dayAPI().format('MM-DD'));
    /** 狀態:目前選取陣列中第幾則訊息 limit=5 0~4 */
    const notifyMessageStateNumber = useSnapshot(notifyMessageStore);
    /** HH:mm 時間格式 */
    const notifyTime = notifyState.logs.map(datum => dayAPI(datum.datetime).format('HH:mm'));
    /** 訊息內容 */
    const notifyMessageContent = messageContext.map(datum => datum.content);
    //------------------------------------------------------
    const [snackbarState, setSnackbarState] = useState(false);
    const [lastTime, setLastTime] = useState(notifyTime[0]);
    //一開始載入網頁時不跳出mui通知 只在最新發訊時間變化時跳出通知
    if (lastTime !== notifyTime[0]) {
        setLastTime(notifyTime[0]);
        //lastTime初始為undefined
        lastTime !== undefined && setSnackbarState(true);
        notifyMessageStore.index = 0;
    }
    return (<classes.container>
        <classes.messageSelecter className={theme}>
          {messageContext.map((datum, index) => {
            return (<MessageTimeButton key={index} messageNumber={index} timeText={dayAPI(datum.datetime).format('HH:mm')}/>);
        })}
        </classes.messageSelecter>
        <classes.messageDisplay>
          {messageContext.length === 0
            ? '目前還沒有訊息'
            : notifyMessageContent[notifyMessageStateNumber.index]}

          {modal === true && isPc && (<MessageModal time={messageContext.map(s => dayAPI(s.datetime).format('YYYY-MM-DD HH:mm'))[notifyMessageStateNumber.index]} text={messageContext.length === 0
                ? '目前還沒有訊息'
                : notifyMessageContent[notifyMessageStateNumber.index]}>
              操作提示
            </MessageModal>)}
        </classes.messageDisplay>

        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={snackbarState} autoHideDuration={6000} onClose={() => setSnackbarState(false)} key={'bottom' + 'left'}>
          <Alert severity='info'>{'時間' + notifyTime[0] + ' 您有新的訊息'}</Alert>
        </Snackbar>
      </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
  `,
    messageSelecter: styled.div `
    ${fill_horizontal_cross_center};
    gap: 4px;
    height: 40px;
    padding: 4px;
    &.dark {
      background-color: #565656;
    }
    &.light {
      background-color: #dedede;
    }
  `,
    messageDisplay: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    height: (100% - 40px);
    padding: 2px;
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
    &.dark {
      color: #dddddd;
    }
    &.light {
      color: #252525;
    }
  `,
    messageSelect: styled.div `
    ${fill_horizontal_all_center};
    width: auto;
    height: 30px;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    font-size: 14px;
    &.dark {
      border: 1px solid ${props => (props.selected === true ? '#cccccc' : '#2a2d2f')};
      &:hover {
        border: 1px solid #ffffff;
      }
    }
    &.light {
      border: 1px solid ${props => (props.selected === true ? '#454545' : '#909090')};
      &:hover {
        border: 1px solid #252525;
      }
    }
  `,
};
const baseStyled = css `
  ${classes.container};
  ${classes.messageSelecter};
  ${classes.messageDisplay};
  ${classes.messageSelect};
`;

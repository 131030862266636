import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@mantine/core';
import { memo } from 'react';
import { ENV } from '~/configs/ENV';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import dayAPI from '~/utils/dayAPI';
/** 需要抽出當共用funtcion */
const getMatchedSubscription = (subscriptions, agentName, // target name
product) => {
    for (const subscription of subscriptions) {
        if (subscription.agentName === agentName && subscription.productName === product) {
            return subscription;
        }
    }
    return null;
};
export const EdwinLogOutView = memo(function EdwinLogOutView() {
    const firebaseMe = useFirebaseMeState.useContainer();
    const { meUserState } = useMeStore();
    /** 產品名稱 */
    const agentName = useMeStore(state => state.agentName);
    /** product名稱 */
    const produtName = useMeStore(state => state.product);
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    /** 會員代碼 */
    const userNumber = useMeStore(state => state.meUserState?.code);
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email);
    const photoUrl = useMeStore(state => state.meUserState?.photo);
    /** 會員大頭貼 */
    const userPicture = (<Avatar src={photoUrl} size={48} radius='lg' alt="it's me"/>);
    let matchedSubscription = null;
    if (meUserState?.subscriptions) {
        matchedSubscription = getMatchedSubscription(meUserState?.subscriptions, agentName, produtName);
    }
    /** 到期日期 */
    const expDate = matchedSubscription && dayAPI(matchedSubscription.expiredAt).format('YYYY-MM-DD');
    const _appenv = ENV;
    const commitHash = _appenv.COMMITHASH.length > 6 ? _appenv.COMMITHASH.substring(0, 4) : _appenv.COMMITHASH;
    return (<styleds.container>
      <styleds.topContent>
        <span>{userPicture}</span>
        <span>{userName}</span>
        <span>{email}</span>
      </styleds.topContent>
      <styleds.infoContent>
        <span>會員代碼 {userNumber}</span>
        {/* <span>到期日期 {expDate ?? '體驗中'}</span> */}
        <span>
          訂閱查詢&nbsp;
          <a href='https://user.oen.tw/dashboard?from=edwin-invest' target={'_blank'} rel='noreferrer' css={css `
              color: #5555ff;
            `}>
            https://user.oen.tw
          </a>
        </span>
        <span>
          產品名稱 {agentName}_{produtName}
        </span>
        <span>版本編號 {_appenv.COMMITHASH}</span>
        <styleds.logoutButton onClick={() => firebaseMe.acts.logout()}>登出</styleds.logoutButton>
      </styleds.infoContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    width: 280px;
    height: 360px;
    border-radius: 10px;
    gap: 12px;
    background: linear-gradient(45deg, #1f262f, #1f262f);
    color: #efefef;
  `,
    topContent: styled.div `
    ${fill_vertical_cross_center};
    width: 100%;
    height: 124px;
    padding: 8px;
    background-image: linear-gradient(90deg, #161b29dd, #161b29dd, #161b29dd),
      url('https://scontent.frmq3-2.fna.fbcdn.net/v/t39.30808-6/315226904_597606635454656_392760390531705972_n.jpg?_nc_cat=101&ccb=1-7&_nc_sid=5f2048&_nc_ohc=6Bh8hx5h7WMAX9xs3xi&_nc_ht=scontent.frmq3-2.fna&oh=00_AfA3NhlQ1MqzPyd1s7Z15-xzMT74jSahhawGb4NarQk4hQ&oe=653D1051');
    background-size: 100%;
    background-position: 50% 100%;
    border-radius: 10px 10px 0 0;
    & > span {
      padding: 4px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
    }
  `,
    infoContent: styled.div `
    ${fill_vertical_cross_center};
    height: 236px;
    gap: 4px;
    & > span {
      width: 186px;
      padding: 12px;
      font-size: 14px;
      line-height: 14px;
      margin: 0px;
      border-radius: 5px;
      transition: 0.3s;
      margin-left: 32px;
      &:hover {
        font-size: 14.8px;
        background: linear-gradient(45deg, #25262b00, #25262b, #25262b00);
        transition: 0.3s;
      }
    }
  `,
    logoutButton: styled.div `
    ${fill_horizontal_all_center};
    width: 240px;
    height: 32px;
    border-radius: 6px;
    background-color: #9b4137;

    &:hover {
      background-color: #9b4137aa;
      cursor: pointer;
    }
  `,
};

import styled from '@emotion/styled';
import { intersection, union } from 'lodash';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useDailyRankResource } from '~/modules/screener/containers/useStockScreenerResource';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useVirtualAccountOrderPre, useVirtualTransaction, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import { asiajyeStore } from '../asiajyeStock_Sidebar';
import { Radio } from '@mantine/core';
export const ConditionPro = memo(function ConditionPro() {
    const state = useSnapshot(asiajyeStore);
    /** 即時訊號商品 */
    const lastTradeString = useSnapshot(staticStore).tradedDate.day0.format('YYYY-MM-DD');
    const day = 3;
    const positionBeginDate = useSnapshot(staticStore).tradedDate.days[day].format('YYYY-MM-DD');
    // 一定是少兩天的資料，因為不可能當天就出場，隔天有出場訊號，再下一天部位才會出場。如果日期定太早，反而會撈到進場之前的翻單交易資料
    const positionExitBeginDate = useSnapshot(staticStore).tradedDate.days[day - 2].format('YYYY-MM-DD');
    // 選出當天符合標準，隔天進場的多方標的（也可能是當天符合標準，隔天出場的空方平倉標的）
    const todaylongEntrySymbolMacd = useVirtualAccountOrderPre('asiajye_stock_macd', 'B', {
        dateTime: lastTradeString,
    })?.map(a => a.symbol);
    const todaylongEntrySymbolOriginal = useVirtualAccountOrderPre('asiajye_stock_original', 'B', {
        dateTime: lastTradeString,
    })?.map(a => a.symbol);
    // 選出當天符合標準，隔天進場的空方標的（也可能是當天符合標準，隔天出場的多方平倉標的）
    const todayShortEntrySymbolMacd = useVirtualAccountOrderPre('asiajye_stock_macd', 'S', {
        dateTime: lastTradeString,
    })?.map(a => a.symbol);
    const todayShortEntrySymbolOriginal = useVirtualAccountOrderPre('asiajye_stock_original', 'S', {
        dateTime: lastTradeString,
    })?.map(a => a.symbol);
    // !多方標的
    // 1. 4天內有符合asiajye_stock_macd和asiajye_stock_original的多方標的
    const nearDayslongEntrySymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    const nearDayslongEntrySymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    // 2.選出3天內有符合asiajye_stock_macd和asiajye_stock_original但又出場(反向平倉)的多方標的
    // 2-1 已反向平倉的多方標的
    const nearDaysLongExitSymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionExitBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'C')
        .map(datum => datum.symbol);
    // console.log(
    //   'near2daysLongExitSymbolMacd',
    //   useVirtualTransaction('asiajye_stock_macd', {
    //     beginDatetime: positionExitBeginDate,
    //   })?.filter(datum => datum.symbol === '3042'),
    // )
    const nearDaysLongExitSymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionExitBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'C')
        .map(datum => datum.symbol);
    // 2-2 合併已反向平倉的多方標的和當天符合標準，隔天出場的多方平倉標的（聯集）
    const longExitSymbolMacd = union(nearDaysLongExitSymbolMacd, todayShortEntrySymbolMacd);
    const longExitSymbolOriginal = union(nearDaysLongExitSymbolOriginal, todayShortEntrySymbolOriginal);
    // 2-3 篩選出還有多方部位的標的
    const filteredNearDayslongEntrySymbolMacd = nearDayslongEntrySymbolMacd?.filter(symbol => !longExitSymbolMacd?.includes(symbol));
    const filteredNearDayslongEntrySymbolOriginal = nearDayslongEntrySymbolOriginal?.filter(symbol => !longExitSymbolOriginal?.includes(symbol));
    // 3. 合併今天預計進場和期間中還有多方部位的標的（聯集）
    const longEntrySymbolMacd = union(filteredNearDayslongEntrySymbolMacd, todaylongEntrySymbolMacd);
    const longEntrySymbolOriginal = union(filteredNearDayslongEntrySymbolOriginal, todaylongEntrySymbolOriginal);
    // 4. 期間中曾經符合asiajye_stock_macd和asiajye_stock_original且未出場多方標的（交集）
    const longEntrySymbol = intersection(longEntrySymbolMacd, longEntrySymbolOriginal);
    // const LONGEXIT = intersection(
    //   nearDayslongEntrySymbolMacd?.filter(symbol => nearDaysLongExitSymbolOriginal?.includes(symbol)),
    //   longEntrySymbolOriginal,
    // )
    // console.log('LONGEXIT', LONGEXIT)
    // !空方標的
    // 1. 4天內有符合asiajye_stock_macd和asiajye_stock_original的空方標的
    const nearDaysShortEntrySymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    const nearDaysShortEntrySymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionBeginDate,
    })
        ?.filter(datum => datum.bs === 'S' && datum.positionType === 'O')
        .map(datum => datum.symbol);
    // 2.選出3天內有符合asiajye_stock_macd和asiajye_stock_original但又出場(反向平倉)的空方標的
    // 2-1 已反向平倉的空方標的
    const nearDaysShortExitSymbolMacd = useVirtualTransaction('asiajye_stock_macd', {
        beginDatetime: positionExitBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'C')
        .map(datum => datum.symbol);
    const nearDaysShortExitSymbolOriginal = useVirtualTransaction('asiajye_stock_original', {
        beginDatetime: positionExitBeginDate,
    })
        ?.filter(datum => datum.bs === 'B' && datum.positionType === 'C')
        .map(datum => datum.symbol);
    // 2-2 合併已反向平倉的空方標的和當天符合標準，隔天出場的空方平倉標的（聯集）
    const shortExitSymbolMacd = union(nearDaysShortExitSymbolMacd, todaylongEntrySymbolMacd);
    const shortExitSymbolOriginal = union(nearDaysShortExitSymbolOriginal, todaylongEntrySymbolOriginal);
    // 2-3 篩選出期間中還有空方部位的標的
    const filteredNear3daysShortEntrySymbolMacd = nearDaysShortEntrySymbolMacd?.filter(symbol => !shortExitSymbolMacd?.includes(symbol));
    const filteredNear3daysShortEntrySymbolOriginal = nearDaysShortEntrySymbolOriginal?.filter(symbol => !shortExitSymbolOriginal?.includes(symbol));
    // 3. 合併今天預計進場和期間中還有空方部位的標的（聯集）
    const shortEntrySymbolMacd = union(filteredNear3daysShortEntrySymbolMacd, todayShortEntrySymbolMacd);
    const shortEntrySymbolOriginal = union(filteredNear3daysShortEntrySymbolOriginal, todayShortEntrySymbolOriginal);
    // const EXITSHORT = intersection(
    //   nearDaysShortEntrySymbolMacd?.filter(symbol => nearDaysShortExitSymbolMacd?.includes(symbol)),
    //   shortEntrySymbolOriginal,
    // )
    // console.log('EXITSHORT', EXITSHORT)
    // 4. 期間中曾經符合asiajye_stock_macd和asiajye_stock_original且未出場空方標的（交集）
    const shortEntrySymbol = intersection(shortEntrySymbolMacd, shortEntrySymbolOriginal);
    const data = useDailyRankResource({
        date: lastTradeString,
        volume_gte: 3000,
        volume_lte: 0,
        amount_gte: 0,
        amount_lte: 0,
        sort_by: 'volume',
        limit: 2000,
    }, 0);
    const allStock = data.data?.map(a => a.symbol);
    const symbolLong = state.showProAllSymboll === true
        ? longEntrySymbol
        : intersection(allStock, longEntrySymbol).slice(0, 6);
    const symbolShort = state.showProAllSymboll === true
        ? shortEntrySymbol
        : intersection(allStock, shortEntrySymbol).slice(0, 6);
    return (<styleds.container>
      <styleds.switchContent>
        <span>標的模式</span>
        <styleds.radioContent>
          <Radio label='全部' color='indigo' checked={state.showProAllSymboll === true} onChange={() => (asiajyeStore.showProAllSymboll = true)}/>
          <Radio label='精選' color='indigo' checked={state.showProAllSymboll === false} onChange={() => (asiajyeStore.showProAllSymboll = false)}/>
        </styleds.radioContent>
      </styleds.switchContent>
      <styleds.listTitle fill='#c85d4a'>多方標的({symbolLong?.length})</styleds.listTitle>
      <styleds.listContent fill='#c85d4a33'>
        <SimpleQuoteListInteractWithChart data={symbolLong ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
      <styleds.listTitle fill='#009900'>空方標的({symbolShort?.length})</styleds.listTitle>
      <styleds.listContent fill='#00990033'>
        <SimpleQuoteListInteractWithChart data={symbolShort ?? []} chart={store.charting} noAutoSubscribe={false}/>
      </styleds.listContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    display: grid;
    grid-template-rows: 30px 30px calc(50% - 45px) 30px calc(50% - 45px);
  `,
    switchContent: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    font-size: 14px;
    font-weight: 600;
    padding: 0 8px;
  `,
    listTitle: styled.div `
    ${fill_horizontal_all_center};
    background-color: ${props => props.fill};
    color: #ffffff;
    border-radius: 4px;
  `,
    listContent: styled.div `
    height: 100%;
    background-color: ${props => props.fill};
  `,
    radioContent: styled.div `
    ${fill_horizontal_cross_center};
    width: 50%;
    padding: 0 4px;
    justify-content: end;
    gap: 24px;
  `,
};

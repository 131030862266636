import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, flex, } from '~/modules/AppLayout/FlexGridCss';
const styleds = {
    Sidebar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: 100%;
    gap: 4px;
    padding: 4px;
    background-color: #141723;
  `,
    Topbar: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
    background-color: #141723;
    border-bottom: 1px solid #444444;
    gap: 4px;
  `,
    //按鈕
    ButtonContent: styled.div `
    ${fill_horizontal_all_center}
    width: 100%;
    height: 32px;
    gap: 4px;
  `,
    //選股、自選股的外層
    ModeContent: styled.div `
    ${options => {
        const { isPc } = useMedia();
        const height = isPc === true ? '80' : '124';
        const baseCssset = css `
        ${fill_vertical_cross_center}
        height:calc(100% - ${height}px);
        gap: 4px;
      `;
        return css([baseCssset]);
    }}
  `,
    DataPickerContnet: styled.div `
    ${fill_horizontal_all_center}
    height: 32px;
    background-color: #2d2f37;
  `,
    DataPickerItem: styled.div `
    ${fill_horizontal_all_center};
    width: 50%;
  `,
    //空方商品、多方商品的外層,會有對應的背景顏色
    symbolGroupContnet: styled.div `
    ${props => {
        const baseCssset = css `
        ${fill_vertical_cross_center};
        border-radius: 7px;
      `;
        const longCssset = css `
        background-color: #2a262f;
      `;
        const shortCssset = css `
        background-color: #202a31;
      `;
        return css([
            baseCssset,
            props.variant === 'long' && longCssset,
            props.variant === 'short' && shortCssset,
        ]);
    }}
  `,
    //商品列
    SymbolList: styled.div `
    ${fill_vertical_cross_center}
    height:calc(100% - 30px);
  `,
    //除股追蹤多空方字樣
    HoldingPosition: styled.div `
    ${options => {
        const baseCssset = css `
        ${fill_horizontal_all_center}
        width: 54px;
        height: 28px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
      `;
        const longCssset = options.variant === 'long' &&
            options.active === false &&
            css `
          background-color: #552222;
          color: #777777;
        `;
        const shortCssset = options.variant === 'short' &&
            options.active === false &&
            css `
          background-color: #225522;
          color: #777777;
        `;
        const longActiveCssset = options.variant === 'long' &&
            options.active === true &&
            css `
          background-color: #994444;
          border: 1px solid #cccccc;
          color: #eeeeee;
        `;
        const shortActiveCssset = options.variant === 'short' &&
            options.active === true &&
            css `
          background-color: #449944;
          border: 1px solid #cccccc;
          color: #eeeeee;
        `;
        return css([baseCssset, longCssset, longActiveCssset, shortActiveCssset, shortCssset]);
    }}
  `,
    //標題
    Title: styled.div `
    ${props => {
        const baseCssset = css `
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 30px;
        color: rgb(255, 255, 255);
        border-radius: 5px 5px 0px 0px;
      `;
        const longCssset = css `
        background-color: rgb(255, 45, 55);
      `;
        const shortCssset = css `
        background-color: rgb(45, 185, 40);
      `;
        return css([
            baseCssset,
            props.variant === 'long' && longCssset,
            props.variant === 'short' && shortCssset,
        ]);
    }}
  `,
    OrderBoxContainer: styled.div `
    width: 100%;
    border-top: 1px solid #acacac;
    margin-top: 4px;
  `,
    OrderBoxTitle: styled.div `
    ${fill_horizontal_all_center};
    font-size: 14px;
    padding: 8px 0;
    height: 30px;
  `,
    OrderBoxBody: styled.div `
    width: 100%;
  `,
};
export default styleds;

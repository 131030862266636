import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
export const More888_Topbar = memo(function More888_Topbar() {
    const { isPc, isPhone, isPad } = useMedia();
    return (<div css={css `
        ${fill_horizontal_cross_center};
        justify-content: end;
        background: linear-gradient(157deg, #383a45, #383a45);
        padding: 0 4px;
      `}>
      {isPc && (<img css={css `
            width: 156px;
            height: 40px;
            margin-right: auto;
          `} src={`/more888/logo.png`}/>)}
      {/* {isPc && <FromOpenAccount />} */}
      {isPc && <ChartingServerSwitch charting={store.charting}/>}
      {isPc && <UserAvatarAsDialogButton />}
      {!isPc && (<Preset_Topbar showLeftBurger showRightBurger componentsInLeft={<div css={css `
                ${fill_horizontal_cross_center};
              `}>
              {!isPhone && (<img css={css `
                    width: 156px;
                    height: 40px;
                    margin-right: auto;
                  `} src={`/more888/logo.png`}/>)}
            </div>} componentsInRight={<div css={css `
                ${fill_horizontal_cross_center};
                justify-content: end;
                background: linear-gradient(157deg, #383a45, #383a45);
              `}>
              {/* {isPad && <FromOpenAccount />} */}
              <ChartingServerSwitch charting={store.charting}/>
              <UserAvatarAsDialogButton />
            </div>}/>)}
    </div>);
});

import { store } from '../heineken_template/_private/store';
import { edwin_volume } from '~/trades/indicators/edwin/edwin_volume';
import { edwin_macd } from '~/trades/indicators/edwin/edwin_macd';
import { edwin_indicators } from '~/trades/indicators/edwin/edwin_indicators';
import { edwin_evn } from '~/trades/indicators/edwin/edwin_evn';
import { edwin_ma } from '~/trades/indicators/edwin/edwin_ma';
import { edwin_foreigner } from '~/trades/indicators/edwin/edwin_foreigner';
import { edwin_trust } from '~/trades/indicators/edwin/edwin_trust';
import { edwin_dealer } from '~/trades/indicators/edwin/edwin_dealer';
import { edwin_indicators_short } from '~/trades/indicators/edwin/edwin_indicators_short';
export const edwin_strategiesGroup = {
    main: [
        {
            displayName: '強勢',
            indicators: [edwin_ma, edwin_indicators, edwin_evn, edwin_macd, edwin_volume],
            interval: '1D',
            panesRatio: 60,
        },
        {
            displayName: '弱勢',
            indicators: [edwin_ma, edwin_indicators_short, edwin_evn, edwin_macd, edwin_volume],
            interval: '1D',
            panesRatio: 60,
        },
    ],
};
export const edwin_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[
            edwin_indicators,
            edwin_indicators_short,
            edwin_ma,
            edwin_evn,
            edwin_macd,
            edwin_volume,
            edwin_foreigner,
            edwin_trust,
            edwin_dealer,
        ],
    ];
    store.charting.initStrategy({
        configs: [...edwin_strategiesGroup.main],
    });
};

import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const a2330_bandTrade = createIndicator({
    displayName: 'AI型態策略',
    id: 'a2330-bandTrade',
    constructorScope: {
        init(context, inputCallback) {
            const ticker = this.PineJS.Std.ticker(this._context)
                .toString()
                .replace(/[^\w-]/g, '');
            const symbol = ticker + '#big3_net_stock';
            this._context.new_sym(symbol, this.PineJS.Std.period(this._context));
        },
        main(context, inputCallback) {
            const i = context.new_unlimited_var(this._context.symbol.time);
            this._context.select_sym(1);
            const time1 = this._context.new_unlimited_var(this._context.symbol.time);
            const chip = this.PineJS.Std.close(this._context);
            const unlimited1 = this._context.new_unlimited_var(chip);
            // 三大法人買賣超
            const chips = unlimited1.adopt(time1, i, 0); // <=這是籌碼
            const chipsArray = this._context.new_var(chips);
            //主圖商品
            this._context.select_sym(0);
            this.PineJS.Std.period(this._context);
            const chipsSum3 = this.PineJS.Std.sum(chipsArray, 3, this._context);
            const interval = context.symbol.period;
            const high = this.PineJS.Std.high(this._context);
            const high_array = this._context.new_var(high);
            const highest30 = this.PineJS.Std.highest(high_array, 30, this._context);
            const low = this.PineJS.Std.low(this._context);
            const low_array = this._context.new_var(low);
            const lowest30 = this.PineJS.Std.lowest(low_array, 30, this._context);
            // 日線級別
            const isDayTrade = interval === '1D';
            // if (!isDayTrade) return null
            // 單日三大法人買買超
            const big3NetBuy = chipsArray.get(0) > 0;
            const big3NetSell = chipsArray.get(0) < 0;
            // 技術面：今日高/低點有突破30日最高/低價
            const priceLongBreakout = high >= highest30;
            const priceShortBreakout = low <= lowest30;
            // 是否進場狀態
            const marketposition = this._context.new_var();
            const entryPrice = this._context.new_var();
            // 加碼條件
            const targetPrice = this._context.new_var();
            // 減碼條件
            const stopLossPrice = this._context.new_var();
            const symbol = context.symbol.info?.ticker;
            const close = this.PineJS.Std.close(this._context);
            const close_array = this._context.new_var(close);
            const ma5 = this.PineJS.Std.sma(close_array, 5, this._context);
            const ma20 = this.PineJS.Std.sma(close_array, 20, this._context);
            const ma10 = this.PineJS.Std.sma(close_array, 10, this._context);
            const ma5_array = this._context.new_var(ma5);
            const ma20_array = this._context.new_var(ma20);
            const ma10_array = this._context.new_var(ma10);
            const open = this.PineJS.Std.open(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            const ma_volume = this.PineJS.Std.sma(volume_array, 15, this._context);
            marketposition.get(2);
            entryPrice.get(2);
            ma5_array.get(10);
            ma20_array.get(10);
            // 進場條件
            // 大於均線
            // 交易量濾網
            const long = close > ma5 &&
                ma5 > ma20 &&
                ma5 > ma10 &&
                close > open &&
                volume > ma_volume &&
                // ma5_array.get(0) > ma5_array.get(1) &&
                volume > 500;
            const short = close < ma5 &&
                ma5 < ma20 &&
                ma5 < ma10 &&
                close < open &&
                volume > ma_volume &&
                // ma5_array.get(0) < ma5_array.get(1) &&
                volume > 500;
            let buy_icon = NaN;
            let short_icon = NaN;
            let stopLongProfit = NaN;
            let stopShortProfit = NaN;
            if (long && !symbol?.includes('-')) {
                marketposition.set(1);
            }
            if (short && !symbol?.includes('-')) {
                marketposition.set(-1);
            }
            // 實際進場
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                stopLossPrice.set(close * 0.97);
                targetPrice.set(close * 1.05);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                stopLossPrice.set(close * 1.03);
                targetPrice.set(close * 0.95);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) === 1) {
                // 實際出場
                if (close < stopLossPrice.get(0) &&
                    close_array.get(0) < ma5_array.get(0)
                // &&
                // close_array.get(1) < ma5_array.get(1)
                ) {
                    marketposition.set(0);
                    targetPrice.set(0);
                    stopLossPrice.set(0);
                }
                // 如果尚未出場，且價格大於目標價，則設定停利價
                if (close > targetPrice.get(0) && marketposition.get(0) === 1) {
                    stopLossPrice.set(close * 0.97);
                    targetPrice.set(close * 1.05);
                    stopLongProfit = 1;
                }
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) === -1) {
                // 實際出場
                if (close > stopLossPrice.get(0) &&
                    close_array.get(0) > ma5_array.get(0)
                // &&
                // close_array.get(1) > ma5_array.get(1)
                ) {
                    marketposition.set(0);
                    targetPrice.set(0);
                    stopLossPrice.set(0);
                }
                // 如果尚未出場，且價格大於目標價，則設定停利價
                if (close < targetPrice.get(0) && marketposition.get(0) === -1) {
                    stopLossPrice.set(close * 1.03);
                    targetPrice.set(close * 0.95);
                    stopShortProfit = 1;
                }
            }
            const colorIndex = () => {
                if (marketposition.get(0) === 1)
                    return 0;
                else if (marketposition.get(0) === -1)
                    return 3;
                else
                    return 4;
            };
            return [
                ma5,
                buy_icon,
                short_icon,
                stopLongProfit,
                stopShortProfit,
                !symbol?.includes('-') ? colorIndex() : NaN,
            ];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: true,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'chars',
            },
            {
                id: '多空顏色',
                type: 'bar_colorer',
                palette: 'palette_0',
            },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 1,
                    visible: true,
                    linewidth: 1,
                    plottype: 0,
                    trackPrice: false,
                    transparency: 30,
                    color: '#5577ff',
                },
                plot_1: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#52a49a',
                    textColor: '#52a49a',
                    transparency: 0,
                    visible: true,
                },
                plot_3: {
                    color: '#FFD700',
                    textColor: '#ff1744',
                    transparency: 0,
                    visible: true,
                },
                plot_4: {
                    color: '#FFD700',
                    textColor: '#5cb642',
                    transparency: 0,
                    visible: true,
                },
            },
        },
        styles: {
            plot_0: {
                title: '日成本線',
                histogramBase: 0,
            },
            plot_1: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                // text: '多單',
                title: '多單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                // text: '空單',
                title: '空單參考訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'small',
                // text: '停利參考訊號',
                title: '多單停利參考訊號',
            },
            plot_4: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                // text: '停利參考訊號',
                title: '空單停利參考訊號',
            },
        },
        palettes: {
            palette_0: {
                colors: {
                    0: {
                        color: '#df484c',
                        width: 1,
                        style: 0,
                    },
                    1: {
                        color: '#e39e9c',
                        width: 1,
                        style: 0,
                    },
                    2: {
                        color: '#91b48a',
                        width: 1,
                        style: 0,
                    },
                    3: {
                        color: '#5cb642',
                        width: 1,
                        style: 0,
                    },
                    4: {
                        color: '#aaaaaa',
                        width: 1,
                        style: 0,
                    },
                },
            },
        },
        inputs: [],
    },
});

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, fill_vertical_main_center, } from '~/modules/AppLayout/FlexGridCss';
export const Jerry597_Footer = memo(function Jerry597_Footer() {
    const theme = useThemeStore(t => t.theme);
    return (<styleds.container className={theme}>
      <styleds.box>
        <p>電 話：(02)2653-8199</p>
        <p>傳 真：(02)2653-7399</p>
        <p>地址：台北市南港區經貿二路197號11F 金管會核准字號：一百零玖金管 投顧新字第 零貳參 號</p>
      </styleds.box>

      <styleds.box>
        <p>
          免責聲明：本網頁所載之資料或數據乃搜集自相信可靠之來源。亨達集團，或其所屬公司及有關人士並沒有表明、保證或承諾該等資料乃準確、完整及應屬可信。此網頁內所載之內容不應理解為買入或出售任何證券、產品或服務之建議、邀請、廣告或勸誘。亨達集團，或其所屬公司及有關人士對於網頁所提供的任何資料或意見而產生之任何損失或虧損概不負任何責任。
        </p>
      </styleds.box>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    ${createIPadCss(css `
      ${fill_vertical_cross_center};
    `)}
    ${createMobileCss2(css `
      ${fill_vertical_cross_center};
    `)}
    padding: 32px;
    font-size: 12px;
    gap: 24px;

    &.dark {
      background-color: #323232;
      color: #fafafa;
      border-top: 2px solid #565656;
    }
    &.light {
      background-color: #fafafa;
      color: #252525;
      border-top: 2px solid #787878;
    }
  `,
    box: styled.div `
    ${fill_vertical_main_center};
    justify-content: start;
    & > p {
      margin: 0px;
    }
  `,
};

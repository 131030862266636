import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { yaya168Stock_store } from '~/pages/yaya168_stock/yaya168Stock_store';
export const yaya168_shortTermTrading = createIndicator({
    displayName: '轉折',
    id: 'yaya168-twist',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.PineJS.Std.close(this._context);
            const low = this.PineJS.Std.low(this._context);
            const close_array = this._context.new_var(close);
            const ma20 = this.PineJS.Std.sma(close_array, 20, this._context);
            const ma140 = this.PineJS.Std.sma(close_array, 140, this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            close_array.get(1);
            volume_array.get(1);
            const priceSoar = (close - close_array.get(1)) / close_array.get(1) >= 0.03;
            const priceLimit = close >= ma140;
            const volumeSoar = volume >= volume_array.get(1) * 3;
            const volumeLimit = volume >= 300;
            const entryCondition = close > ma20 && low < ma20 && priceSoar && volumeSoar && volumeLimit && priceLimit;
            const entryCondition_array = this._context.new_var(entryCondition);
            const entryPrice = this._context.new_var(NaN);
            // 延續前根k棒進出場狀態
            entryPrice.set(entryPrice.get(1));
            entryCondition_array.get(5);
            entryPrice.get(5);
            // 未進場
            let up_arrow = NaN;
            let dn_arrow = NaN;
            //符合進場條件
            //沒有目標價代表還沒進場
            //且符合進場狀態
            if (entryCondition_array.get(0) === true) {
                up_arrow = 1;
                // 已進場，設定目標價格
                entryPrice.set(close);
            }
            // 前一天仍持有標的
            if (!isNaN(entryPrice.get(1))) {
                // 符合出場條件且重設目標價格
                // 收盤價大於目標價格，則賣出
                // 或持有三天出場
                if (close >= entryPrice.get(0) * 1.05) {
                    // 已出場
                    dn_arrow = 1;
                    entryPrice.set(NaN);
                }
            }
            // 更新近期多空主導＆動能領導指標
            //近期動能領導
            const dmi = this.dmi(14, 14);
            const di_long = dmi[0];
            const di_short = dmi[1];
            const di_long_array = this._context.new_var(di_long);
            const di_short_array = this._context.new_var(di_short);
            const long = this.PineJS.Std.sma(di_long_array, 5, this._context);
            const short = this.PineJS.Std.sma(di_short_array, 5, this._context);
            const result = this._context.new_var();
            const color = this._context.new_var();
            if (long > short) {
                result.set(long - short);
                color.set(0);
                yaya168Stock_store.trendLeader = '多方';
            }
            if (long < short) {
                result.set(long - short);
                color.set(1);
                yaya168Stock_store.trendLeader = '空方';
            }
            if (long - short < 2 && long - short > -2) {
                color.set(2);
                yaya168Stock_store.trendLeader = '盤整';
            }
            // 近期多空主導
            const in1 = 5;
            const in3 = 60;
            const ma5 = this.PineJS.Std.sma(close_array, in1, this._context);
            const ma60 = this.PineJS.Std.sma(close_array, in3, this._context);
            const ma5_array = this._context.new_var(ma5);
            const ma20_array = this._context.new_var(ma20);
            const ma60_array = this._context.new_var(ma60);
            ma5_array.get(5);
            ma20_array.get(5);
            ma60_array.get(5);
            const longSlopeLong = ma5_array.get(0) >= ma5_array.get(1) &&
                ma20_array.get(0) >= ma20_array.get(1) &&
                ma60_array.get(0) >= ma60_array.get(1);
            const shortSlopeShort = ma5_array.get(0) <= ma5_array.get(1) &&
                ma20_array.get(0) <= ma20_array.get(1) &&
                ma60_array.get(0) <= ma60_array.get(1);
            //全線多頭
            const longTrend = ma5 >= ma20 && ma20 >= ma60 && longSlopeLong;
            //全線空頭
            const shortTrend = ma5 <= ma20 && ma20 <= ma60 && shortSlopeShort;
            //不管60ma 只管5ma 20ma
            //多頭拉回
            const longBack = ma5 >= ma20 && ma20 >= ma60 && !long;
            //空頭反彈
            const shortBack = ma5 <= ma20 && ma20 <= ma60 && !short;
            //多頭整理
            const longSwing = ma5 >= ma20 && !longBack;
            //空頭整理
            const shortSwing = ma5 <= ma20 && !shortBack;
            if (longTrend) {
                yaya168Stock_store.trendState = '多方';
            }
            else if (longBack) {
                yaya168Stock_store.trendState = '偏多';
            }
            else if (longSwing) {
                yaya168Stock_store.trendState = '盤整';
            }
            else if (shortTrend) {
                yaya168Stock_store.trendState = '空方';
            }
            else if (shortBack) {
                yaya168Stock_store.trendState = '偏空';
            }
            else if (shortSwing) {
                yaya168Stock_store.trendState = '盤整';
            }
            else {
                yaya168Stock_store.trendState = '盤整';
            }
            return [up_arrow, dn_arrow];
        },
    },
    metainfo: {
        //宣告
        plots: [
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
        ],
        defaults: {
            //樣式調整
            styles: {
                plot_1: {
                    color: '#dd5e56',
                    textColor: '#dd5e56',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#00ccff',
                    textColor: '#00ccff',
                    transparency: 0,
                    visible: true,
                },
            },
            inputs: {},
        },
        styles: {
            plot_1: {
                isHidden: false,
                location: 'BelowBar',
                char: '✯',
                size: 'tiny',
                text: '',
                title: '✯',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '✯',
                size: 'tiny',
                title: '✯',
            },
        },
        is_price_study: !0,
        inputs: [],
        scriptIdPart: '',
    },
});

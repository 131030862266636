import { memo } from 'react';
import { ContractKbar } from './ContractKbar';
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
export const MonthContractKbar = memo(function MonthContractKbar(props) {
    const data = props.contractData;
    const monthContractChange = data.close - data.open;
    const monthContractChangePercent = (data.close / data.open - 1) * 100;
    const monthContractChangePercentstring = isNaN(monthContractChangePercent)
        ? '-'
        : monthContractChangePercent.toFixed(2);
    const changeSymbol = monthContractChange > 0 ? '+' : '';
    const changeColor = monthContractChange > 0 ? '#ff0000' : monthContractChange < 0 ? '#00aa00' : '#ffff22';
    return (<div css={css `
          ${fill_vertical_cross_center};
          display: grid;
          grid-template-rows: 60% 20% 20%;
        `}>
        <ContractKbar data={data}/>
        <div>
          漲跌:&nbsp;
          <span css={css `
              color: ${changeColor};
            `}>
            {changeSymbol}
            {monthContractChange}
          </span>
        </div>
        <div>
          幅度:&nbsp;
          <span css={css `
              color: ${changeColor};
            `}>
            {changeSymbol}
            {monthContractChangePercentstring}%
          </span>
        </div>
      </div>);
});

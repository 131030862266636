import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { freemanStore } from '../freemanStore';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
/** 切換週期的案鈕群組 */
export const IntervalSwitch = memo(function IntervalSwitch() {
    const state = useSnapshot(freemanStore);
    const tabState = state.ampTabs;
    return (<div css={css `
        ${fill_horizontal_cross_center};
        height: 30px;
        background-color: #353535;
        gap: 8px;
        padding: 8px;
      `}>
      <div>振幅週期:</div>
      <div css={tabState === 'day' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'day')}>
        日
      </div>
      <div css={tabState === 'week' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'week')}>
        週
      </div>
      <div css={tabState === 'month' ? classes.switchButtomSelected : classes.switchButtom} onClick={() => (freemanStore.ampTabs = 'month')}>
        月
      </div>
    </div>);
});
const classes = {
    switchButtom: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #232323;
    color: #aaaaaa;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
    switchButtomSelected: css `
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    background-color: #e48100;
    border-radius: 4px;
    padding: 0 8px;
    cursor: pointer;
  `,
};

import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center, jc } from '~/modules/AppLayout/FlexGridCss';
export const PriceLevelItem = memo(function PriceLevelItem(props) {
    return props.data.map((item, index) => {
        return (<div key={index} css={css `
            ${classes.container};
            background: linear-gradient(
              90deg,
              ${item.color}33,
              ${item.color}55,
              ${item.color}77,
              ${item.color}55,
              ${item.color}33
            );
            &:hover {
              background: linear-gradient(
                90deg,
                ${item.color}33,
                ${item.color}77,
                ${item.color}99,
                ${item.color}77,
                ${item.color}33
              );
            }
          `}>
          <span css={classes.item}>{item.levelString}</span>
          <span css={classes.value}>{item.value.toFixed(0)}</span>
        </div>);
    });
});
const classes = {
    container: css `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 28px;
    border: 1px solid transparent;
    padding: 0 8px;
    &:hover {
      transform: scale(1.04);
      transition: 0.4s;
      border: 1px solid #66666666;
    }
  `,
    item: css `
    ${fill_horizontal_all_center};
    padding: 0 4px;
    border-radius: 4px;
    font-weight: 500;
    background-color: #5959596b;
    padding: 0 4px;
    width: 48%;
    height: 22px;
  `,
    value: css `
    ${fill_horizontal_all_center};
    font-weight: 700;
    width: 52%;
    height: 22px;
  `,
};

import { memo } from 'react';
import { store } from '~/pages/heineken_template/_private/store';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { Styleds } from '../styleds';
import { useSnapshot } from 'valtio';
import { useChartPositionStore } from '~/modules/cons-misc/mode-buttons/ChartPositionButton';
import { RealtimeSymbolPage } from '../RealtimeSymbolPage';
export const Lung88988Stock_SidePane_ForOnlyMobile = memo(function Lung88988Stock_SidePane_forOnlyMobile() {
    return (<Styleds.SideBar>
        <Styleds.SidebarTitle>當沖策略</Styleds.SidebarTitle>
        <PositionGroup />
        <Styleds.SymbolListContainerForOnlyMobile>
          <RealtimeSymbolPage />
        </Styleds.SymbolListContainerForOnlyMobile>
      </Styleds.SideBar>);
});
const PositionGroup = memo(function PositionButtonGroup() {
    const chartPositionState = useSnapshot(useChartPositionStore);
    return (<Styleds.PositionButtonGroup>
      <FuiButton.Display variant='long' active={chartPositionState.positionType === 'long'} onClick={event => {
            useChartPositionStore.positionType = 'long';
            store.charting.updateFromState();
        }}>
        多方操作
      </FuiButton.Display>
      <FuiButton.Display active={chartPositionState.positionType === 'default'} onClick={event => {
            useChartPositionStore.positionType = 'default';
            store.charting.updateFromState();
        }}>
        預設
      </FuiButton.Display>
      <FuiButton.Display variant='short' active={chartPositionState.positionType === 'short'} onClick={event => {
            useChartPositionStore.positionType = 'short';
            store.charting.updateFromState();
        }}>
        空方操作
      </FuiButton.Display>
    </Styleds.PositionButtonGroup>);
});

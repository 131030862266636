import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { indexMacd, indexKd1, indexKd2, indexKd3, stockMacd, stockKd1, stockKd2, stockKd3, } from '~/trades/indicators/sungop_stock/indicatorsParameter';
/** 多空分界 */
export const sungopStock_customized0 = createIndicator({
    displayName: '輕松賺指標',
    id: 'sungopStockcustomized0',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            // 基本參數
            const stds = 2;
            const length = 20;
            const interval = context.symbol.period;
            const symbolNumber = context.symbol.ticker;
            const isStockSymbol = symbolNumber.match(/^[\d]{4}$/);
            const close = this.ohlc.closeArray;
            const high = this.ohlc.highArray;
            const low = this.ohlc.lowArray;
            const targetPrice = this._context.new_var();
            const targetPoint = this._context.new_var();
            close.get(10);
            //股票＆指數的參數不同
            const customized1MACD = isStockSymbol ? stockMacd(interval) : indexMacd(interval);
            const customized3Length1 = isStockSymbol ? stockKd1(interval) : indexKd1(interval);
            const customized3Length2 = isStockSymbol ? stockKd2(interval) : indexKd2(interval);
            const customized3Length3 = isStockSymbol ? stockKd3(interval) : indexKd3(interval);
            //macd，近期為上升/下降趨勢
            const macd = this.ok1788customized1(customized1MACD);
            //K%快線-50,代表股價偏近期最高價更多或是偏近期最低價更多
            const K = this.ok1788customized3(customized3Length1, customized3Length2, customized3Length3)[0];
            // 價格突破布林通道以上/以下
            const bband = this.bollingerBand(close, length, stds);
            const bbandUp = bband.top;
            const bbandDn = bband.bottom;
            // 季線之上/下
            const ma60 = this.average(close, 60);
            // 進場條件
            const longCondition = macd >= 0 && K >= 0 && close.get(0) >= bbandUp && close.get(0) >= ma60;
            const shortCondition = macd < 0 && K < 0 && close.get(0) <= bbandDn && close.get(0) <= ma60;
            //這邊處理減資等事件造成極大的缺口影響交易訊號----
            const time = this.PineJS.Std.time(this._context);
            const time_array = this._context.new_var(time);
            const open = this.ohlc.open;
            const pass = this._context.new_var();
            const passDate = this._context.new_var();
            time_array.get(customized1MACD);
            const isHasPriceGap = open / close.get(1) > 1.2 || close.get(1) / open > 1.2;
            if (pass.get(0) !== 1 && isHasPriceGap) {
                //出現減資立flag 限制交易進場
                pass.set(1);
                //記下當下時間
                passDate.set(time);
            }
            //取前N天時間 > 出現缺口的時間 就解除限制
            if (pass.get(0) === 1 && time_array.get(customized1MACD) >= passDate.get(0)) {
                pass.set(0);
            }
            //-----------------------------------------
            const marketposition = this._context.new_var();
            // 設定new_var的容器深度
            marketposition.get(1);
            marketposition.get(2);
            high.get(1);
            targetPrice.get(1);
            targetPoint.get(1);
            let buy_icon = NaN;
            let short_icon = NaN;
            let buy_target_icon = NaN;
            let short_target_icon = NaN;
            const condition_buy = longCondition && pass.get(0) !== 1;
            const condition_short = shortCondition && pass.get(0) !== 1;
            // 設定為前根k棒的狀態，只有在進出場時才會改變
            marketposition.set(marketposition.get(1));
            //只有三線多/空才會進場
            // 三線多 ＆ 非跳空
            if (condition_buy) {
                marketposition.set(1);
            }
            if (condition_short) {
                marketposition.set(-1);
            }
            if (marketposition.get(0) === 1 && marketposition.get(1) !== 1) {
                buy_icon = 1;
                // 設定進場價格
                targetPrice.set(close.get(0));
                targetPoint.set(1.03);
            }
            if (marketposition.get(0) === -1 && marketposition.get(1) !== -1) {
                short_icon = 1;
                // 設定進場價格
                targetPrice.set(close.get(0));
                targetPoint.set(0.97);
            }
            //停利訊號--------------------------------------------------
            // 每完成一次停利訊號 停利點數就會再放大
            // 至少進場兩天才會開始停利
            if (marketposition.get(2) === 1 && marketposition.get(1) === 1) {
                if (high.get(1) > targetPrice.get(1) * targetPoint.get(1)) {
                    targetPoint.set(targetPoint.get(1) + 0.08);
                    targetPrice.set(targetPrice.get(1) * targetPoint.get(1));
                    buy_target_icon = 1;
                }
                else {
                    targetPoint.set(targetPoint.get(1));
                    targetPrice.set(targetPrice.get(1));
                }
            }
            if (marketposition.get(1) === -1 && marketposition.get(0) === -1) {
                if (low.get(0) < targetPrice.get(0) * targetPoint.get(0)) {
                    targetPoint.set(targetPoint.get(0) - 0.08);
                    targetPrice.set(targetPrice.get(0) * targetPoint.get(0));
                    short_target_icon = 1;
                }
                else {
                    targetPoint.set(targetPoint.get(1));
                    targetPrice.set(targetPrice.get(1));
                }
            }
            //sar出場
            const sar_long = this.sarLongStopLoss('nonLinear', 4, marketposition, 10, 2, 0.06, 1);
            const sar_short = this.sarShortStopLoss('nonLinear', 5, marketposition, 10, 2, 0.08, 1);
            // 至少進場兩天才會出場
            const longEnd = marketposition.get(1) === 1 && marketposition.get(0) === 1 && close.get(0) < sar_long.get(0);
            const shortEnd = marketposition.get(1) === -1 &&
                marketposition.get(0) === -1 &&
                close.get(0) > sar_short.get(0);
            // console.log('SUNGOP sar_long-1', sar_long.get(0))
            if (longEnd) {
                marketposition.set(0);
                targetPrice.set(NaN);
                targetPoint.set(NaN);
            }
            if (shortEnd) {
                marketposition.set(0);
                targetPrice.set(NaN);
                targetPoint.set(NaN);
            }
            //sar線--------------------------------------------------
            const sarLongLine = this._context.new_var();
            const sarShortLine = this._context.new_var();
            if (marketposition.get(0) === 1) {
                sarLongLine.set(sar_long.get(0));
                sarShortLine.set(NaN);
            }
            // console.log('SUNGOP sar_long-2', sar_long.get(0))
            if (marketposition.get(0) === -1) {
                sarLongLine.set(NaN);
                sarShortLine.set(sar_short.get(0));
            }
            if (marketposition.get(0) === 0) {
                sarLongLine.set(NaN);
                sarShortLine.set(NaN);
            }
            return [
                buy_icon,
                short_icon,
                buy_target_icon,
                short_target_icon,
                sarLongLine.get(0),
                sarShortLine.get(0),
                // low.get(0) - this.this.avgTrueRange(10),
            ];
        },
    },
    metainfo: {
        plots: [
            {
                id: 'plot_0',
                type: 'chars',
            },
            {
                id: 'plot_1',
                type: 'chars',
            },
            {
                id: 'plot_2',
                type: 'chars',
            },
            {
                id: 'plot_3',
                type: 'chars',
            },
            {
                id: 'plot_4',
                type: 'line',
            },
            {
                id: 'plot_5',
                type: 'line',
            },
            // {
            //   id: 'plot_6',
            //   type: 'line',
            // },
        ],
        defaults: {
            styles: {
                plot_0: {
                    color: '#ff0031',
                    textColor: '#ff0031',
                    transparency: 0,
                    visible: true,
                },
                plot_1: {
                    color: '#00ff31',
                    textColor: '#00ff31',
                    transparency: 0,
                    visible: true,
                },
                plot_2: {
                    color: '#ff0031',
                    textColor: '#ff0031',
                    transparency: 30,
                    visible: true,
                },
                plot_3: {
                    color: '#00ff31',
                    textColor: '#00ff31',
                    transparency: 30,
                    visible: true,
                },
                plot_4: {
                    color: '#ff0031',
                    textColor: '#ff0031',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                plot_5: {
                    color: '#00ff31',
                    textColor: '#00ff31',
                    transparency: 30,
                    visible: true,
                    linestyle: 2,
                    linewidth: 1,
                },
                // plot_6: {
                //   color: 'yellow',
                //   textColor: 'yellow',
                //   transparency: 30,
                //   visible: true,
                //   linestyle: 2,
                //   linewidth: 1,
                // },
            },
        },
        styles: {
            plot_0: {
                isHidden: false,
                location: 'BelowBar',
                char: '▲',
                size: 'small',
                text: 'B',
                title: '多單參考訊號',
            },
            plot_1: {
                isHidden: false,
                location: 'AboveBar',
                char: '▼',
                size: 'small',
                text: 'S',
                title: '空單參考訊號',
            },
            plot_2: {
                isHidden: false,
                location: 'AboveBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '多單出場訊號',
            },
            plot_3: {
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'tiny',
                text: '',
                title: '空單出場訊號',
            },
            plot_4: {
                isHidden: false,
                title: '多單出場參考線',
            },
            plot_5: {
                isHidden: false,
                title: '空單出場參考線',
            },
            // plot_6: {
            //   isHidden: false,
            //   title: 'atr線',
            // },
        },
        inputs: [],
        scriptIdPart: '',
        is_price_study: !0,
    },
});

import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { Preset_LoginPageOfCarousel } from '~/pages/heineken_template/_preset/preset_LoginPageOfCarousel';
import { store } from '~/pages/heineken_template/_private/store';
import { lung88988_initStrategies } from './lung88988_initStrategies';
import { lung88988_initStyling } from './lung88988_initStyling';
import { Lung88988_SidePane1 } from './lung88988_SidePane1';
import { Lung88988_SidePane2 } from './lung88988_SidePane2';
import { Lung88988_Topbar } from './lung88988_Topbar';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import { Fragment } from 'react';
import { useSignalrBuild } from '~/modules/SDK/Signalr/useSignalrBuild';
import { meCheckHandlerCreateByProductNamesWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
export const lung88988_init = (templateProps) => {
    lung88988_initStyling(templateProps);
    lung88988_initStrategies();
    templateProps.layout.Providers = function Provider(props) {
        useSignalrBuild({ reconnectCheckInterval: 3000 });
        useSignalrStart2_1();
        return <Fragment>{props.children}</Fragment>;
    };
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/lung88988/loginView.jpg'] }]}/>);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web',
        'web_moblie',
    ]);
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        custom_css_url: '../../lung88988/chartingTheme.css',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TX-1',
        interval: '3',
        enableVolumeIndicator: true,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledTimeframesToolbar: true,
        overrides: store.charting.darkOverrides,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr;
    grid-template-columns: 336px 1fr 336px;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Chart Drawer2'
      'Drawer1 Chart Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart';
    `)}
  `;
    templateProps.layout.Row1 = Lung88988_Topbar;
    templateProps.layout.Drawer1 = Lung88988_SidePane1;
    templateProps.layout.Drawer2 = Lung88988_SidePane2;
    templateProps.hooks.add(fr_me.useInstall);
};

import { css } from '@emotion/react';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
export const A2330_Topbar = memo(function A2330_Topbar(props) {
    const { isPc } = useMedia();
    const showRightBurger = props.rightBurger ?? false;
    return (<div css={css `
          ${fill_horizontal_cross_center};
          ${jc.spaceBetween};
          /* justify-content: end; */
          /* background-color: #151515; */
          padding: 5px;
          border-bottom: 1px solid #acacac;
          /* border-bottom: 1px solid #2b2e38; */
        `}>
        {isPc && (<div css={css `
              ${fill_horizontal_cross_center};
              width: auto;
              gap: 8px;
            `}/>)}
        <div css={css `
            ${fill_horizontal_cross_center};
            width: auto;
            gap: 8px;
          `}>
          {isPc && <ChartingServerSwitch charting={store.charting}/>}
          {isPc && <UserAvatarAsDialogButton />}
        </div>

        {!isPc && (<Preset_Topbar showLeftBurger showRightBurger={showRightBurger} componentsInRight={<div css={css `
                  ${fill_horizontal_cross_center};
                `}>
                <ChartingServerSwitch charting={store.charting}/>
                <UserAvatarAsDialogButton />
              </div>}/>)}
      </div>);
});
const styleds = {
    pageTitle: styled.div `
    color: #cc1353;
    font-size: 16px;
    font-weight: 600;
  `,
    pageFavicon: styled.img `
    width: 56px;
  `,
};

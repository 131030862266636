import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo } from 'react';
import { fill_horizontal_all_center, } from '~/modules/AppLayout/FlexGridCss';
/** google標單填寫預約開戶服務 */
//https://docs.google.com/forms/d/e/1FAIpQLSelCwbjv2wpsGd7G0I6IouLwW3sOmezek6ttLAXjit-H9P7xw/viewform
export const FormOpenAccount = memo(function FormOpenAccount(props) {
    return (<classes.container css={props.containerCss}>
      <classes.title>便利下單功能申請</classes.title>
      <Button onClick={() => window.open(props.customURL, '_blank')} css={css `
          width: 100%;
          height: 100%;
          min-width: 80px;
          padding: 2px;
          gap: 4px;
          border-radius: 5px;
          cursor: pointer;
          background-color: rgb(76, 86, 95);
          line-height: 18px;
          transition: 0.3s;
          &:hover {
            background-color: #578aef;
          }
        `}>
        <span css={css `
            color: #ffffff;
          `}>
          確認
        </span>
      </Button>
    </classes.container>);
});
const classes = {
    container: styled.div `
    display: flex;
    height: 40px;
    width: 100%;
    border-radius: 4px;
    gap: 8px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    font-size: 14px;
    white-space: nowrap;
  `,
};

import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
const plusSymbol = (value) => {
    if (value > 0) {
        return '+';
    }
    else
        return '';
};
export const PriceDiff = memo(function PriceDiff(props) {
    return (<div css={fill_vertical_all_center}>
        <div css={classes.container}>
          <span css={classes.item}>台指加權價差</span>
          <span css={classes.value}>
            {plusSymbol(props.indexPriceDiff)}
            {props.indexPriceDiff.toFixed(2)}
          </span>
        </div>
        <div css={classes.container}>
          <span css={classes.item}>台指近遠月價差</span>
          <span css={classes.value}>
            {plusSymbol(props.futuresPriceDiff)}
            {props.futuresPriceDiff.toFixed(2)}
          </span>
        </div>
      </div>);
});
const classes = {
    container: css `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    height: 28px;
    border: 1px solid transparent;
    padding: 0 8px;
    &:hover {
      transform: scale(1.04);
      transition: 0.4s;
      border: 1px solid #66666666;
    }
  `,
    item: css `
    ${fill_horizontal_all_center};
    padding: 0 4px;
    border-radius: 4px;
    font-weight: 500;
    background-color: #5959596b;
    padding: 0 4px;
    width: 48%;
    height: 22px;
  `,
    value: css `
    ${fill_horizontal_all_center};
    font-weight: 700;
    width: 52%;
    height: 22px;
  `,
};

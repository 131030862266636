import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import YouTube from 'react-youtube';
import { proxy, useSnapshot } from 'valtio';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal, fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useGetYoutubeDataByPlaylist, } from '~/pages/freeman/component/VideoContnet';
const opts = {
    playerVars: {
        autoplay: 0,
    },
};
const videoStore = proxy({
    currentId: '',
    listType: 'default',
});
const defaultListID = 'PLJJOvPqSP9PXsU4qsZsdWU4XCELuXwIrf';
const leisureListID = 'PLJJOvPqSP9PWjR_a89VkFZ62ZcUN_VzHY';
const manualListID = 'PLJJOvPqSP9PXPTf9gs1rvtt--A5mUIDNx';
export const VideoContent = memo(function VideoContent() {
    const state = useSnapshot(videoStore);
    const displayListID = () => {
        if (state.listType === 'default') {
            return defaultListID;
        }
        else if (state.listType === 'leisure') {
            return leisureListID;
        }
        else
            return manualListID;
    };
    const originalData = useGetYoutubeDataByPlaylist(displayListID());
    const attributes = originalData.data?.data.map(s => s.attributes);
    const data = originalData.data?.data.flatMap(s => s.attributes.entry);
    const defaultVideoID = attributes?.[0]?.entry?.[0].video_id ?? '';
    useEffect(() => {
        videoStore.currentId = defaultVideoID;
    }, [defaultVideoID]);
    return (<classes.container>
      <classes.video>
        <YouTube videoId={state.currentId} opts={opts}/>
      </classes.video>

      <div css={css `
          ${fill_horizontal_all_center};
          background-color: #3a3a3f;
          font-size: 14px;
        `}>
        影片清單
      </div>

      <div css={css `
          ${fill_horizontal_cross_center};
          font-size: 14px;
          gap: 4px;
        `}>
        <classes.typeButton onClick={() => (videoStore.listType = 'default')}>
          盤後分析
        </classes.typeButton>
        <classes.typeButton onClick={() => (videoStore.listType = 'leisure')}>
          生活短片
        </classes.typeButton>
        <classes.typeButton onClick={() => (videoStore.listType = 'manual')}>
          系統教學
        </classes.typeButton>
      </div>
      <div css={css `
          ${fill_vertical_cross_center};
          ${scrollbar2Css};
          gap: 4px;
        `}>
        {data?.map(s => {
            return (<div key={s.media_title} css={css `
                ${fill_horizontal};
                height: 80px;
                font-size: 12px;
                background-color: #25262a;
                border-radius: 4px;
                padding: 4px;
                flex-shrink: 1;
              `} onClick={() => (videoStore.currentId = s.video_id)}>
              <img src={s.media_thumbnail_url} css={css `
                  width: 96px;
                  max-width: 96px;
                  height: 72px;
                  max-height: 72px;
                  flex-shrink: 1;
                `}/>
              <div>{s.media_title}</div>
            </div>);
        })}
      </div>
    </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-rows: 148px 28px 24px 1fr;
    height: 100%;
    width: 100%;
    gap: 4px;
  `,
    video: styled.div `
    width: 100%;
    height: 100%;
    & > * {
      width: 100%;
      height: 100%;
    }
    & > * > iframe {
      width: 100%;
      height: 100%;
    }
    background-color: #25262a;
    border-radius: 4px;
  `,
    typeButton: styled.span `
    width: auto;
    height: 22px;
    border-radius: 2px;
    padding: 0 6px;
    background-color: #535862;
    cursor: pointer;
  `,
};
//這邊需要使用播放清單來做分類
//權值股K棒10檔數
//貢獻點數
//下單匣預留位置

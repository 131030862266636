import { css } from '@emotion/react';
import { createTheme } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { symbolGroupStore } from '~/modules/investment-consultant/SymbolGroupTable/symbolGroupStore';
import ThemeLayout from '~/components/theme/ThemeLayout';
import SimpleQuoteBodyItem from '~/modules/symbolQuote/simple/SimpleQuoteBodyItem';
import SimpleQuoteHeader from '~/modules/symbolQuote/simple/SimpleQuoteHeader';
import { fontWeight400 } from '~/css/font';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { useChartingSymbol } from '~/modules/SDK/chart4/hooks/useChartingSymbol';
import { useChartingChangeIndicatorsFn } from '~/modules/SDK/chart4/hooks/useChartingChangeIndicators';
import { useChartingChangeSymbolIntervalFn } from '~/modules/SDK/chart4/hooks/useChartingChangeSymbolIntervalFn';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { BitPixelBorder } from '~/css/bitPixelBorder';
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        text: { primary: '#333333' },
        background: { paper: '#eeeeee' },
    },
});
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: { primary: '#ffffff' },
        background: { paper: '#222222' },
    },
});
/** 排列顯示data中的股票 帶入股票代號之陣列即可 [2330,2609,...] */
export const HighlightQuoteListInteractWithChart = memo(function HighlightQuoteListInteractWithChart(props) {
    const currentSymbol = useChartingSymbol(props.chart)();
    const changeIndicators = useChartingChangeIndicatorsFn(props.chart);
    const changeSymbolInterval = useChartingChangeSymbolIntervalFn(props.chart);
    const noAutoSubscribe = props.noAutoSubscribe ?? false;
    const showStockFutures = props.showStockFutures ?? true;
    useEffect(() => {
        signalrStore2.addQuote(props.data);
        return () => {
            signalrStore2.removeQuote(props.data);
        };
    }, [JSON.stringify(props.data)]);
    const sortData = useSignalr2QuoteSort(props.data) ?? [];
    const symbolsInfo = signalrHooks2.useQuotes(sortData);
    const filteredData = symbolsInfo.filter(data => {
        const { volumeLimitModeIsGT, volumeLimitModeIsLT } = symbolGroupStore;
        if (volumeLimitModeIsGT) {
            return (data?.volume ?? 0) >= symbolGroupStore.volumeLimit;
        }
        if (volumeLimitModeIsLT) {
            return (data?.volume ?? 0) <= symbolGroupStore.volumeLimit;
        }
        return true;
    });
    return (<ThemeLayout lightTheme={lightTheme} darkTheme={darkTheme}>
        <div css={css `
            ${flex.v.default};
            width: 100%;
            height: 100%;
          `}>
          {filteredData.length === 0 ? (<div css={css `
                ${flex.v.allCenter};
                width: 100%;
                height: 100%;
              `}>
              沒有商品
            </div>) : (<div css={styledScrollBarCss}>
              <BitPixelBorder backgroundColor={props.color}>
                <div css={highlight}>
                  <div css={css `
                      width: 100%;
                      text-align: center;
                      ${fontWeight400};
                      margin: 1px 0px;
                    `}>
                    精選一檔
                  </div>
                  <SimpleQuoteHeader.Default />
                  {props.highlightSymbol?.map((symbol, index) => {
                return (<div key={index} onClick={event => {
                        props.handleClick?.(symbol);
                        if (currentSymbol !== symbol) {
                            changeSymbolInterval({ symbol });
                        }
                        if (props.indicators) {
                            changeIndicators(props.indicators);
                        }
                    }}>
                        <SimpleQuoteBodyItem.Default currentSymbol={currentSymbol} symbol={symbol} showStockFutures={showStockFutures}/>
                      </div>);
            })}
                </div>
              </BitPixelBorder>

              <div>
                <div css={css `
                    width: 100%;
                    text-align: center;
                    ${fontWeight400};
                    margin: 1px 0px;
                  `}>
                  強勢標的
                </div>
                <SimpleQuoteHeader.Default />
                {filteredData?.map((data, index) => {
                const symbol = data?.symbol;
                const isHighlightSymbol = props.highlightSymbol?.includes(symbol);
                // 強調顯示的股票則不顯示
                if (isHighlightSymbol) {
                    return;
                }
                return (<div key={index} onClick={event => {
                        props.handleClick?.(symbol);
                        if (currentSymbol !== symbol) {
                            changeSymbolInterval({ symbol });
                        }
                        if (props.indicators) {
                            changeIndicators(props.indicators);
                        }
                    }}>
                      <SimpleQuoteBodyItem.Default currentSymbol={currentSymbol} symbol={symbol} showStockFutures={showStockFutures}/>
                    </div>);
            })}
              </div>
            </div>)}
        </div>
      </ThemeLayout>);
});
const styledScrollBarCss = css `
  ${scrollbar2Css}
  height: 100%;
`;
const highlight = css ``;

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fill_horizontal_all_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const styleds = {
    Columns: styled.div(props => css `
      display: grid;
      grid-template-columns: repeat(${props.span}, 1fr);
    `),
    Sidebar: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px 4px;
    gap: 4px;
    background-size: 75%;
    background-position: center;
    background-repeat: no-repeat;
    border-right: 1px solid #999999;
    ${options => {
        const darkColor = '#141721';
        const lightColor = '#fafafa';
        const color = options.theme === 'dark' ? darkColor : lightColor;
        const background = css `
        background-image: linear-gradient(${color + 'ee'}, ${color}ee), url('good178/favicon.png');
        background-color: ${color};
      `;
        return css([background]);
    }}
  `,
    ModeButtonGroup: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 3px;
  `,
    VirtualTransactionSelector: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 4px;
  `,
    DashboardContent: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - ${options => (options.openHoldingPrices === true ? '302' : '106')}px);
  `,
    SymbolListContent: styled.div `
    width: 100%;
    height: calc(100% - 32px);
  `,
    ScreenerHeader: styled.div `
    ${fill_vertical_all_center};
    height: 88px;
  `,
    ScreenerList: styled.div `
    ${fill_vertical_all_center};
    height: calc(100% - 88px);
  `,
    PositionInfoContent: styled.div `
    ${fill_vertical_all_center};
    height: ${options => (options.openHoldingPrices === true ? '196' : '0')}px;
  `,
    RenderButton: styled.div `
    ${fill_horizontal_all_center};
    width: 160px;
    height: 24px;
    background-color: ${options => (options.theme === 'dark' ? '#333547' : '#cacaca')};
    color: ${options => (options.theme === 'dark' ? '#f5f5f5' : '#252525')};
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
  `,
};

import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { styleds } from './styleds';
import { CheapCard, DefaultCard, MTXQuoteCard, TimeCard, TSEAQuoteCard, TXFQuoteCard, } from './component/PriceCard';
import { optionAnalyzeStore } from './component/optionAnalyze/optionAnalyzeStore';
import { getOptionCheap } from './component/optionAnalyze/getOptionCheap';
import { useOptionQuote } from './component/optionAnalyze/useOptionQuote';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MasterTeaching } from './component/masterTeaching';
import { css } from '@emotion/react';
import { fill_horizontal_cross_center } from '~/modules/AppLayout/FlexGridCss';
export const Opkevin_SidePane = memo(function Opkevin_SidePane() {
    const opState = useSnapshot(optionAnalyzeStore);
    const data = useOptionQuote().quoteData;
    const atTheMoneyPrice = opState.atTheMoneyPrice;
    const atTheMoneySum = opState.atTheMoneySum;
    const cheapValue = getOptionCheap(data, atTheMoneyPrice, atTheMoneySum);
    const opPriceState = cheapValue > 50 ? 'Expensive' : 'Cheap';
    const [opened, { open, close }] = useDisclosure(false);
    return (<styleds.sidePane>
      <TimeCard />
      <DefaultCard value={opState.remainingDays}>剩餘日</DefaultCard>
      <TSEAQuoteCard />
      <TXFQuoteCard />
      <MTXQuoteCard />
      <DefaultCard value={opState.syntheticFutures}>合成期貨</DefaultCard>
      <DefaultCard value={opState.atTheMoneyPrice}>價平</DefaultCard>
      <CheapCard value={cheapValue}>{opPriceState}</CheapCard>
      <Modal opened={opened} onClose={close} title={<div>
            <img src='opkevin/master.png' css={css `
                height: 36px;
              `}/>
            策略軍師
          </div>} css={modalCss}>
        <MasterTeaching />
      </Modal>

      <Button onClick={open} css={masterButtonCss}>
        <img src='opkevin/master.png' css={css `
            height: 36px;
          `}/>
        策略軍師
      </Button>
    </styleds.sidePane>);
});
const masterButtonCss = css `
  width: 100%;
  height: 64px;
  padding: 8px;
  background-color: #e38131;
  &:hover {
    background-color: #ff923a;
  }
`;
const modalCss = css `
  aria-hidden: true;
  z-index: 13000;
  position: fixed;
  width: 100%;
  height: 100%;
  .mantine-11tivgz {
    height: 100%;
  }
  .mantine-1q36a81 {
    width: 100%;
    height: calc(100% - 54px);
  }
  .mantine-143k401 {
    ${fill_horizontal_cross_center};
    height: 72px;
    padding: 16px;
    background-color: #292935;
    border-bottom: 1px solid #787878;
  }
  .mantine-1k9itrp {
    font-size: 28px;
    font-weight: bold;
  }
`;

import { store } from '../heineken_template/_private/store';
import { daddy960_revive_cci } from '~/trades/indicators/daddy960_revive/daddy960_revive_cci';
import { daddy960_revive_holdingLine } from '~/trades/indicators/daddy960_revive/daddy960_revive_holdingLine';
import { daddy960_revive_macd } from '~/trades/indicators/daddy960_revive/daddy960_revive_macd';
import { daddy960_revive_ti } from '~/trades/indicators/daddy960_revive/daddy960_revive_ti';
import { daddy960_revive_tdcc } from '~/trades/indicators/daddy960_revive/daddy960_revive_tdcc';
const DEFAULT_SYMBOL = 'TXAM-1';
export const reviveIndicators = [
    daddy960_revive_holdingLine,
    daddy960_revive_macd,
    daddy960_revive_cci,
    daddy960_revive_ti,
    daddy960_revive_tdcc,
];
export const daddy960_revive_strategyGroup = {
    main: [
        {
            displayName: 'revive',
            indicators: [...reviveIndicators],
            symbol: DEFAULT_SYMBOL,
            interval: 'D',
            panesRatio: 60,
        },
    ],
};
export const daddy960_revive_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...reviveIndicators];
    store.charting.initStrategy({
        configs: [...daddy960_revive_strategyGroup.main],
    });
    store.charting.changeSymbol(DEFAULT_SYMBOL);
};

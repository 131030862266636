import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import dayAPI from '~/utils/dayAPI';
export const freeman_diff = createIndicator({
    id: 'freeman-diff',
    displayName: '多空差額',
    constructorScope: {
        init(context, inputcallback) {
            return;
        },
        main(context, inputCallback) {
            const newValue = this._context.new_var();
            const bar = this._context.new_var();
            const open = this.PineJS.Std.open(this._context);
            const close = this.PineJS.Std.close(this._context);
            const volume = this.PineJS.Std.volume(this._context);
            const buy_volume = close >= open;
            const sell_volume = close < open;
            if (buy_volume) {
                newValue.set(newValue.get(0) + volume);
                bar.set(volume);
            }
            else if (sell_volume) {
                newValue.set(newValue.get(0) - volume);
                bar.set(-volume);
            }
            else
                newValue.set(newValue.get(0));
            const itime = this.PineJS.Std.time(this._context);
            const hours = dayAPI(itime).hour();
            const minutes = dayAPI(itime).minute();
            const openTime = hours === 8 && minutes === 45;
            const diff = buy_volume ? volume : sell_volume ? volume * -1 : 0;
            const colorIndex = diff >= 0 ? 0 : 1;
            //開盤歸零
            if (openTime) {
                if (buy_volume) {
                    newValue.set(volume);
                }
                if (sell_volume) {
                    newValue.set(-volume);
                }
            }
            return [newValue.get(0), diff, 10000, 20000, -10000, -20000, colorIndex];
        },
    },
    metainfo: {
        precision: 2,
        plots: [
            {
                id: 'acc',
                type: 'line',
            },
            {
                id: 'bar',
                type: 'line',
            },
            {
                id: 'line_p1000',
                type: 'line',
            },
            {
                id: 'line_p2000',
                type: 'line',
            },
            {
                id: 'line_n1000',
                type: 'line',
            },
            {
                id: 'line_n2000',
                type: 'line',
            },
            {
                id: 'barPalette',
                type: 'colorer',
                target: 'bar',
                palette: 'barPalette',
            },
        ],
        palettes: {
            barPalette: {
                colors: {
                    0: {
                        name: '能量',
                    },
                    1: {
                        name: '能量',
                    },
                },
            },
        },
        defaults: {
            styles: {
                acc: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
                bar: {
                    linestyle: 0,
                    linewidth: 5,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#ff8800',
                },
                line_p1000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !1,
                    color: '#aa2222',
                },
                line_p2000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !1,
                    color: '#aa2222',
                },
                line_n1000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !1,
                    color: '#22aa22',
                },
                line_n2000: {
                    linestyle: 1,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 66,
                    visible: !1,
                    color: '#22aa22',
                },
            },
            palettes: {
                barPalette: {
                    colors: {
                        0: {
                            color: '#cc2222',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#22cc22',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            acc: {
                title: '成本線',
                histogramBase: 0,
                joinPoints: !1,
            },
            bar: {
                title: '差額',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_p1000: {
                title: '1000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_p2000: {
                title: '2000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_n1000: {
                title: '-1000',
                histogramBase: 0,
                joinPoints: !1,
            },
            line_n2000: {
                title: '-2000',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        is_price_study: !1,
        inputs: [],
        scriptIdPart: '',
    },
});

/* eslint-disable no-var, no-console */
import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const edwin_indicators_short = createIndicator({
    id: 'edwin-indicators-short',
    displayName: 'ED動能指標-弱勢',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const itime = this.PineJS.Std.time(this._context);
            const close = this.PineJS.Std.close(this._context);
            const open = this.PineJS.Std.open(this._context);
            const close_array = this._context.new_var(close);
            const change = close - close_array.get(1) / close_array.get(1);
            const change_array = this._context.new_var(change);
            const volume = this.PineJS.Std.volume(this._context);
            const volume_array = this._context.new_var(volume);
            close_array.get(60);
            volume_array.get(60);
            change_array.get(60);
            //macd
            const macd1 = 12;
            const macd2 = 26;
            const macd3 = 9;
            const fast = this.PineJS.Std.ema(close_array, macd1, this._context);
            const slow = this.PineJS.Std.ema(close_array, macd2, this._context);
            const macd = fast - slow;
            const macd_array = this._context.new_var(macd);
            const signal = this.PineJS.Std.ema(macd_array, macd3, this._context);
            const hist = macd - signal;
            const hist_array = this._context.new_var(hist);
            macd_array.get(20);
            hist_array.get(20);
            //-----
            //bband
            const length = 10;
            const stds = 2;
            const bband = this.bollingerBand(close_array, length, stds);
            const bband_width = bband.top - bband.bottom;
            const bband_width_array = this._context.new_var(bband_width);
            bband_width_array.get(20);
            //-----
            //EVN
            const evn = this.PineJS.Std.sma(close_array, 20, this._context);
            const k = 0.3 / 100;
            const upper_evn = evn * (1 + k);
            const lower_evn = evn * (1 - k);
            //-----
            //default
            const sma20 = this.PineJS.Std.sma(close_array, 20, this._context);
            const sma60 = this.PineJS.Std.sma(close_array, 60, this._context);
            const volume20ma = this.PineJS.Std.sma(volume_array, 20, this._context);
            const k_red = close > open;
            const k_green = close < open;
            //-----
            //(close - ta.valuewhen(ta.change(close) != 0, close, 1)) 解讀收盤[0]-收盤[1]
            const is_close_change = this.PineJS.Std.change(close_array);
            const first_d = ((close - close_array.get(1)) / close) * 100;
            const first_d_array = this._context.new_var(first_d);
            first_d_array.get(20);
            const second_d = first_d - first_d_array.get(1);
            //布林道擴張
            const expansion = bband_width < bband_width_array.get(1);
            //綠K + second_d放大（>0）：強勢的上漲信號。
            const long_signal = k_green && change_array.get(0) < change_array.get(1);
            //綠K + second_d縮小（<0）：上漲動能減少。
            const small_short_signal = k_green && second_d < 0;
            //紅K + second_d放大（>0）：強烈的下跌信號。
            const short_signal = k_green && second_d > 0;
            //紅K + second_d縮小（<0）：下跌的動能減少。
            const small_long_signal = k_green && second_d < 0;
            //突破壓力 (價格高於20MA和60MA)
            const lcon1 = close <= sma20 && close <= sma60;
            //爆量 (交易量>SMA20的交易量的三倍)
            const vol_con = volume > volume20ma * 1;
            //MACD上穿0軸
            const macd_cross_above = macd > 0 && macd_array.get(1) < 0;
            const histArray = [
                hist_array.get(0),
                hist_array.get(1),
                hist_array.get(2),
                hist_array.get(3),
                hist_array.get(4),
            ];
            const avg_hist = this.averageList(histArray);
            //
            const increasingStreak = hist_array.get(0) < hist_array.get(1); //histArray.slice(-2, -1)[0]
            //----------------------------------------------------------------
            // YC後端條件
            // 1. 布林道擴張 or 變化率的變化率增加且紅K
            // >>>> 變化率調整
            // 2. 價格高於20MA和60MA
            // 3. 爆量(交易量>SMA20的交易量的三倍)
            // 4. MACD量增或是上穿
            // >>>>量增調整 macd 5ma countif 5ma > 5ma[1]
            const condition1 = expansion || long_signal;
            const condition2 = lcon1;
            const condition3 = vol_con;
            const condition4 = increasingStreak;
            //條件
            const long = condition1 &&
                condition2 &&
                condition3 &&
                condition4 &&
                volume_array.get(1) * close_array.get(1) * 1000 > 1e8;
            //--
            const buy_icon = this._context.new_var();
            // if (dayAPI(itime) > dayAPI('2023/09/27')) {
            //   console.log(
            //     this._context.symbol.tickerid,
            //     dayAPI(itime).format('YYYY-MM-DD'),
            //     { 布林2l4: expansion },
            //     { 變化率增收紅: long_signal },
            //     { 價格高於20MA和60MA: lcon1 },
            //     { 爆量: vol_con },
            //     { MACD量增: increasingStreak },
            //     { MACD上穿: macd_cross_above },
            //     { condition1: condition1 },
            //     { condition2: condition2 },
            //     { condition3: condition3 },
            //     { condition4: condition4 },
            //     { condition5: volume_array.get(1) * close_array.get(1) * 1000 > 1e8 },
            //     {
            //       陣列長度: histArray.length,
            //       陣列: histArray,
            //       量增: avg_hist >= histArray.slice(-2, -1)[0],
            //       avghist: avg_hist,
            //       倒數第二: histArray.slice(-2, -1)[0],
            //       倒數第二新: hist_array.get(3),
            //     },
            //   )
            // }
            if (long) {
                buy_icon.set(-1);
            }
            else
                buy_icon.set(NaN);
            return [buy_icon.get(0), 2, 0];
        },
    },
    metainfo: {
        _metainfoVersion: 40,
        scriptIdPart: '',
        is_hidden_study: false,
        is_price_study: false,
        isCustomIndicator: true,
        plots: [
            { id: 'plot_0', type: 'line' },
            { id: 'plot_1', type: 'line' },
            { id: 'plot_2', type: 'line' },
        ],
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 5,
                    trackPrice: !1,
                    transparency: 15,
                    visible: !0,
                    color: '#22aa44',
                },
                plot_1: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 100,
                    visible: !0,
                    color: '#000000',
                },
                plot_2: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: !1,
                    transparency: 10,
                    visible: !0,
                    color: '#aaaaaa',
                },
            },
            precision: 0,
            inputs: {},
        },
        styles: {
            plot_0: {
                title: '動能',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_1: {
                title: '',
                histogramBase: 0,
                joinPoints: !1,
            },
            plot_2: {
                title: '0',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
    },
});

import { memo } from 'react';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
import { flex, fill } from '~/modules/AppLayout/FlexGridCss';
export const FirebaseSymbolList = memo(function FirebaseSymbolList() {
    const longFirebase = useFirebaseValue('sungop_signal', 'long');
    const shortFirebase = useFirebaseValue('sungop_signal', 'short');
    return (<div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa3333'>
        多方精選 數量:{longFirebase?.symbols?.length}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={longFirebase?.symbols ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#33aa33'>
        空方精選 數量:{shortFirebase?.symbols?.length}
      </styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={shortFirebase?.symbols ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
    </div>);
});
const styleds = {
    SymbolLsitTitle: styled.div `
    ${flex.h.allCenter}
    height: 28px;
    width: 100%;
    background-color: ${options => options.fill};
    color: #eeeeee;
    border-radius: 5px 5px 0px 0px;
  `,
    SymbolLsitContent: styled.div `
    width: 100%;
    height: calc(50% - 30px);
  `,
    symbolMark: styled.div `
    width: 10px;
    height: 10px;
    background-color: ${options => options.fill};
    border-radius: 50%;
  `,
};

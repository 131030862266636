import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fill_horizontal_cross_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import QuoteValueBody from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody';
import { quoteChangeColor, quoteChangeType, } from '~/pages/investment_demo/component/symbolQuote/SymbolQuoteWithChart';
import dayAPI from '~/utils/dayAPI';
const mainSymbol = ['TSEA', 'TX-1', 'OTCA'];
export const TimeCard = memo(function TimeCard(props) {
    const dayTime = new Date();
    const [time, setTime] = useState(dayTime);
    const theme = useThemeStore(t => t.theme);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const result = dayAPI(time).format('HH:mm:ss');
    //報價部分
    const value = signalrHooks2.useQuotes(mainSymbol);
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    return (<classes.container className={theme}>
      <classes.time.body>{result}</classes.time.body>
      <classes.quote.body>
        {value.map(s => {
            const changeType = quoteChangeType(s.close, s.prevRef, false);
            return (<classes.quote.item key={s.symbol}>
              <span>{dictionary[s.symbol]}</span>
              <classes.quote.quote className={theme} changeType={changeType}>
                <QuoteValueBody.arrow.Display quote={s} changeType='closeChange'/>
                {s.close}
              </classes.quote.quote>
            </classes.quote.item>);
        })}
      </classes.quote.body>
    </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    height: 68px;
    font-size: 28px;
    border-radius: 4px;
    padding: 2px;
    &.dark {
      background-color: #2f3032;
      color: #fafafa;
      border: 1px solid #676767;
    }
    &.light {
      background-color: #d6d6d6;
      color: #252525;
      border: 1px solid #aaaaaa;
    }
  `,
    time: {
        body: styled.div `
      ${fill_vertical_all_center};
    `,
    },
    quote: {
        body: styled.div `
      ${fill_vertical_all_center};
      font-size: 14px;
    `,
        item: styled.div `
      display: grid;
      grid-template-columns: 40% 60%;
      width: 100%;
      & > * {
        ${fill_horizontal_cross_center};
      }
    `,
        quote: styled.span `
      &.dark {
        color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffff00', '#ffffff')};
      }
      &.light {
        color: ${props => quoteChangeColor(props.changeType, '#aa0000', '#009900', '#ffaa00', '#252525')};
      }
    `,
    },
};

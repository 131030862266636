import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '../heineken_template/_private/store';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { ManualSignalMain } from '~/modules/investment-consultant/signalNotify/ManualSignalMain';
import { jerry597_store } from './jerry597_store';
import styled from '@emotion/styled';
import { SymbolQuoteWithChart } from '../investment_demo/component/symbolQuote/SymbolQuoteWithChart';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { TimeCard } from './component/TimeCard';
import { useFirebaseValue } from '~/hooks/useFirebaseValue';
export const Jerry597_SidePane2 = memo(function Jerry597_SidePane2() {
    const charting = useSnapshot(store.charting);
    const theme = useThemeStore(t => t.theme);
    const longSymbol = useFirebaseValue('jerry597_signal', 'long');
    const state = useSnapshot(jerry597_store);
    return (<classes.container className={theme}>
      <classes.componeyLogo className={theme} src='jerry597/logo.png'/>
      <TimeCard />
      <classes.mode.body>
        <classes.mode.button className={theme} onClick={() => (jerry597_store.pageMode2 = 'paper')} active={state.pageMode2 === 'paper'}>
          盤前精華
        </classes.mode.button>
        <classes.mode.button className={theme} onClick={() => (jerry597_store.pageMode2 = 'focus')} active={state.pageMode2 === 'focus'}>
          精選重點
        </classes.mode.button>
      </classes.mode.body>

      {state.pageMode2 === 'paper' && (<classes.body>
          <ManualSignalMain agent={'jerry597'} limit={10} modal={true}/>
        </classes.body>)}

      {state.pageMode2 === 'focus' && (<classes.body>
          {longSymbol?.symbols.map(s => {
                return (<SymbolQuoteWithChart symbol={s} key={s}/>);
            })}
        </classes.body>)}
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    padding: 4px;
    &.dark {
      background-color: #232222;
      color: #ffffff;
    }
    &.light {
      background-color: #f7f7f7;
      color: #232222;
    }
  `,
    body: styled.div `
    ${fill_vertical_cross_center}
    ${scrollbar2Css};
    height: calc(100% - 178px);
    font-size: 16px;
    border-radius: 4px;
    gap: 4px;
    &.dark {
      background: linear-gradient(90deg, #252525, #333333);
    }
    &.light {
      background: linear-gradient(90deg, #fafafa, #f7f7f7);
    }
  `,
    mode: {
        body: styled.div `
      ${fill_horizontal_all_center};
      height: 30px;
      gap: 4px;
    `,
        button: styled.div `
      ${fill_horizontal_all_center};
      border-radius: 4px;
      cursor: pointer;
      font-size:14px;
      &.dark {
        background-color: ${props => (props.active ? '#bd3b1e;' : '#232323;')}
        color: ${props => (props.active ? '#ffffff;' : '#aaaaaa;')}
        border: 1px solid #898989;
        &:hover{
          border: 1px solid #eeeeee;
          transition:0.5s;
        }
      }
      &.light {
        background-color: ${props => (props.active ? '#bd3b1e;' : '#cecece;')}
        color: ${props => (props.active ? '#ffffff;' : '#787878;')}
        border: 1px solid #898989;
        &:hover{
          border: 1px solid #232323;
          transition:0.5s;
        }
      }
    `,
    },
    componeyLogo: styled.img `
    width: 100%;
    height: 64px;
    border-radius: 4px;
    padding: 2px;
    &.dark {
      background-color: #2f3032;
      border: 1px solid #676767;
    }
    &.light {
      background-color: #d6d6d6;
      border: 1px solid #aaaaaa;
    }
  `,
};

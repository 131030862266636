import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useStockWeightResource } from '~/modules/chips/useChipResource';
import StockKbarBoxView from '~/pages/freeman/component/stockbarBoxView';
import StockContribtuionTable from '~/pages/futuresai/monitors/component/StockContribtuionTable';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
export const StockContent = memo(function StockContent() {
    const state = useSnapshot(staticStore);
    const twseWeight = useStockWeightResource('TWSE');
    const tw50Stock = state.symbol.tw50;
    const tw50Data = useMemo(() => {
        return twseWeight
            .filter(([k]) => tw50Stock?.indexOf(k) !== -1)
            .map(([k, v]) => ({ symbol: k, percentage: v.percentage }))
            .sort((a, b) => b.percentage - a.percentage);
    }, [tw50Stock, twseWeight]).slice(0, 12);
    const tw50Symbol = tw50Data.map(data => data.symbol) ?? [''];
    const tw50Value = signalrHooks2.useQuotes(tw50Symbol);
    useEffect(() => {
        signalrStore2.addQuote(tw50Symbol);
        return () => {
            signalrStore2.removeQuote(tw50Symbol);
        };
    }, [JSON.stringify(tw50Symbol)]);
    return (<classes.container>
      <classes.title>權值股表現</classes.title>
      <div css={css `
          height: 216px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #565656;
        `}>
        <StockKbarBoxView quote={tw50Value}/>
      </div>
      <classes.title>點數貢獻概況</classes.title>
      <div css={css `
          height: auto;
          width: 100%;
        `}>
        <StockContribtuionTable.Display limit={10}/>
      </div>
      {/* <div>市場產業概況</div>
        <div
          css={css`
            width: 100%;
            height: calc(100% - 640px);
          `}
        >
          <SymbolRankingHorizontalBar.Display
            symbols={state.symbol.stockCategory.map(s => s.index_symbol).slice(0, 25) ?? []}
            type={'changePercent'}
            fontSize={15}
            nameLabelWidth={48}
            barSize={22}
          />
        </div> */}
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #3c3c49;
    border-radius: 4px;
  `,
};

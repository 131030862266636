import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Edwin_Sidebar1 } from './edwin_SidePane1';
import { Edwin_Sidebar2 } from './edwin_SidePane2';
import { Edwin_TopBar } from './edwin_TopBar';
import { Edwin_LoginView } from './edwin_LoginView';
import { Edwin_Footer } from './edwin_Footer';
import { Edwin_Col1 } from './edwin_Col1';
import { edwin_initStrategies } from './edwin_initStrategies';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
export const edwin_init = (templateProps) => {
    edwin_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
    // store.charting.widgetOptions = {
    //   ...store.charting.widgetOptions,
    //   custom_css_url: '../../edwin/chartingTheme.css',
    // }
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.downColor': '#429488',
        'mainSeriesProperties.candleStyle.upColor': '#e15241',
        'mainSeriesProperties.candleStyle.wickDownColor': '#dedede',
        'mainSeriesProperties.candleStyle.wickUpColor': '#dedede',
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': false,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#2f2f2f',
        'paneProperties.horzGridProperties.color': '#2f2f2f',
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#141721',
        'paneProperties.backgroundGradientEndColor': '#141721',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 14,
        'scalesProperties.textColor': '#dedede',
        'scalesProperties.lineColor': '#dedede',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TSEA',
        interval: 'D',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: false,
        disabledHeaderCompare: false,
        disabledGoToDate: true,
        disabledHeaderSaveload: false,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 160px calc(100% - 216px) 132px;
    grid-template-columns: 336px 336px 1fr;
    place-content: flex-start;

    background: linear-gradient(to bottom, #151c2b, #141c2a);

    .Chart {
      height: calc(100vh - 216px);
    }
    .Drawer1 {
      padding: 4px;
    }
    .Drawer2 {
      padding: 4px;
    }
    .Col1 {
      overflow-y: auto;
      padding: 4px 0;
    }

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Drawer2 Col1'
      'Drawer1 Drawer2 Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css `
      grid-template-rows: 48px 160px 1fr 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 160px 1fr 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Col1 Col1 Col1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `;
    //templateProps.layout.Charting = undefined
    templateProps.layout.login = Edwin_LoginView;
    templateProps.layout.Drawer1 = Edwin_Sidebar1;
    templateProps.layout.Drawer2 = Edwin_Sidebar2;
    templateProps.layout.Row1 = Edwin_TopBar;
    templateProps.layout.Row2 = Edwin_Footer;
    templateProps.layout.Col1 = Edwin_Col1;
    templateProps.hooks.add(useSignalrStart2_1);
};

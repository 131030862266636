import { css } from '@emotion/react';
import { memo } from 'react';
import { apirc } from '~/configs/apirc';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { useIntraday, useLatestTradeDateSize, } from '~/modules/screener/containers/useStockScreenerResource';
import { SignalWallId } from '~/modules/signal-wall/SignalWallId';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { useStockPriceChangeDistributionState } from '~/pages/futuresai/stock-price-change-distribution/components/useStockPriceChangeDistributionState';
import FuiButton from '~/pages/heineken_template/components/FuiButton';
import { store } from '~/pages/heineken_template/_private/store';
import { DataChart } from '~/pages/lung88988_stock/_private/Component/lung88988Stock_SidePane_ForOthers';
import dayAPI from '~/utils/dayAPI';
import { m168168_agentStore } from './m168168Stock_store';
import { styleds } from './styleds';
export const M168168Stock_SidePane1 = memo(function M168168Stock_SidePane1() {
    //XQ選股輸出至訊號牆 --測試階段
    const lastTradeDatePick = useLatestTradeDateSize({ size: 5 });
    const lastTradeDate = lastTradeDatePick?.[0];
    const beforeLastTradeDate = lastTradeDatePick?.[1];
    //盤中: 09:00 ~ 15:00 => lastTradeDate
    //收盤後: 15:00 ~ 05:00 => beforeLastTradeDate
    const nowTime = dayAPI().hour() >= 9 && dayAPI().hour() <= 15 && dayAPI().minute() <= 30;
    const intraday = useIntraday();
    //lastTradeDate === tradeDate
    const useBeforeLastTradeDate = intraday === lastTradeDate ? beforeLastTradeDate : lastTradeDate;
    const getSignalWallDateDate = nowTime ? lastTradeDate : beforeLastTradeDate;
    console.log({ intraday: intraday }, { lastTradeDate: lastTradeDate }, { beforeLastTradeDate: beforeLastTradeDate }, { signalWall: getSignalWallDateDate }, { useBeforeLastTrade: useBeforeLastTradeDate });
    const fromDate = dayAPI(useBeforeLastTradeDate);
    const currentDate = dayAPI();
    const longSymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.多方選股,
        from: fromDate,
        to: currentDate,
    })
        .data?.map(f => f.symbol);
    const shortSymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.空方選股,
        from: fromDate,
        to: currentDate,
        size: 50,
        page: 1,
    })
        .data?.map(f => f.symbol);
    const cheapSymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.撿便宜,
        from: fromDate,
        to: currentDate,
        size: 50,
        page: 1,
    })
        .data?.map(f => f.symbol);
    //const getCheapSymbol = cheapSymbol?.filter((item, data) => cheapSymbol.indexOf(item) === data)
    const shortFlyInSkySymbol = apirc.signalWall.data
        .useAsync({
        signal_id: SignalWallId.短線軋空,
        from: fromDate,
        to: currentDate,
        size: 50,
        page: 1,
    })
        .data?.map(f => f.symbol);
    const longSymbol_ = longSymbol?.filter((symbol, index) => longSymbol.indexOf(symbol) === index);
    const shortSymbol_ = shortSymbol?.filter((symbol, index) => shortSymbol.indexOf(symbol) === index);
    const shortFlyInSkySymbol_ = shortFlyInSkySymbol?.filter((symbol, index) => shortFlyInSkySymbol.indexOf(symbol) === index);
    return (<styleds.SideBar>
      <styleds.ScreenerDateString>{useBeforeLastTradeDate}盤後選股結果</styleds.ScreenerDateString>
      <div css={css `
          ${fill_horizontal_all_center};
          height: 32px;
          gap: 4px;
        `}>
        <m168168_agentStore.tabsOfMain.WithButton to='強勢選股'>
          <FuiButton.Display>強勢選股</FuiButton.Display>
        </m168168_agentStore.tabsOfMain.WithButton>

        <m168168_agentStore.tabsOfMain.WithButton to='短線軋空'>
          <FuiButton.Display>短線軋空</FuiButton.Display>
        </m168168_agentStore.tabsOfMain.WithButton>
      </div>
      <styleds.ScreenerContent>
        <m168168_agentStore.tabsOfMain.WithContent for='強勢選股'>
          <styleds.ScreenerTitle variant='buy'>強勢多</styleds.ScreenerTitle>
          <styleds.ScreenerList>
            <SimpleQuoteListInteractWithChart data={longSymbol_ ?? []} chart={store.charting}/>
          </styleds.ScreenerList>
          <styleds.ScreenerTitle variant='short'>強勢空</styleds.ScreenerTitle>
          <styleds.ScreenerList>
            <SimpleQuoteListInteractWithChart data={shortSymbol_ ?? []} chart={store.charting}/>
          </styleds.ScreenerList>
        </m168168_agentStore.tabsOfMain.WithContent>

        <m168168_agentStore.tabsOfMain.WithContent for='短線軋空'>
          <SimpleQuoteListInteractWithChart data={shortFlyInSkySymbol_ ?? []} chart={store.charting}/>
        </m168168_agentStore.tabsOfMain.WithContent>
      </styleds.ScreenerContent>
      <styleds.DataChartContent>
        <useStockPriceChangeDistributionState.Provider>
          <DataChart />
        </useStockPriceChangeDistributionState.Provider>
      </styleds.DataChartContent>
    </styleds.SideBar>);
});

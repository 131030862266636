import { css } from '@emotion/react';
import { Autocomplete } from '@mantine/core';
import { memo, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useSnapshot } from 'valtio';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { store } from '~/pages/heineken_template/_private/store';
import { a2330_store } from '~/pages/a2330/a2330_store';
import styled from '@emotion/styled';
export const SymbolSearch = memo(function SymbolSearch(props) {
    const { symbols } = props;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const [value, setValue] = useState('');
    const stockSymbols = symbols
        .filter(symbol => dictionary.hasOwnProperty(symbol))
        .map(symbol => ({
        symbol: symbol,
        name: dictionary[symbol],
    }));
    const symbol = useSnapshot(store.charting).symbol;
    return (<Autocomplete icon={<AiOutlineSearch size={22}/>} placeholder='商品代號' data={stockSymbols.map(s => s.symbol + s.name)} variant='unstyled' limit={100} maxDropdownHeight={200} value={value} rightSection={<ConcelButton setValue={setValue}/>} onChange={item => {
            // 更新輸入框的內容
            setValue(item);
        }} onItemSubmit={item => {
            const symbolNumber = String(parseInt(item.value, 10));
            symbolNumber.match(/^[\d]{4}$/) && store.charting.changeSymbol(symbolNumber);
            // 更新搜尋標的
            a2330_store.searchedSymbol = [symbolNumber];
            // 更新輸入框的內容
            setValue(item.value);
        }} css={css `
        width: 100%;
        height: 40px;
      `}/>);
});
const ConcelButton = memo(function ConcelButton(prop) {
    return (<styleds.container onClick={() => {
            // 清空搜尋標的
            a2330_store.searchedSymbol = [];
            prop.setValue('');
        }}>
      <styleds.logo src='a2330/cancel.png'/>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    border-radius: 6px;
    padding: 4px;
  `,
    logo: styled.img `
    width: 50%;
  `,
};

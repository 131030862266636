import { css } from '@emotion/react';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { store } from '~/pages/heineken_template/_private/store';
import { WatchListAddListButton } from './WatchListAddListButton';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const WatchListAddButton2 = memo(function WatchListAddButton2() {
    const charting = useSnapshot(store).charting;
    const stockType = charting.symbol.match(/^\d{4,5}[A-Z]?$/);
    return (<div css={css `
        ${fill_horizontal_all_center};
        width: 64px;
        height: 24px;
        background-color: ${stockType ? '#3563b7' : 'transparent'};
        border-radius: 4px;
        &:hover {
          cursor: pointer;
          background-color: ${stockType ? '#4e6da6' : 'transparent'};
        }
        &:active {
          background-color: ${stockType ? '#6e7d97' : 'transparent'};
        }
      `}>
      {stockType && <WatchListAddListButton symbol={charting.symbol}/>}
    </div>);
});

import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
import { flex, fill } from '~/modules/AppLayout/FlexGridCss';
import { useVolumeMa } from '~/modules/screener/containers/useStockScreenerResource';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
export const EntrySymbolList = memo(function TodaySignal() {
    /** 即時訊號 */
    //最近的一天有交易資料的日期
    const baseDate = useSnapshot(staticStore).tradedDate.day0.format('YYYY-MM-DD');
    /** 依照交易量(5日均量>5000張)篩選當作基本股池 */
    const tradingVolumeSymbolLongPool = useVolumeMa({
        date: baseDate,
        ma: 5,
        volume_gte: 5000,
    }).data;
    /** 依照交易量(5日均量>500張)篩選當作基本股池 */
    const tradingVolumeSymbolShortPool = useVolumeMa({
        date: baseDate,
        ma: 5,
        volume_gte: 500,
    }).data;
    const longStock = useVirtualAccountOrderPre('sungop_stock_long', 'B', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'B')
        // 篩選出交易量5日均量>5000張交易量標的
        .filter(item1 => tradingVolumeSymbolLongPool?.some(item2 => item2 === item1.symbol))
        .map(datum => datum.symbol);
    const shortStock = useVirtualAccountOrderPre('sungop_stock_short', 'S', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'S')
        // 篩選出交易量5日均量>5000張交易量標的
        .filter(item1 => tradingVolumeSymbolShortPool?.some(item2 => item2 === item1.symbol))
        .map(datum => datum.symbol);
    const longLength = longStock?.length;
    const shortLength = shortStock?.length;
    return (<div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa3333'>波浪向上 數量:{longLength}</styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={longStock ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#33aa33'>波浪向下 數量:{shortLength}</styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={shortStock ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
    </div>);
});
const styleds = {
    SymbolLsitTitle: styled.div `
    ${flex.h.allCenter}
    height: 28px;
    width: 100%;
    background-color: ${options => options.fill};
    color: #eeeeee;
    border-radius: 5px 5px 0px 0px;
  `,
    SymbolLsitContent: styled.div `
    width: 100%;
    height: calc(50% - 30px);
  `,
    symbolMark: styled.div `
    width: 10px;
    height: 10px;
    background-color: ${options => options.fill};
    border-radius: 50%;
  `,
};

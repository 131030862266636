import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
import { useIndicatorStore } from '~/store/useIndicatorStore';
export const stock_power = createIndicator({
    displayName: '主力控盤',
    id: 'stockpower',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            // MACD
            const state = useIndicatorStore.getState();
            //商品資訊
            const getSymbolnumber = this.PineJS.Std.ticker(this._context);
            state.symbolNumber = String(getSymbolnumber.replace(/"/g, '').replace('}', ''));
            state.update(state);
            const close = this.PineJS.Std.close(this._context);
            const length1 = 12;
            const length2 = 26;
            const length3 = 9;
            const close_array = this._context.new_var(close);
            const ema1 = this.PineJS.Std.ema(close_array, length1, this._context);
            const ema2 = this.PineJS.Std.ema(close_array, length2, this._context);
            const diff = ema1 - ema2;
            const diff_array = this._context.new_var(diff);
            const ema3 = this.PineJS.Std.ema(diff_array, length3, this._context);
            const colorIndex = diff - ema3 >= 0 ? 1 : 0;
            return [diff - ema3, colorIndex];
        },
    },
    metainfo: {
        _metainfoVersion: 27,
        isTVScript: !1,
        isTVScriptStub: !1,
        is_hidden_study: !1,
        plots: [
            {
                id: 'plot_0',
                type: 'line',
            },
            {
                id: 'PowerPalette',
                type: 'colorer',
                target: 'plot_0',
                palette: 'PowerPalette',
            },
        ],
        palettes: {
            PowerPalette: {
                colors: {
                    0: {
                        name: '+力道',
                    },
                    1: {
                        name: '-力道',
                    },
                },
            },
        },
        defaults: {
            styles: {
                plot_0: {
                    linestyle: 0,
                    linewidth: 4,
                    plottype: 4,
                    trackPrice: !1,
                    transparency: 0,
                    visible: !0,
                    color: '#000000',
                },
            },
            precision: 4,
            inputs: {},
            palettes: {
                PowerPalette: {
                    colors: {
                        0: {
                            color: '#009900',
                            width: 0,
                            style: 0,
                        },
                        1: {
                            color: '#d75442',
                            width: 0,
                            style: 0,
                        },
                    },
                },
            },
        },
        styles: {
            plot_0: {
                title: 'Histogram',
                histogramBase: 0,
                joinPoints: !1,
            },
        },
        inputs: [],
        is_price_study: !1,
        scriptIdPart: '',
    },
});

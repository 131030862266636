import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
import { Preset_Topbar } from '../heineken_template/_preset/preset_Topbar';
import { ThemeToggle } from '~/components/theme/ThemeToggle';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const Jerry597_Topbar = memo(function Jerry597_Topbar() {
    const theme = useThemeStore(state => state.theme);
    const { isPc } = useMedia();
    return (<div>
      {isPc && (<div className={theme} css={css `
            ${fill_horizontal_all_center}
            justify-content: end;
            padding: 0px 16px;
            &.dark {
              background-color: #343434;
            }
            &.light {
              background-color: #d0d0d0;
            }
          `}>
          <ThemeToggle css={css `
              height: 40px;
            `} onClick={event => {
                store.charting.setThemeMode(useThemeStore.getState().theme);
                store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators);
                store.charting.updateFromThemeMode();
            }}/>
          <ChartingServerSwitch className={theme} charting={store.charting} css={css `
              &.dark {
                background-color: #565656;
              }
              &.light {
                background-color: #f0f0f0;
              }
            `}/>
          <UserAvatarAsDialogButton />
        </div>)}
      {!isPc && (<Preset_Topbar className={theme} css={css `
            &.dark {
              background-color: #343434;
            }
            &.light {
              background-color: #acacac;
            }
          `} showLeftBurger showRightBurger componentsInRight={<div css={fill_horizontal_all_center}>
              <ThemeToggle css={css `
                  height: 40px;
                `} onClick={event => {
                    store.charting.setThemeMode(useThemeStore.getState().theme);
                    store.charting.replaceIndicators(store.charting.strategyConfigs[0].indicators);
                    store.charting.updateFromThemeMode();
                }}/>
              <ChartingServerSwitch className={theme} charting={store.charting} css={css `
                  &.dark {
                    background-color: #565656;
                  }
                  &.light {
                    background-color: #f0f0f0;
                  }
                `}/>
              <UserAvatarAsDialogButton />
            </div>}/>)}
    </div>);
});

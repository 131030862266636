import styled from '@emotion/styled';
import { useEffect } from 'react';
import { BsXSquareFill } from 'react-icons/bs';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { scrollbar2Css } from '~/css/scrollbarCss';
export const WatchListSymbolQuote = ({ groupName, children, }) => {
    const { state, acts } = useSymbolWatchList(groupName);
    const symbolListArray = state.symbolListArray;
    const sortData = useSignalr2QuoteSort(symbolListArray?.map(datum => datum)) ?? [];
    useEffect(() => {
        signalrStore2.addQuote(symbolListArray);
        return () => {
            signalrStore2.removeQuote(symbolListArray);
        };
    }, [JSON.stringify(symbolListArray)]);
    return (<classes.container>
      {sortData.map(symbol => {
            return (<classes.body key={symbol}>
            <classes.removeButton onClick={() => acts.removeSymbol(symbol)}>
              <BsXSquareFill size={12} fill={'#aaaaaa'}/>
            </classes.removeButton>
            <div>{children(symbol)}</div>
          </classes.body>);
        })}
    </classes.container>);
};
const classes = {
    container: styled.div `
    ${scrollbar2Css};
    gap: 2px;
  `,
    body: styled.div `
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 12px 1fr;
    gap: 2px;
  `,
    removeButton: styled.div `
    ${fill_vertical_all_center};
  `,
};

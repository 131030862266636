import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { Kbar } from '../Kbar';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { SortArrow } from '../content/SortArrow';
import { useBig2DataStore } from '../content/Big2Content';
import { store } from '~/pages/heineken_template/_private/store';
import { edwin_store } from '../../edwin_store';
import { useVolumeUpdateAnimationCSS } from './useVolumeUpdateAnimationCss';
import { css } from '@emotion/react';
import { WatchListAddListButton } from '../WatchListAddListButton';
const KbarTrustBody = memo(function KbarTrustBody(props) {
    const quote = props.quote;
    const trust = props.data;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const value = useSnapshot(signalrStore2.values.quote)[quote.symbol];
    const quoteChanges = processQuoteToReadable(value);
    const quoteChangesPercent = quoteChanges.closeChangePercent;
    const changeArrow = quoteChangesPercent > 0 ? '+' : '';
    const close = value?.close ?? 0;
    const volume = value?.volume ?? 0;
    const amount = (close * 1000 * volume) / 1e8;
    const trustTr = close * trust.diff_volume * 1000;
    const trustValue = trustTr / 1e8;
    const trustUnit = '億';
    const trustFloat = 2;
    const charting = useSnapshot(store).charting;
    const selected = charting.symbol === quote.symbol;
    const valueAnime = useVolumeUpdateAnimationCSS(value?.volume);
    return (<styleds.body.container onClick={() => store.charting.changeSymbol(quote.symbol)} selected={selected} css={css `
        ${valueAnime};
      `}>
      <div onClick={event => {
            event.stopPropagation();
        }}>
        <WatchListAddListButton symbol={quote.symbol}/>
      </div>
      <styleds.body.content>
        <Kbar quote={value}/>
      </styleds.body.content>
      <styleds.body.symbolNameContent>
        <span>{dictionary[quote.symbol]}</span>
        <span>{quote.symbol}</span>
      </styleds.body.symbolNameContent>
      <styleds.body.quote quote={quoteChangesPercent}>
        {changeArrow}
        {quoteChangesPercent.toFixed(2)}
      </styleds.body.quote>
      <styleds.body.content>
        {trustValue.toFixed(trustFloat)}
        {trustUnit}
      </styleds.body.content>
      <styleds.body.content>{trust.diff_volume_percentage.toFixed(1)}%</styleds.body.content>
      <styleds.body.content>{amount.toFixed(1)}億</styleds.body.content>
    </styleds.body.container>);
});
const KbarTrustHeader = memo(function KbarTrustHeader() {
    const state = useSnapshot(edwin_store);
    const sortState = useSnapshot(useSortSignalrDataStore);
    const sortTrustState = useSnapshot(useBig2DataStore);
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrDataStore.sortKey = sortKey;
        useSortSignalrDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    const toggleTrustOrderKey = sortTrustState.orderKey === 'desc' ? 'asc' : 'desc';
    const sort = sortState.sortKey;
    const sortTrust = sortTrustState.sortKey;
    const handleTurstSelected = useCallback((sortKey) => {
        ;
        (useBig2DataStore.sortKey = sortKey), (useBig2DataStore.orderKey = toggleTrustOrderKey);
    }, [toggleTrustOrderKey]);
    return (<styleds.header.container>
      <styleds.header.item selectd={false}/>
      <styleds.header.item selectd={sort === 'close'} onClick={() => {
            handleColumnSelected('changePrecent');
            handleTurstSelected('none');
        }}>
        K棒
      </styleds.header.item>
      <styleds.header.item selectd={sort === 'symbol'} onClick={() => {
            handleColumnSelected('symbol');
            handleTurstSelected('none');
        }}>
        代號
        <SortArrow sortKey='symbol' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
      <styleds.header.item selectd={sort === 'changePrecent'} onClick={() => {
            handleColumnSelected('changePrecent');
            handleTurstSelected('none');
        }}>
        幅度%
        <SortArrow sortKey='changePrecent' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
      <styleds.header.item selectd={sortTrust === 'diff'} onClick={() => {
            handleTurstSelected('diff');
            handleColumnSelected('none');
        }}>
        {state.big2ActiveType === 'buy' ? '買' : '賣'}超金額
        <SortArrow sortKey='diff' sotre={useBig2DataStore}/>
      </styleds.header.item>
      <styleds.header.item selectd={sortTrust === 'diffPercent'} onClick={() => {
            handleTurstSelected('diffPercent');
            handleColumnSelected('none');
        }}>
        佔比
        <SortArrow sortKey='diffPercent' sotre={useBig2DataStore}/>
      </styleds.header.item>
      <styleds.header.item selectd={sort === 'amount'} onClick={() => {
            handleColumnSelected('amount');
            handleTurstSelected('none');
        }}>
        成交值
        <SortArrow sortKey='amount' sotre={useSortSignalrDataStore}/>
      </styleds.header.item>
    </styleds.header.container>);
});
export default { body: KbarTrustBody, header: KbarTrustHeader };
const styleds = {
    header: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 16% 20% 16% 16%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      background-color: #1a1e27;
      user-select: none;
      padding: 6px 2px;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      font-size: 12px;
      background-color: ${props => (props.selectd ? '#303843' : 'transparent')};
      border: 0.6px solid ${props => (props.selectd ? '#e29134' : 'transparent')};
      border-radius: 4px;
    `,
    },
    body: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 14% 18% 18% 18%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 13px;
      cursor: pointer;
      border: 1px solid ${props => (props.selected ? '#c7c7c7' : 'transparent')};
      background-color: #141821;
      &:hover {
        background-color: #1b1e26;
        transition: 0.3s;
      }
      flex-shrink: 0;
    `,
        addButton: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
      height: 50%;
      margin-top: 9px;
      border-radius: 4px;
      &:hover {
        transform: scale(1.2);
      }
      &:active {
        transform: scale(1.4);
      }
    `,
        content: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
    `,
        quote: styled.div `
      ${fill_horizontal_all_center};
      width: 42px;
      border-radius: 6px;
      color: ${props => (props.quote > 0 ? '#d51f1f' : props.quote < 0 ? '#59c03d' : '#cccc22')};
      font-size: 13px;
      font-weight: 500;
      justify-content: end;
    `,
        symbolNameContent: styled.div `
      ${fill_vertical_all_center};
      align-items: start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      &:hover {
        overflow: visible;
      }
      & > * {
        line-height: 14px;
      }
    `,
    },
};

import styled from '@emotion/styled';
import { memo } from 'react';
import { fontWeight600 } from '~/css/font';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const LogoCard = memo(function LogoCard() {
    return (<styleds.container>
      {/* <styleds.logo src='a2330/line.png' /> */}
      <styleds.disContent>強哥專業AI團隊</styleds.disContent>
      <styleds.disContent>LINE ID : @a2330</styleds.disContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_all_center};
    border-radius: 6px;
    padding: 4px;
  `,
    logo: styled.img `
    width: 50%;
  `,
    qrCode: styled.img `
    width: 25%;
  `,
    disContent: styled.div `
    font-size: 28px;
    ${fontWeight600};
  `,
};

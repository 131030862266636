import { css } from '@emotion/react';
import { memo, useState, useEffect } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { store } from '~/pages/heineken_template/_private/store';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { AppLink } from '~/components/AppLink';
import styled from '@emotion/styled';
import { fontWeight600 } from '~/css/font';
import { BiLinkExternal } from 'react-icons/bi';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { UndevelopedView } from '~/pages/goodpsy/component/UndevelopedView';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { useSnapshot } from 'valtio';
export const Lung88988_Topbar = memo(function Lung88988_Topbar() {
    const { isPc, isPhone } = useMedia();
    const me = useSnapshot(fr_me);
    const [moblieUser, setMoblieUser] = useState(false);
    useEffect(() => {
        const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'lung889881688' && s.productName === 'web_stock_moblie');
        if (tutorialProduct && !tutorialProduct.isExpired) {
            setMoblieUser(true);
        }
        else {
            setMoblieUser(false);
        }
    }, [me]);
    return (<div>
      {/* 手機專用版設定 */}
      {/* 非手機看不到 */}
      {!isPhone && moblieUser && <UndevelopedView />}
      {/* 手機專用版顯示特定版面 */}
      {isPhone && moblieUser && (<styleds.TopbarPhone>
          <Preset_Topbar componentsInRight={<div css={fill_horizontal_cross_center}>
                <ChartingServerSwitch charting={store.charting} css={css `
                    width: 108px;
                  `}/>
                <UserAvatarAsDialogButton />
              </div>}/>
        </styleds.TopbarPhone>)}

      {isPc && (<styleds.Topbar>
          <styleds.TopbarTitle>
            <styleds.TopbarLogo src='lung88988/favicon1.png'/>
            動能操盤系統-期貨版
          </styleds.TopbarTitle>
          <styleds.TopbarComponents>
            {/* <Lung88988VersionSwitch url='https://lung88988.moneycom.in/'>
              動能操盤系統-股票版
            </Lung88988VersionSwitch> */}
            <ChartingServerSwitch charting={store.charting} css={css `
                background-color: #1a2c41;
              `}/>
            <UserAvatarAsDialogButton />
          </styleds.TopbarComponents>
        </styleds.Topbar>)}
      {!isPc && (<styleds.TopbarPhone>
          <Preset_Topbar showLeftBurger showRightBurger componentsInRight={<div css={fill_horizontal_cross_center}>
                {/* <Lung88988VersionSwitch url='https://lung88988.moneycom.in/'>
                  股票版
                </Lung88988VersionSwitch> */}
                <ChartingServerSwitch charting={store.charting} css={css `
                    width: 108px;
                    background-color: #1a2c41;
                  `}/>
                <UserAvatarAsDialogButton />
              </div>}/>
        </styleds.TopbarPhone>)}
    </div>);
});
export const Lung88988VersionSwitch = memo(function Lung88988VersionSwitch(props) {
    return (<styleds.Container>
        <BiLinkExternal />
        <styleds.Link>
          <AppLink href={props.url} target='_blank'>
            {props.children}
          </AppLink>
        </styleds.Link>
      </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${flex.h.allCenter};
    height: 40px;
    background-color: #285b7b;
    border-radius: 5px;
    padding: 0px 8px;
    border: 1px solid #555555;
    gap: 8px;
    color: #eeeeee !important;
    white-space: nowrap;
    &:hover {
      background-color: #285b8b;
    }
  `,
    Link: styled.div `
    * {
      ${fill_horizontal_all_center};
      color: #eeeeee !important;
    }
  `,
    Topbar: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    background: url('lung88988/technology-lineflooer.png');
    background-color: #152640;
    background-size: 80% 50px;
    padding: 0px 8px;
  `,
    TopbarPhone: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #152640aa;
    border-bottom: 1px solid #777777;
  `,
    TopbarTitle: styled.div `
    ${flex.h.allCenter}
    font-size: 22px;
    background: linear-gradient(170deg, #eeeeee, #bbbbbb);
    background-clip: text;
    color: transparent;
    ${fontWeight600}
  `,
    TopbarLogo: styled.img `
    max-width: 40px;
    max-height: 100%;
  `,
    TopbarComponents: styled.div `
    ${flex.h.allCenter}
  `,
};

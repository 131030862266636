import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const EmptyScreenerDataView = memo(function EmptyScreenerDataView() {
    return (<classes.container>
      <classes.mainText>尚未有選股結果</classes.mainText>

      {/* <classes.message>成交值未達1000萬以上</classes.message> */}
      <classes.message>所有標的未符合選股條件</classes.message>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 8px;
    gap: 4px;
  `,
    mainText: styled.div `
    font-size: 15px;
    font-weight: 500;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 24px;
    width: 64%;
    font-size: 13px;
    background-color: #414551;
    border-radius: 4px;
  `,
    message: styled.div `
    ${fill_horizontal_all_center};
    height: 36px;
    width: 64%;
    font-size: 13px;
    background-color: #414551;
    border-radius: 4px;
    padding: 8px;
  `,
};

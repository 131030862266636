import { css } from '@emotion/react';
import { memo, useEffect, useState } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_cross_center, jc } from '~/modules/AppLayout/FlexGridCss';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
import { freemanStore } from './freemanStore';
import { FreemanLogOutView } from './component/FreemanLogOutView';
export const Freeman_TopBar = memo(function Freeman_TopBar() {
    const { isPc } = useMedia();
    const dayTime = new Date();
    const [time, setTime] = useState(dayTime);
    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    const taiwanTime = time.toLocaleTimeString('zh-TW', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Taipei',
    });
    return (<styleds.container>
      {isPc && (<div css={css `
            ${fill_horizontal_cross_center};
            ${jc.spaceBetween};
          `}>
          <span css={css `
              ${fill_horizontal_cross_center};
              width: auto;
              color: #ffffff;
              font-size: 22px;
              font-weight: 600;
            `}>
            <img src='freeman/freeman-favicon.png' css={css `
                height: 32px;
              `}/>
            期海任我行
          </span>
          <span css={css `
              color: #ffffff;
              font-size: 22px;
            `}>
            {taiwanTime}
          </span>
        </div>)}
      {isPc && (<styleds.desktopComponent>
          <ChartingServerSwitch charting={store.charting}/>
          <freemanStore.userAvatar.AvatarModal cssset={css `
              width: 280px;
              height: 360px;
              transition: 0.3s;
            `}>
            <FreemanLogOutView />
          </freemanStore.userAvatar.AvatarModal>
        </styleds.desktopComponent>)}
      {!isPc && (<Preset_Topbar showLeftBurger showRightBurger componentsInRight={<div css={css `
                ${fill_horizontal_cross_center};
                justify-content: end;
              `}>
              <ChartingServerSwitch charting={store.charting}/>
              <freemanStore.userAvatar.AvatarModal cssset={css `
                  width: 280px;
                  height: 360px;
                  transition: 0.3s;
                `}>
                <FreemanLogOutView />
              </freemanStore.userAvatar.AvatarModal>
            </div>}/>)}
    </styleds.container>);
});
//LogoutView
const styleds = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    padding: 0 6px;
    background-color: #303035;
  `,
    desktopTop: styled.div `
    ${fill_horizontal_cross_center};
    justify-content: end;
  `,
    desktopComponent: styled.div `
    ${fill_horizontal_cross_center};
    width: 240px;
    justify-content: end;
    gap: 4px;
  `,
};

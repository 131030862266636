import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import KbarOsSymbolQuote from '../quote/KbarOsSymbolQuote';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
export const OsFuturesContent = memo(function OsfuturesContent() {
    const osFutures = useSnapshot(staticStore).symbol.osFuturesList;
    const mainFutures = osFutures.overall.filter(s => s !== 'TX-1' && s !== 'TSEA' && s !== 'MTX-1');
    const energyFutures = osFutures.energy.filter(s => s !== '');
    const metalFutures = osFutures.metal.filter(s => s !== '');
    const allSymbol = mainFutures.concat(energyFutures).concat(metalFutures);
    useEffect(() => {
        signalrStore2.addQuote(allSymbol);
        return () => {
            signalrStore2.removeQuote(allSymbol);
        };
    }, [JSON.stringify(allSymbol)]);
    const indexValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(mainFutures, 2));
    const energyValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(energyFutures, 2));
    const metalValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(metalFutures, 2));
    return (<classes.container>
      {indexValue.length === 0 && <LoadCard.Loading />}
      {indexValue.length > 0 && (<classes.body>
          <classes.title>主要指數</classes.title>
          <KbarOsSymbolQuote.header sortGroup={2}/>
          <div css={css `
              ${fill_vertical_cross_center};
              height: auto;
              gap: 2px;
            `}>
            {indexValue.map(value => {
                return (<KbarOsSymbolQuote.body key={value.symbol} quote={value}>
                  {value.symbol}
                </KbarOsSymbolQuote.body>);
            })}
          </div>

          <classes.title>能源商品</classes.title>
          <div css={css `
              ${fill_vertical_cross_center};
              height: auto;
              gap: 2px;
            `}>
            {energyValue.map(value => {
                return (<KbarOsSymbolQuote.body key={value.symbol} quote={value}>
                  {value.symbol}
                </KbarOsSymbolQuote.body>);
            })}
          </div>
          <classes.title>貴金屬</classes.title>
          <div css={css `
              ${fill_vertical_cross_center};
              height: auto;
              gap: 2px;
            `}>
            {metalValue.map(value => {
                return (<KbarOsSymbolQuote.body key={value.symbol} quote={value}>
                  {value.symbol}
                </KbarOsSymbolQuote.body>);
            })}
          </div>
        </classes.body>)}
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 4px;
    font-size: 14px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
      background-color: #555;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      border-radius: 5px;
      background-color: #ccc;
    }
  `,
    body: styled.div `
    ${fill_vertical_cross_center};
    gap: 2px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 30px;
    background-color: #313644;
    border-radius: 4px;
  `,
};

import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { TbWaveSawTool } from 'react-icons/tb';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { store } from '~/pages/heineken_template/_private/store';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { Kbar } from '../Kbar';
import SimpleTrendChart from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/SimpleTrendChart';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { useGetPrice } from '~/modules/screener/components/invesement-consultant/SymbolChangeRanking/useGetPrice';
import { first, last } from 'lodash';
import { toPercentage } from '~/utils/toPercentage';
import { useSortSignalrIndustryDataStore, } from '~/modules/symbolQuote/simple/useSortStore';
import { SortArrow } from '../content/SortArrow';
// 關於data需要自定義一個組合
/**
 * 商品:symbol 報價:quote value 過去5日的漲跌幅: changeWeek
 *
 * 先取一大群的quote 自定義新的data格式
 *
 * { quotValue: value as Signalr.ValueOfOHLC, weekChangePercent: 5 as number }
 *
 * 再傳到元件中
 */
const IndustryBody = memo(function IndustryBody(props) {
    const quote = props.quote;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const value = useSnapshot(signalrStore2.values.quote)[quote.symbol];
    const close = value?.close ?? 0;
    const quoteChanges = processQuoteToReadable(value);
    const changePercent = quoteChanges.closeChangePercent;
    const lastDate = useSnapshot(staticStore).tradedDate.day0;
    const data = useGetPrice(lastDate, 5, 120, quote.symbol);
    const change5days = toPercentage(last(data)?.close ?? 0, first(data)?.close ?? 0, true);
    const changeSymbol = changePercent > 0 ? '+' : '';
    const arrow = (change) => {
        if (change > 0) {
            return <BiTrendingUp size={18}/>;
        }
        else if (change < 0) {
            return <BiTrendingDown size={18}/>;
        }
        else
            return <TbWaveSawTool size={18}/>;
    };
    const selected = useSnapshot(store.charting).symbol === quote.symbol;
    return (<styleds.body.container selected={selected} onClick={() => {
            store.charting.changeSymbol(quote.symbol);
        }}>
      <styleds.body.content.header>
        <styleds.body.hearder.title>
          <div>{dictionary[quote.symbol]}</div>
          <styleds.body.hearder.quote change={changePercent}>
            {/* {close.toFixed(2)}  */}
            {changeSymbol}
            {changePercent.toFixed(2)}%
          </styleds.body.hearder.quote>
        </styleds.body.hearder.title>
        <styleds.body.hearder.kbar>
          <Kbar quote={value}/>
        </styleds.body.hearder.kbar>
      </styleds.body.content.header>
      <styleds.body.content.body>
        {/* <styleds.body.chartTitle>
          近五日走勢圖
        </styleds.body.chartTitle> */}
        <styleds.body.body.chart>
          <SimpleTrendChart symbol={quote.symbol} interval={'hour'} timeCount={120} dataInterval={'1H'} fillColorUp={'#33aacc'} fillColorDn={'#33aacc'} refreshInterval={60000 * 15}/>
        </styleds.body.body.chart>
      </styleds.body.content.body>
      <styleds.body.content.bottom>
        <styleds.body.bottom.container change={change5days}>
          <styleds.body.bottom.title>5日漲跌幅</styleds.body.bottom.title>
          <span>{change5days.toFixed(2)}%</span>
          <styleds.body.bottom.arrow>{arrow(change5days)}</styleds.body.bottom.arrow>
        </styleds.body.bottom.container>
      </styleds.body.content.bottom>
    </styleds.body.container>);
});
const IndustryHeader = memo(function IndustryHeader() {
    const sortState = useSnapshot(useSortSignalrIndustryDataStore);
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const sort = sortState.sortKey;
    const getArrow = useCallback((sortKey) => {
        return sortKey === sortState.sortKey ? (toggleOrderKey === 'desc' ? '▲' : '▼') : undefined;
    }, [sortState.sortKey, toggleOrderKey]);
    const handleColumnSelected = useCallback((sortKey) => {
        useSortSignalrIndustryDataStore.sortKey = sortKey;
        useSortSignalrIndustryDataStore.orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<styleds.header.container>
      <styleds.header.item onClick={() => handleColumnSelected('changePrecent')} selectd={false}>
        K棒
      </styleds.header.item>
      <styleds.header.item onClick={() => handleColumnSelected('symbol')} selectd={sort === 'symbol'}>
        商品
        <SortArrow sortKey='symbol' sotre={useSortSignalrIndustryDataStore}/>
      </styleds.header.item>
      <styleds.header.item onClick={() => handleColumnSelected('changePrecent')} selectd={sort === 'changePrecent'}>
        漲跌幅
        <SortArrow sortKey='changePrecent' sotre={useSortSignalrIndustryDataStore}/>
      </styleds.header.item>
      {/* <styleds.header.item selectd={false}>五日漲跌幅</styleds.header.item> */}
      <styleds.header.item onClick={() => handleColumnSelected('amount')} selectd={sort === 'amount'}>
        成交金額
        <SortArrow sortKey='amount' sotre={useSortSignalrIndustryDataStore}/>
      </styleds.header.item>
      {/* <styleds.header.item
          onClick={() => (edwin_store.displayType = 'list')}
          selectd={false}
        >
          {<RiListUnordered size={16} />}
        </styleds.header.item> */}
    </styleds.header.container>);
});
export default {
    body: IndustryBody,
    header: IndustryHeader,
};
const styleds = {
    header: {
        container: styled.div `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 14% 18% 18% 24% 20% 6%;
      height: 36px;
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
      background-color: #151820;
      border-radius: 4px;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      font-size: 12px;
      background-color: ${props => (props.selectd ? '#151820' : 'transparent')};
    `,
    },
    body: {
        container: styled.div `
      ${fill_vertical_cross_center};
      display: grid;
      grid-template-rows: 38% 38% 24%;
      height: 116px;
      min-width: 132px;
      max-width: 49%;
      border-radius: 12px;
      background-color: ${props => (props.selected === true ? '#151820' : '#151820')};
      border: 1px solid ${props => (props.selected === true ? '#787c79' : '#28292a')};
      font-size: 13px;
      cursor: pointer;
      transition: 0.5s;
      &:hover {
        transform: scale(1.04);
        transition: 0.5s;
        border: 1px solid ${props => (props.selected === true ? '#898989' : '#565656')};
      }
    `,
        content: {
            header: styled.div `
        ${fill_horizontal_cross_center};
        border-radius: 12px 12px 0 0;
        border-bottom: 1px solid #454545;
      `,
            body: styled.div `
        ${fill_horizontal_all_center};
        position: relative;
        padding: 4px;
      `,
            bottom: styled.div `
        ${fill_horizontal_all_center};
      `,
        },
        hearder: {
            title: styled.div `
        ${fill_vertical_all_center};
        width: 70%;
        align-items: start;
        padding-left: 8px;
      `,
            kbar: styled.div `
        ${fill_horizontal_all_center};
        width: 30%;
      `,
            quote: styled.div `
        color: ${props => props.change > 0 ? '#ff2a4e' : props.change < 0 ? '#3dff79' : '#acacac'};
      `,
        },
        body: {
            chartTitle: styled.div `
        font-size: 12px;
        position: absolute;
        z-index: 2;
        top: -2px;
        left: -6px;
        transform: scale(0.7);
        padding: 0 !important;
        color: #cacaca;
      `,
            chart: styled.div `
        width: 100%;
        height: 100%;
        padding: 8px 0 0 0;
      `,
        },
        bottom: {
            container: styled.div `
        ${fill_horizontal_all_center};
        border-radius: 0 0 12px 12px;
        background-color: ${props => props.change > 0 ? '#231918' : props.change < 0 ? '#20261b' : '#2d2d2d'};
        color: ${props => props.change > 0 ? '#ff2a4e' : props.change < 0 ? '#3dff79' : '#acacac'};
        padding: 0 4px;
        font-weight: 400;
        gap: 4px;
      `,
            title: styled.div `
        font-size: 12px;
        transform: scale(0.9);
        color: #fff !important;
      `,
            arrow: styled.div `
        margin-top: 6px;
      `,
        },
    },
};

import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { SimpleQuoteListInteractWithChart } from '~/modules/symbolQuote/simple';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { store } from '~/pages/heineken_template/_private/store';
import styled from '@emotion/styled';
import { flex, fill } from '~/modules/AppLayout/FlexGridCss';
import { useVirtualAccountOrderPre, useVirtualAccountHolding, } from '~/modules/virtualExchange/useVirtualExchangeResource';
import { useRealTimeResource } from '~/modules/screener/containers/useStockScreenerResource';
export const TodaySignal = memo(function TodaySignal() {
    /** 即時訊號 */
    //最近的一天有交易資料的日期
    const baseDate = useSnapshot(staticStore).tradedDate.day0.format('YYYY-MM-DD');
    const intraday = useSnapshot(staticStore).tradedDate.intraday.format('YYYY-MM-DD');
    // 即時選股資料，今天符合條件，明天進場，明天盤後變成持有部位（已篩選多：5日均量>5000張，空：5日均量>500張）
    const realTimeLongSymbol = useRealTimeResource({ agent: 'sungop', date: intraday, bs: 'b' }).data ?? [];
    const realTimeShortSymbol = useRealTimeResource({ agent: 'sungop', date: intraday, bs: 's' }).data ?? [];
    // 已持有部位
    const longStockHolding = useVirtualAccountHolding('sungop_stock_long');
    const shortStockHolding = useVirtualAccountHolding('sungop_stock_short');
    // 今天進場的部位
    const longOrderPreStock = useVirtualAccountOrderPre('sungop_stock_long', 'B', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'B')
        .map(datum => datum.symbol);
    const shortOrderPreStock = useVirtualAccountOrderPre('sungop_stock_short', 'S', {
        dateTime: baseDate,
    })
        ?.filter(datum => datum.bs === 'S')
        .map(datum => datum.symbol);
    const longStock = realTimeLongSymbol.reduce((acc, cur) => {
        if (longStockHolding?.some(item => item.symbol === cur)) {
            return acc;
        }
        if (longOrderPreStock?.some(item => item === cur)) {
            return acc;
        }
        acc.push(cur);
        return acc;
    }, []);
    const shortStock = realTimeShortSymbol.reduce((acc, cur) => {
        if (shortStockHolding?.some(item => item.symbol === cur)) {
            return acc;
        }
        if (shortOrderPreStock?.some(item => item === cur)) {
            return acc;
        }
        acc.push(cur);
        return acc;
    }, []);
    const longLength = longStock?.length;
    const shortLength = shortStock?.length;
    return (<div css={fill}>
      <styleds.SymbolLsitTitle fill='#aa3333'>波浪向上 數量:{longLength}</styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={longStock ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
      <styleds.SymbolLsitTitle fill='#33aa33'>波浪向下 數量:{shortLength}</styleds.SymbolLsitTitle>
      <styleds.SymbolLsitContent>
        <SimpleQuoteListInteractWithChart data={shortStock ?? []} chart={store.charting}/>
      </styleds.SymbolLsitContent>
    </div>);
});
const styleds = {
    SymbolLsitTitle: styled.div `
    ${flex.h.allCenter}
    height: 28px;
    width: 100%;
    background-color: ${options => options.fill};
    color: #eeeeee;
    border-radius: 5px 5px 0px 0px;
  `,
    SymbolLsitContent: styled.div `
    width: 100%;
    height: calc(50% - 30px);
  `,
    symbolMark: styled.div `
    width: 10px;
    height: 10px;
    background-color: ${options => options.fill};
    border-radius: 50%;
  `,
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { freemanStore } from '../freemanStore';
import { EstimatedVolumeChart, estimatedVolumeStore } from './EstimatedVolume';
export const EstimatedVolumeContent = memo(function EstimateVolumeContent() {
    const state = useSnapshot(freemanStore);
    const estimatedValue = useSnapshot(estimatedVolumeStore).value;
    const estimatedPrefValue = useSnapshot(estimatedVolumeStore).prefValue;
    const estimatedVolume = estimatedValue / 1e8;
    const estimatedPrefVolume = estimatedPrefValue / 1e8;
    return (<classes.container>
      <classes.header>
        <div css={css `
            color: #ff4499;
          `}>
          <span>加權指數預估量: </span>
          <span>{estimatedVolume.toFixed(2)}億</span>
        </div>

        <div css={css `
            color: #5f81f5;
          `}>
          <span>昨日: </span>
          <span>{estimatedPrefVolume.toFixed(2)}億</span>
        </div>

        <div>
          <span>差值: </span>
          <span>{(estimatedVolume - estimatedPrefVolume).toFixed(2)}</span>
        </div>
      </classes.header>
      <div css={css `
          position: relative;
          width: 100%;
          height: 256px;
        `}>
        <EstimatedVolumeChart />
      </div>
    </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-rows: 30px auto;
    width: 99%;
    height: 280px;
    font-size: 13px;
    font-weight: 600;
    padding: 4px;
  `,
    header: styled.div `
    display: flex;
    gap: 16px;
  `,
};

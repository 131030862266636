import { store } from '~/pages/heineken_template/_private/store';
import { yaya168_main_power } from '~/trades/indicators/yaya168/yaya168_main_power';
import { yaya168_volume } from '~/trades/indicators/yaya168/yaya168_volume';
import { yaya168_shortTermTrading } from '~/trades/indicators/yaya168/yaya168_shortTermTrading';
import { wu5868_bband } from '~/trades/indicators/wu5868/wu5868_bband';
export const yaya168Stock_strategiesGroup = {
    /** 主要策略集 */
    main: [
        {
            displayName: '順勢波段',
            indicators: [wu5868_bband, yaya168_shortTermTrading, yaya168_main_power, yaya168_volume],
            symbol: 'TSEA',
            interval: 'D',
            panesRatio: 70,
        },
    ],
};
export const yaya168Stock_initStrategies = () => {
    store.charting.indicatorsPreloaded = [
        ...store.charting.indicatorsPreloaded,
        ...[wu5868_bband, yaya168_shortTermTrading, yaya168_main_power, yaya168_volume],
    ];
    store.charting.initStrategy({
        configs: [...yaya168Stock_strategiesGroup.main],
    });
};

import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_tc1688_qqTony = createIndicator({
    id: 'daddy960-tc1688-2-2',
    displayName: 'tc1688_2-2',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const sf = 5; //this._input(0)
            const rsiLength = 14;
            const wildersPeriod = rsiLength * 2 - 1;
            const DAR_FACTOR = 4.236;
            const close = this.ohlc.closeArray;
            const rsi = context.new_var();
            const rsiMa = context.new_var();
            const atrRsi = context.new_var();
            const maAtrRsi = context.new_var();
            const trLevelSlow = context.new_var();
            close.get(100);
            rsi.get(100);
            rsiMa.get(100);
            atrRsi.get(100);
            maAtrRsi.get(100);
            trLevelSlow.get(100);
            const rsiValue = this.rsi(close, rsiLength);
            rsi.set(rsiValue);
            const rsiMaValue = this.exponentialAverage(rsi, sf);
            rsiMa.set(rsiMaValue);
            const atrRsiValue = Math.abs(rsiMa.get(0) - rsiMa.get(1));
            atrRsi.set(atrRsiValue);
            const maAtrRsiValue = this.exponentialAverage(atrRsi, wildersPeriod);
            maAtrRsi.set(maAtrRsiValue);
            let tr = isNaN(trLevelSlow.get(0)) ? 0 : trLevelSlow.get(0);
            let rsi1 = this.exponentialAverage(rsi, sf);
            const rsi0 = this.exponentialAverage(rsi, sf);
            const dar = this.exponentialAverage(maAtrRsi, wildersPeriod) * DAR_FACTOR;
            const dv = tr;
            if (rsi0 < tr) {
                tr = rsi0 + dar;
                if (rsi1 < dv)
                    if (tr > dv)
                        tr = dv;
            }
            else if (rsi0 > tr) {
                tr = rsi0 - dar;
                if (rsi1 > dv)
                    if (tr < dv)
                        tr = dv;
            }
            trLevelSlow.set(tr);
            rsi1 = rsi0;
            return [rsiMa.get(0), trLevelSlow.get(0), 50];
        },
    },
    metainfo: {
        is_price_study: false,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [
        // {
        //   id: 'sf',
        //   name: 'SF',
        //   type: 'integer',
        //   defval: 5,
        //   min: 1,
        //   max: 500,
        //   step: 1,
        // },
        ],
        plots: [
            { id: 'rsiMa', type: 'line' },
            { id: 'trLevelSlow', type: 'line' },
            { id: 'zero', type: 'line' },
        ],
        styles: {
            rsiMa: {
                title: 'RsiMa',
                histogramBase: 0,
                joinPoints: false,
            },
            trLevelSlow: {
                title: 'TrLevelSlow',
                histogramBase: 0,
                joinPoints: false,
            },
        },
        defaults: {
            styles: {
                rsiMa: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#1E90FF',
                },
                trLevelSlow: {
                    linestyle: 1,
                    linewidth: 2,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#Ffff00',
                },
                zero: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: false,
                    transparency: 30,
                    visible: true,
                    color: '#cccccc',
                },
            },
            precision: 2,
            inputs: {
            // sf: 5,
            },
        },
    },
});

import { filter } from 'lodash';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { useMarketPriceResource } from '~/modules/options/api/useMarketPriceResource';
import { useOptionsGreeksResource } from '~/modules/options/api/useOptionsGreeksResource';
import { filterSocket } from '~/modules/options/utils/filterSocketUtil';
import { useParseOptionsContractMonthString } from '~/modules/options/utils/useParseOptionsContractMonthString';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import dayAPI from '~/utils/dayAPI';
import { getOptionIntradayEndDate } from './getOptionContractDateTime';
import { optionAnalyzeStore } from './optionAnalyzeStore';
import { getWeeklyMxfContract } from './useGetOptionContract';
import { useOptionReferencePrice } from './useOptionReferencePrice';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
//to do:需要給出fake data 以免日後api有問題前端爆掉
/** 距離結算剩餘天數 */
const getRemainingDays = (startDate, endDate) => {
    const start = dayAPI(startDate);
    const end = dayAPI(endDate);
    // 定義一個工作日的判斷函數
    function isTradeDay(date) {
        //如果是周六或周日，返回 false
        return date.day() !== 0 && date.day() !== 6;
    }
    // 計算日期區間的天數
    const days = end.diff(start, 'day') + 1;
    // 計算工作日數
    let tradeDays = 0;
    for (let i = 0; i < days; i++) {
        const date = start.add(i, 'day');
        if (isTradeDay(date)) {
            tradeDays++;
        }
    }
    return tradeDays;
};
/** 從選擇權資料找出價平履約價 */
const getClosestStrikePrice = (data) => parseFloat(data.reduce((closest, entry) => {
    const diff = Math.abs(entry.pxp - entry.pxc);
    return diff < closest.minDiff ? { strikePrice: entry.strikePrice, minDiff: diff } : closest;
}, { strikePrice: '0', minDiff: Infinity }).strikePrice);
export const useOptionQuote = () => {
    const intraday = useSnapshot(staticStore).tradedDate.intraday;
    /** store */
    const state = useSnapshot(optionAnalyzeStore);
    /** 預設的選擇權契約代號 */
    const contract = state.currentContract;
    /** 交易量最大的合約結算日 */
    const settlementDate = state.currentSettlementDate;
    /** 剩餘結算天數 結算當天會是 (目前無法處理國定假日,因為沒有提供未來放假日期的api,先假設工作日都有交易~) */
    const remainingDays = getRemainingDays(dayAPI(), dayAPI(settlementDate));
    /** 拿選擇權 波動率、delta值 */
    const getGreeks = useOptionsGreeksResource({
        contract,
    }).res.data ?? [
        {
            symbol: '0',
            delta: 0,
            iv: 0,
        },
    ];
    /** 拿'所有'選擇權指定時間的報價 */
    const getQuote = useMarketPriceResource(contract, dayAPI(getOptionIntradayEndDate(intraday)));
    /** Signalr */
    const signalr = signalrHooks2.useQuotes(getQuote?.map(s => s.symbol));
    useEffect(() => {
        signalrStore2.addQuote([...(getQuote?.map(s => s.symbol) || [])]);
        return () => {
            signalrStore2.removeQuote([...(getQuote?.map(s => s.symbol) || [])]);
        };
    }, [JSON.stringify(getQuote?.map(s => s.symbol))]);
    /** 拿'所有'選擇權'即時'報價 */
    const quoteSource = Object.values(signalr); //getQuote(回朔模式)
    /** 目前合約 給出type 周別 月別 (用於filterSocket過濾買賣權函式) */
    const contractObject = useParseOptionsContractMonthString(contract);
    /** 將已取得報價過濾出`買權` */
    const qouteCallSource = filter(quoteSource, datum => filterSocket(datum, 'CALL', contractObject));
    /** 將已取得報價過濾出`賣權` */
    const quotePutSource = filter(quoteSource, datum => filterSocket(datum, 'PUT', contractObject));
    /** 將波動率報價過濾出`買權` */
    const ivCallSource = filter(getGreeks, datum => filterSocket(datum, 'CALL', contractObject));
    /** 將波動率報價過濾出`賣權` */
    const ivPutSource = filter(getGreeks, datum => filterSocket(datum, 'PUT', contractObject));
    /** callSource合併ivCallSource 獨立的選擇權客製化報價格式 */
    const dataCall = ivCallSource
        ?.reduce((acc, ivObj) => {
        const matchingDefaultObj = qouteCallSource.find(defaultObj => defaultObj?.symbol === ivObj.symbol);
        if (matchingDefaultObj) {
            const newObj = {
                pxc: matchingDefaultObj.close,
                volc: matchingDefaultObj.volume,
                prevRefc: matchingDefaultObj.prevRef,
                ivc: ivObj.iv * 100,
                deltac: ivObj.delta,
                strikePrice: matchingDefaultObj.symbol.slice(3, -2) ?? '',
                dateTimec: matchingDefaultObj.datetime,
            };
            return [...acc, newObj];
        }
        return acc;
    }, [])
        .sort((a, b) => Number(a.strikePrice) - Number(b.strikePrice));
    /** putSource合併ivPutSource 獨立的選擇權客製化報價格式 */
    const dataPut = ivPutSource
        ?.reduce((acc, ivObj) => {
        const matchingDefaultObj = quotePutSource?.find(defaultObj => defaultObj?.symbol === ivObj.symbol);
        if (matchingDefaultObj) {
            const newObj = {
                pxp: matchingDefaultObj.close,
                volp: matchingDefaultObj.volume,
                prevRefp: matchingDefaultObj.prevRef,
                ivp: ivObj.iv * 100,
                deltap: ivObj.delta,
                strikePrice: matchingDefaultObj.symbol.slice(3, -2) ?? '',
                dateTimep: matchingDefaultObj.datetime,
            };
            return [...acc, newObj];
        }
        return acc;
    }, [])
        .sort((a, b) => Number(a.strikePrice) - Number(b.strikePrice));
    /** 獨立的選擇權客製化報價 */
    const mergedData = dataCall?.reduce((acc, callObj) => {
        const matchingPut = dataPut?.find(putObj => putObj.strikePrice === callObj.strikePrice);
        if (matchingPut) {
            acc.push({ ...callObj, ...matchingPut });
        }
        return acc;
    }, []) ?? [];
    /** 所有履約價 */
    const allStrikePrice = quoteSource.length
        ? quoteSource.map(s => Number(s?.symbol.slice(3, -2)))
        : [0];
    /** 標的價格 (週小台) */
    const mtx = getWeeklyMxfContract(state.currentContract);
    /** 使用周小台的bid ask算出基準價 */
    const currentClose = useOptionReferencePrice(mtx);
    /** 價平位置 */
    const atTheMoneyPrice = getClosestStrikePrice(mergedData);
    /** 價平和 */
    const atTheMoneySum = mergedData.filter(s => Number(s.strikePrice) === atTheMoneyPrice).map(s => s.pxc + s.pxp)[0] ||
        0;
    /** 找到mergedData價平的兩邊成交價 */
    const findAtTheMoneyPrice = mergedData?.filter(s => Number(Number(s?.strikePrice) === atTheMoneyPrice));
    /** 合成期貨價格 (價平 + (call - put)) */
    const syntheticFutures = atTheMoneyPrice + ((findAtTheMoneyPrice[0]?.pxc ?? 0) - (findAtTheMoneyPrice[0]?.pxp ?? 0));
    //store相關----
    /** 價平 */
    optionAnalyzeStore.atTheMoneyPrice = atTheMoneyPrice;
    /** 價平和 */
    optionAnalyzeStore.atTheMoneySum = atTheMoneySum;
    /** 所選契約結算日 */
    optionAnalyzeStore.currentSettlementDate = settlementDate;
    /** 剩餘天數 */
    optionAnalyzeStore.remainingDays = remainingDays;
    /** 合成期貨 */
    optionAnalyzeStore.syntheticFutures = syntheticFutures;
    return { quoteData: mergedData, atTheMoneyPrice: atTheMoneyPrice ?? 0 };
};

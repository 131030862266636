import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
export const ContractKbar = memo(function ContractKbar(props) {
    const kbarWidth = 16;
    /** Signalr */
    const value = props.data;
    const amp = value.high - value.low;
    const viewRange = amp < value.maxRange ? value.averRange : value.maxRange;
    const cost = (value.high + value.low) / 2;
    const close = value?.close ?? 0;
    const open = value?.open ?? 0;
    const high = value?.high ?? 0;
    const low = value?.low ?? 0;
    const view = high - low;
    /** 頂部範圍 暫時給 最低+歷史最大震幅 */
    const limitUp = view < 1000 ? cost + viewRange / 2 : high;
    /** 頂部範圍 暫時給 最高-歷史最大震幅 */
    const limitDn = view < 1000 ? cost - viewRange / 2 : low;
    /** 最高價離實體K上緣的距離 */
    const base1 = close > open ? close : open;
    /** 最低價離實體K上緣的距離 */
    const base2 = close <= open ? close : open;
    /** K棒最大高度;漲停倒跌的距離 */
    const range = limitUp - limitDn;
    /** 實體K高度 */
    const bar = Math.abs(close - open);
    /** 實體K顏色 */
    const barColor = close > open ? '#ff3232' : close < open ? '#0dc316' : '#ffff22';
    //K棒數值
    const topAreaValue = (limitUp - high) / range;
    const upBorderValue = (high - base1) / range;
    const barValue = bar / range === 0 ? 0.01 : bar / range;
    const dnBorderValue = (base2 - low) / range;
    const bottomValue = (low - limitDn) / range;
    //K棒數值 css使用
    const kbarTopArea = (topAreaValue * 100).toFixed(3);
    const kbarUpBorder = (upBorderValue * 100).toFixed(3);
    const kbar = (barValue * 100).toFixed(3);
    const kbarDnBorder = (dnBorderValue * 100).toFixed(3);
    const kbarBottomArea = (bottomValue * 100).toFixed(3);
    return (<div css={css `
          ${classes.container};
          width: ${kbarWidth * 1.2}px;
        `}>
        <div css={css `
            ${classes.range}
            grid-template-rows: ${kbarTopArea}% ${kbarUpBorder}% ${kbar}% ${kbarDnBorder}% ${kbarBottomArea}%;
          `}>
          <div css={classes.kbar.empty}/>
          <div css={classes.kbar.border}/>
          <div css={css `
              ${classes.kbar.bar};
              background-color: ${barColor};
            `}/>
          <div css={classes.kbar.border}/>
          <div css={classes.kbar.empty}/>
        </div>
      </div>);
});
const classes = {
    container: css `
    ${fill_vertical_all_center};
    height: 100%;
    flex-shrink: 0;
    & > .mantine-g0lxdh {
      background-color: #111111ee;
      border: 1px solid #787878;
      padding: 0;
    }
  `,
    range: css `
    width: 100%;
    height: 83.3%;
    display: grid;
  `,
    kbar: {
        empty: css `
      width: 1.4px;
      height: 100%;
    `,
        border: css `
      width: 3px;
      height: 100%;
      background-color: #737375;
      margin: 0 auto;
    `,
        bar: css `
      width: 100%;
      height: 100%;
      border-radius: 0.4px;
      margin: 0 auto;
    `,
    },
};

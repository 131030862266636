import { css } from '@emotion/react';
import { memo, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '../heineken_template/_private/store';
import { jerry597_store } from './jerry597_store';
import { useThemeStore } from '~/components/theme/useThemeStore';
import styled from '@emotion/styled';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { jerry597_strategyGroup } from './jerry597_initStrategies';
import { MdAddchart } from 'react-icons/md';
import { RiExchangeFundsFill } from 'react-icons/ri';
const mainSymbol = ['TSEA', 'TX-1', 'OTCA'];
export const Jerry597_Row = memo(function Jerry597_Row() {
    const theme = useThemeStore(state => state.theme);
    const state = useSnapshot(jerry597_store);
    const charting = useSnapshot(store.charting);
    useEffect(() => {
        signalrStore2.addQuote(mainSymbol);
        return () => {
            signalrStore2.removeQuote(mainSymbol);
        };
    }, [JSON.stringify(mainSymbol)]);
    return (<classes.container className={theme}>
      <classes.group>
        <classes.strategy.title className={theme}>
          <RiExchangeFundsFill size={'30px'} fill={'#ff5500'}/>
          策略選擇
        </classes.strategy.title>
        <classes.positionButton.body className={theme} css={css `
            &.dark {
              background-color: ${state.strategy === 'long' ? '#bd3b1e' : '#5d0e07'};
              color: ${state.strategy === 'long' ? '#ffffff' : '#aaaaaa'};
              &:hover {
                border: 1px solid #eeeeee;
              }
            }
            &.light {
              background-color: ${state.strategy === 'long' ? '#bd3b1e' : '#5d0e07'};
              color: ${state.strategy === 'long' ? '#ffffff' : '#787878'};
              &:hover {
                border: 1px solid #252525;
              }
            }
          `} onClick={() => {
            jerry597_store.strategy = 'long';
            store.charting.updateFromState();
        }}>
          多方策略
        </classes.positionButton.body>
        <classes.positionButton.body className={theme} css={css `
            &.dark {
              background-color: ${state.strategy === 'default' ? '#898989' : '#454545'};
              color: ${state.strategy === 'default' ? '#ffffff' : '#aaaaaa'};
              &:hover {
                border: 1px solid #eeeeee;
              }
            }
            &.light {
              background-color: ${state.strategy === 'default' ? '#6e87da' : '#cecece'};
              color: ${state.strategy === 'default' ? '#ffffff' : '#787878'};
              &:hover {
                border: 1px solid #252525;
              }
            }
          `} onClick={() => {
            jerry597_store.strategy = 'default';
            store.charting.updateFromState();
        }}>
          多空策略
        </classes.positionButton.body>
        <classes.positionButton.body className={theme} css={css `
            &.dark {
              background-color: ${state.strategy === 'short' ? '#00aa00' : '#225413'};
              color: ${state.strategy === 'short' ? '#ffffff' : '#aaaaaa'};
              &:hover {
                border: 1px solid #eeeeee;
              }
            }
            &.light {
              background-color: ${state.strategy === 'short' ? '#008800' : '#225413'};
              color: ${state.strategy === 'short' ? '#ffffff' : '#787878'};
              &:hover {
                border: 1px solid #252525;
              }
            }
          `} onClick={() => {
            jerry597_store.strategy = 'short';
            store.charting.updateFromState();
        }}>
          空方策略
        </classes.positionButton.body>
      </classes.group>

      <classes.group>
        <classes.strategy.title className={theme}>
          <MdAddchart size={'30px'} fill={'#ff5500'}/>
          金旺指標
        </classes.strategy.title>
        {jerry597_strategyGroup.future.map(strategyConfig => {
            const isActive = charting.strategySelected.displayName === strategyConfig.displayName;
            return (<classes.strategyButton.body className={theme} key={strategyConfig.displayName} active={isActive} onClick={event => {
                    store.charting.setStrategy(strategyConfig);
                    store.charting.changeSymbol(strategyConfig.symbol ?? '');
                    store.charting.updateFromStrategyState();
                }}>
              {strategyConfig.displayName}
            </classes.strategyButton.body>);
        })}
      </classes.group>
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_horizontal_cross_center};
    gap: 4px;
    &.dark {
      background-color: #232222;
    }
    &.light {
      background-color: #f7f7f7;
    }
  `,
    strategyButton: {
        body: styled.div `
      ${fill_horizontal_all_center};
      width: auto;
      height: 30px;
      border-radius: 2px;
      font-size: 14px;
      cursor: pointer;
      border: 1px solid #00000000;
      &:hover {
        border: 1px solid #cccccc;
        transition: 0.5s;
      }
      &.dark {
        background-color: ${props => (props.active ? '#bd3b1e' : '#454545')};
        color: ${props => (props.active ? '#ffffff' : '#aaaaaa')};
        &:hover {
          border: 1px solid #eeeeee;
        }
      }
      &.light {
        background-color: ${props => (props.active ? '#bd3b1e' : '#cecece')};
        color: ${props => (props.active ? '#ffffff' : '#787878')};
        &:hover {
          border: 1px solid #252525;
        }
      }
    `,
    },
    positionButton: {
        body: styled.div `
      ${fill_horizontal_all_center};
      width: auto;
      height: 30px;
      border-radius: 2px;
      font-size: 14px;
      cursor: pointer;
      border: 1px solid #00000000;
      &:hover {
        border: 1px solid #cccccc;
        transition: 0.5s;
      }
    `,
    },
    group: styled.div `
    display: grid;
    grid-template-rows: 50% 50%;
    grid-template-columns: 33% 33% 33%;
    width: 100%;
    gap: 2px;
  `,
    strategy: {
        container: styled.div `
      ${fill_vertical_cross_center};
      height: 72px;
      border-radius: 4px;
      padding: 4px;
      gap: 2px;
      &.dark {
        background-color: #424242;
        color: #ffffff;
      }
      &.light {
        background-color: #ececec;
        color: #252525;
      }
    `,
        title: styled.div `
      ${fill_horizontal_all_center};
      grid-column: 1/4;
      height: 30px;
      color: #ffffff;
      border-radius: 2px;
      font-size: 18px;
      gap: 8px;
      font-weight: 600;
      &.dark {
        background-color: #333333;
        color: #ffffff;
      }
      &.light {
        background-color: #dadada;
        color: #2a2a2a;
      }
    `,
    },
};
const buttonLongColor = css `
  &.dark {
    background-color: #ff0000;
  }
  &.light {
    background-color: #990000;
  }
`;
const buttonShortColor = css `
  &.dark {
    background-color: #00cc00;
  }
  &.light {
    background-color: #008800;
  }
`;
const buttonDefaultrColor = css `
  &.dark {
    background-color: #343434;
  }
  &.light {
    background-color: #989898;
  }
`;
const buttonStrategyColor = css `
  &.dark {
    background-color: #af452b;
  }
  &.light {
    background-color: #af452b;
  }
`;

import { css } from '@emotion/react';
import { memo, useState, useEffect } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_horizontal_cross_center, flex, jc, } from '~/modules/AppLayout/FlexGridCss';
import { UserAvatarAsDialogButton } from '~/modules/auth/components/UserAvatarAsDialogButton';
import { ChartingServerSwitch } from '~/modules/SDK/chart4/components/ChartingServerSwitch';
import { AppLink } from '~/components/AppLink';
import styled from '@emotion/styled';
import { fontWeight600 } from '~/css/font';
import { BiLinkExternal } from 'react-icons/bi';
import { Preset_Topbar } from '~/pages/heineken_template/_preset/preset_Topbar';
import { store } from '~/pages/heineken_template/_private/store';
import { UndevelopedView } from '~/pages/goodpsy/component/UndevelopedView';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { useSnapshot } from 'valtio';
import { dayTradeIndicators } from '~/pages/lung88988_stock/_private/lung88988Stock_initStrategies';
export const Lung88988Stock_Topbar = memo(function Lung88988Stock_Topbar() {
    const { isPc, isPhone } = useMedia();
    const me = useSnapshot(fr_me);
    const [moblieUser, setMoblieUser] = useState(false);
    useEffect(() => {
        const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'lung889881688' && s.productName === 'web_stock_moblie');
        if (tutorialProduct && !tutorialProduct.isExpired) {
            // 如果是手機專用版使用者就顯示當沖策略
            setMoblieUser(true);
            store.charting.setStrategy({
                displayName: '當沖策略',
                indicators: dayTradeIndicators,
                symbol: 'TSEA',
                interval: '5',
            });
            store.charting.updateFromStrategyState();
        }
        else {
            setMoblieUser(false);
        }
    }, [me]);
    return (<div>
      {/* 手機專用版設定 */}
      {/* 非手機看不到 */}
      {!isPhone && moblieUser && <UndevelopedView />}
      {/* 手機專用版顯示特定版面 */}
      {isPhone && moblieUser && (<styleds.TopbarPhone>
          <Preset_Topbar 
        // showLeftBurger
        componentsInRight={<div css={fill_horizontal_cross_center}>
                <ChartingServerSwitch charting={store.charting} css={css `
                    width: 108px;
                  `}/>
                <UserAvatarAsDialogButton />
              </div>}/>
        </styleds.TopbarPhone>)}

      {!isPhone && (<styleds.Topbar>
          <styleds.TopbarTitle>
            <styleds.TopbarLogo src='lung88988/favicon1.png'/>
            {isPc && <span>動能操盤系統-股票版</span>}
          </styleds.TopbarTitle>

          <styleds.TopbarComponents>
            {/* <PocketBrokerEventLink /> */}
            {/* <PocketBrokerOpenAccountLogo /> */}
            <Lung88988VersionSwitch url='https://lung889881688.moneycom.in/'>
              動能操盤系統-期貨版
            </Lung88988VersionSwitch>
            <ChartingServerSwitch charting={store.charting}/>
            <UserAvatarAsDialogButton />
          </styleds.TopbarComponents>
        </styleds.Topbar>)}

      {!isPc && (<styleds.TopbarPhone>
          <Preset_Topbar showLeftBurger showRightBurger componentsInRight={<div css={fill_horizontal_cross_center}>
                {/* <PocketBrokerOpenAccountLogo /> */}
                {/* <Lung88988VersionSwitch url='https://lungfutures.moneycom.in/'>
                  期貨版
                </Lung88988VersionSwitch> */}
                <ChartingServerSwitch charting={store.charting} css={css `
                    width: 108px;
                  `}/>
                <UserAvatarAsDialogButton />
              </div>}/>
        </styleds.TopbarPhone>)}
    </div>);
});
export const Lung88988VersionSwitch = memo(function Lung88988VersionSwitch(props) {
    return (<styleds.Container>
        <BiLinkExternal />
        <styleds.Link>
          <AppLink href={props.url} target='_blank'>
            {props.children}
          </AppLink>
        </styleds.Link>
      </styleds.Container>);
});
const styleds = {
    Container: styled.div `
    ${flex.h.allCenter};
    height: 40px;
    background-color: #2a2a2a;
    border-radius: 5px;
    padding: 0px 8px;
    border: 1px solid #555555;
    gap: 8px;
    color: #eeeeee !important;
    white-space: nowrap;
    &:hover {
      background-color: #2f2f2f;
    }
  `,
    Link: styled.div `
    * {
      ${fill_horizontal_all_center};
      color: #eeeeee !important;
    }
  `,
    Topbar: styled.div `
    ${fill_horizontal_all_center};
    ${jc.spaceBetween};
    background-color: #202020;
    border-bottom: 1px solid #777777;
    padding: 0px 8px;
  `,
    TopbarPhone: styled.div `
    ${fill_horizontal_cross_center};
    background-color: #202020;
    border-bottom: 1px solid #777777;
  `,
    TopbarTitle: styled.div `
    ${flex.h.allCenter}
    font-size: 22px;
    background: linear-gradient(170deg, #eeeeee, #bbbbbb);
    background-clip: text;
    color: transparent;
    ${fontWeight600}
  `,
    TopbarLogo: styled.img `
    max-width: 40px;
    max-height: 100%;
  `,
    TopbarComponents: styled.div `
    ${flex.h.allCenter}
    gap:4px;
  `,
};

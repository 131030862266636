import styled from '@emotion/styled';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
export const styleds = {
    SideBar: styled.div `
    ${fill_vertical_cross_center};
    ${scrollbar2Css};
    gap: 8px;
    padding: 4px 8px;
    background: linear-gradient(72deg, #1d1d1dee, #121212ee);
  `,
    SideBar2: styled.div `
    ${fill_vertical_cross_center};
    gap: 8px;
    padding: 4px 8px;
    background: linear-gradient(72deg, #111111ee, #251111ee);
  `,
    Board: styled.div `
    ${fill_vertical_cross_center};
    min-height: 132px;
    max-height: 132px;
    width: 100%;
    background: linear-gradient(32deg, #34374099, #32333699);
    border-radius: 5px;
    padding: 4px;
    font-size: 14px;
  `,
    BoardTitle: styled.div `
    ${fill_horizontal_all_center};
    width: 80%;
    height: 24px;
    background: linear-gradient(39deg, #4d1e18, #8c2417, #4d1e18);
    border-radius: 5px;
  `,
    TrendChartBoard: styled.div `
    max-height: 156px;
    min-height: 156px;
    width: 100%;
    border-radius: 5px;
    background: linear-gradient(32deg, #34374099, #32333699);
  `,
    ButtonContent: styled.div `
    ${fill_horizontal_all_center};
    height: 32px;
    gap: 8px;
  `,
    SymbolListContent: styled.div `
    ${fill_vertical_cross_center}
    height:calc(50% - 48px);
    background-color: #1f212577;
    border-radius: 5px;
    padding: 4px;
  `,
};

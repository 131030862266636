import { sortBy } from 'lodash';
import { useSnapshot } from 'valtio';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useSortSignalrIndustryDataStore } from '~/modules/symbolQuote/simple/useSortStore';
export const useIndustryQuoteSort = (data) => {
    const sortSnap = useSnapshot(useSortSignalrIndustryDataStore);
    const sortKey = sortSnap.sortKey;
    const orderKey = sortSnap.orderKey;
    const value = signalrHooks2.useQuotes(data);
    //const newDate =
    const valueData = () => {
        if (sortKey === 'change')
            return sortBy(value, datum => (datum?.close ?? 0) - (datum?.prevRef ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === 'changePrecent')
            return sortBy(value, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01).map(datum => datum?.symbol);
        else if (sortKey === 'none')
            return value.map(datum => datum?.symbol);
        else if (sortKey === 'amount')
            return sortBy(value, datum => (datum?.close ?? 0) * (datum?.volume ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === sortKey)
            return sortBy(value, datum => datum?.[sortKey]).map(datum => datum?.symbol);
    };
    const resultData = orderKey === 'desc' ? valueData()?.reverse() : valueData();
    return resultData;
};

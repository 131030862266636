import { memo, useState, useEffect } from 'react';
import { useSnapshot } from 'valtio';
import { Lung88988Stock_SidePane_ForOnlyMobile } from '~/pages/lung88988_stock/_private/Component/lung88988Stock_SidePane_ForOnlyMobile';
import useMedia from '~/hooks/useMedia';
import { Lung88988Stock_SidePane_ForOthers } from '~/pages/lung88988_stock/_private/Component/lung88988Stock_SidePane_ForOthers';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
export const Lung88988Stock_Sidepane1 = memo(function Lung88988Stock_Sidepane1() {
    const { isPc, isPhone } = useMedia();
    const me = useSnapshot(fr_me);
    const [moblieUser, setMoblieUser] = useState(false);
    useEffect(() => {
        const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'lung889881688' && s.productName === 'web_stock_moblie');
        if (tutorialProduct && !tutorialProduct.isExpired) {
            setMoblieUser(true);
        }
        else {
            setMoblieUser(false);
        }
    }, [me]);
    if (isPhone && moblieUser) {
        return <Lung88988Stock_SidePane_ForOnlyMobile />;
    }
    return <Lung88988Stock_SidePane_ForOthers />;
});

import { createIndicator } from '~/modules/SDK/indicator/createIndicator';
export const daddy960_tc1688_turtle = createIndicator({
    id: 'daddy960-tc1688-2-1',
    displayName: 'tc1688_2-1',
    constructorScope: {
        init(context, inputCallback) {
            return;
        },
        main(context, inputCallback) {
            const close = this.ohlc.closeArray;
            close.get(100);
            const fast = 5; //this._input(0)
            const middle = 21; //this._input(1)
            const slow = 55; //this._input(2)
            const slowest = 233; //this._input(3)
            const histogram = this._input(0) < 0 || this._input(0) > 3 ? 2 : this._input(0);
            const ma233 = context.new_var(this.exponentialAverage(close, slowest));
            const ma55 = context.new_var(this.exponentialAverage(close, slow));
            const ma21 = context.new_var(this.exponentialAverage(close, middle));
            const ma5 = context.new_var(this.exponentialAverage(close, fast));
            ma233.get(100);
            ma55.get(100);
            ma21.get(100);
            ma5.get(100);
            let map22 = 0;
            let map56 = 0;
            if (histogram === 0) {
                map22 = ma5.get(0);
                map56 = ma21.get(0);
            }
            if (histogram === 1) {
                map22 = ma5.get(0);
                map56 = ma21.get(0);
            }
            if (histogram === 2) {
                map22 = ma5.get(0);
                map56 = ma21.get(0);
            }
            if (histogram === 3) {
                map22 = ma5.get(0);
                map56 = ma21.get(0);
            }
            let mapDown;
            let mapUp;
            if (histogram === 0) {
                if (ma5.get(2) >= ma21.get(2) && ma5.get(1) < ma21.get(1)) {
                    mapDown = 1;
                    mapUp = 0;
                }
                if (ma5.get(2) <= ma21.get(2) && ma5.get(1) > ma21.get(1)) {
                    mapUp = 1;
                    mapDown = 0;
                }
            }
            if (histogram === 1) {
                if (ma5.get(2) >= ma55.get(2) && ma5.get(1) < ma55.get(1)) {
                    mapDown = 1;
                    mapUp = 0;
                }
                if (ma5.get(2) <= ma55.get(2) && ma5.get(1) > ma55.get(1)) {
                    mapUp = 1;
                    mapDown = 0;
                }
            }
            if (histogram === 2) {
                if (ma21.get(2) >= ma55.get(2) && ma21.get(1) < ma55.get(1)) {
                    mapDown = 1;
                    mapUp = 0;
                }
                if (ma21.get(2) <= ma55.get(2) && ma21.get(1) > ma55.get(1)) {
                    mapUp = 1;
                    mapDown = 0;
                }
            }
            if (histogram === 3) {
                if (ma55.get(2) >= ma233.get(2) && ma55.get(1) < ma233.get(1)) {
                    mapDown = 1;
                    mapUp = 0;
                }
                if (ma55.get(2) <= ma233.get(2) && ma55.get(1) > ma233.get(1)) {
                    mapUp = 1;
                    mapDown = 0;
                }
            }
            // Show Arrows
            // if (ma55.get(2) > ma233.get(2) && ma55.get(1) < ma233.get(1)) {
            //   // Alert(Symbol.Code, "  ", Period(), "  交叉口向下 55-233")
            // }
            // if (ma55.get(2) < ma233.get(2) && ma55.get(1) > ma233.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向上 55-233')
            // }
            // if (ma21.get(2) > ma55.get(2) && ma21.get(1) < ma55.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向下 21-55')
            // }
            // if (ma21.get(2) < ma55.get(2) && ma21.get(1) > ma55.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向上 21-55')
            // }
            // if (ma5.get(2) > ma21.get(2) && ma5.get(1) < ma21.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向下 5-21')
            // }
            // if (ma5.get(2) < ma21.get(2) && ma5.get(1) > ma21.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向上 5-21')
            // }
            // if (ma5.get(2) > ma55.get(2) && ma5.get(1) < ma55.get(1) && ma5.get(1) < ma21.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向下 5-跌破 21 和 55')
            // }
            // if (ma5.get(2) < ma55.get(2) && ma5.get(1) > ma55.get(1) && ma5.get(1) > ma21.get(1)) {
            //   // Alert(Symbol.Code, '  ', Period(), '  交叉口向上 5-穿越 21 和 55')
            // }
            return [ma233.get(0), ma55.get(0), ma21.get(0), ma5.get(0), mapUp, mapDown];
        },
    },
    metainfo: {
        is_price_study: true,
        _metainfoVersion: 42,
        isTVScript: false,
        isTVScriptStub: false,
        is_hidden_study: false,
        inputs: [
            // {
            //   id: 'fast',
            //   name: 'Fast',
            //   type: 'integer',
            //   defval: 5,
            //   min: 1,
            //   max: 500,
            //   step: 1,
            // },
            // {
            //   id: 'middle',
            //   name: 'Middle',
            //   type: 'integer',
            //   defval: 21,
            //   min: 1,
            //   max: 500,
            //   step: 1,
            // },
            // {
            //   id: 'slow',
            //   name: 'Slow',
            //   type: 'integer',
            //   defval: 55,
            //   min: 1,
            //   max: 500,
            //   step: 1,
            // },
            // {
            //   id: 'slowest',
            //   name: 'Slowest',
            //   type: 'integer',
            //   defval: 233,
            //   min: 1,
            //   max: 500,
            //   step: 1,
            // },
            {
                id: 'histogram',
                name: '多空選擇',
                type: 'integer',
                defval: 1,
                min: 0,
                max: 3,
                step: 1,
            },
        ],
        plots: [
            { id: 'ma233', type: 'line' },
            { id: 'ma55', type: 'line' },
            { id: 'ma21', type: 'line' },
            { id: 'ma5', type: 'line' },
            { id: 'mapUp', type: 'chars' },
            { id: 'mapDown', type: 'chars' },
        ],
        styles: {
            ma233: {
                title: 'MA233',
                histogramBase: 0,
                joinPoints: false,
            },
            ma55: {
                title: 'MA55',
                histogramBase: 0,
                joinPoints: false,
            },
            ma21: {
                title: 'MA21',
                histogramBase: 0,
                joinPoints: false,
            },
            ma5: {
                title: 'MA5',
                histogramBase: 0,
                joinPoints: false,
            },
            mapUp: {
                title: 'Up',
                isHidden: false,
                location: 'BelowBar',
                char: '★',
                size: 'small',
                text: '',
            },
            mapDown: {
                title: 'Down',
                isHidden: false,
                location: 'AboveBar',
                char: '●',
                size: 'small',
                text: '',
            },
        },
        defaults: {
            styles: {
                ma233: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: '#Ff8c00',
                },
                ma55: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: false,
                    transparency: 0,
                    visible: false,
                    color: '#00ff00',
                },
                ma21: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: false,
                    transparency: 0,
                    visible: false,
                    color: '#1e90ff',
                },
                ma5: {
                    linestyle: 0,
                    linewidth: 2,
                    plottype: 7,
                    trackPrice: false,
                    transparency: 0,
                    visible: false,
                    color: '#FFFAFA',
                },
                mapUp: { color: '#FF0000', textColor: '#FF0000', transparency: 20, visible: true },
                mapDown: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
            },
            precision: 2,
            inputs: {
                // fast: 5,
                // middle: 21,
                // slow: 55,
                // slowest: 233,
                histogram: 1,
            },
        },
    },
});

import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase';
const symbols = [
    'XAUUSD',
    'XAGUSD',
    'HK50',
    'JP225',
    'DE40',
    'UK100',
    'US30',
    'US500',
    'USTECH100',
    'XTIUSD',
    //'XNGUSD',
    'CN50',
];
const newSymbols = ['GC-1', 'CL-1', 'NQ-1', 'YM-1', 'FDX-1', 'NK-1', 'HSI-1', 'CN-1', 'DX-1'];
const watchListGroup = 'tc1688_group_1';
export const Tc1688_SidePane1 = memo(function Tc1688_SidePane1() {
    return (<div css={css `
        ${fill_vertical_all_center};
        padding: 4px;
        height: calc(100% - 8px);
      `}>
      <SymbolPlatformBase.Display symbol={newSymbols.reverse()} watchListGroup={watchListGroup} searchListType='customized'/>
    </div>);
});

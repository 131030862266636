import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import SymbolRankingHorizontalBar from '~/modules/investment-consultant/symbolRankingChart/SymbolRankingHorizontalBar';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import IndustryCard from '../quote/IndustryCard';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { useIndustryQuoteSort } from '../quote/useIndustryQuoteSort';
import { Button } from '@mantine/core';
import LoadCard from '~/modules/symbolPlatform/LoadCard';
export const IndustryBar = memo(function IndustryBar() {
    const state = useSnapshot(staticStore);
    const [barAxis, setBarAxis] = useState('max');
    const [displayType, setDisplayType] = useState('card');
    const stockCategory = state.symbol.stockCategory;
    const symbol = stockCategory.map(s => s.index_symbol);
    useEffect(() => {
        signalrStore2.addQuote(symbol);
        return () => {
            signalrStore2.removeQuote(symbol);
        };
    }, [JSON.stringify(symbol)]);
    const value = signalrHooks2.useQuotes(useIndustryQuoteSort(symbol));
    return (<classes.container>
      <classes.header>
        <Button css={css `
            width: ${72}px;
            height: 28px;
            font-size: 13px;
            font-weight: 500;
            background-color: ${displayType === 'card' ? '#d69548' : '#353a3f'};
            color: ${displayType === 'card' ? '#eeeeee' : '#888888'};

            &:hover {
              background-color: ${displayType === 'card' ? '#d69548' : '#353a3f'};
              border: 1px solid #acacac;
            }
          `} radius='md' variant='filled' onClick={() => setDisplayType('card')}>
          圖卡
        </Button>
        <Button css={css `
            width: ${72}px;
            height: 28px;
            font-size: 13px;
            font-weight: 500;
            background-color: ${displayType === 'bar' ? '#d69548' : '#353a3f'};
            color: ${displayType === 'bar' ? '#eeeeee' : '#888888'};
            &:hover {
              background-color: ${displayType === 'bar' ? '#d69548' : '#353a3f'};
              border: 1px solid #acacac;
            }
          `} radius='md' variant='filled' onClick={() => setDisplayType('bar')}>
          比較
        </Button>
      </classes.header>
      {value.length === 0 && <LoadCard.Loading />}

      {value.length > 0 && (<classes.body>
          {displayType === 'card' && (<classes.body_.card>
              <IndustryCard.header />
              <div css={css `
                  display: flex;
                  flex-wrap: wrap;
                  gap: 2px;
                  padding: 2px;
                  overflow-y: auto;
                  overflow-x: hidden;
                  &::-webkit-scrollbar {
                    width: 4px;
                    background-color: #555;
                  }
                  &::-webkit-scrollbar-thumb {
                    width: 4px;
                    border-radius: 5px;
                    background-color: #ccc;
                  }
                `}>
                {value.map(s => {
                    return (<IndustryCard.body quote={s} key={s.symbol}/>);
                })}
              </div>
            </classes.body_.card>)}
          {displayType === 'bar' && (<classes.body_.bar>
              <classes.title>成交值</classes.title>
              <div css={css `
                  height: 48%;
                  width: 100%;
                `}>
                <SymbolRankingHorizontalBar.Display symbols={stockCategory.map(s => s.index_symbol) ?? []} type={'volume'} fontSize={13} nameLabelWidth={48} barSize={18}/>
              </div>
              <classes.title>漲跌幅</classes.title>
              <div css={css `
                  height: 48%;
                  width: 100%;
                `}>
                <SymbolRankingHorizontalBar.Display symbols={stockCategory.map(s => s.index_symbol) ?? []} type={'changePercent'} fontSize={13} nameLabelWidth={48} barSize={18} changePercent={barAxis}/>
              </div>
            </classes.body_.bar>)}
        </classes.body>)}
    </classes.container>);
});
const classes = {
    container: styled.div `
    ${fill_vertical_cross_center};
    gap: 2px;
  `,
    header: styled.div `
    ${fill_horizontal_cross_center};
    height: 30px;
    font-size: 14px;
    gap: 4px;
  `,
    title: styled.div `
    ${fill_horizontal_all_center};
    height: 26px;
    background-color: #343948;
    border-radius: 4px;
    font-size: 14px;
  `,
    body: styled.div `
    width: 100%;
    height: calc(100% - 32px);
  `,
    body_: {
        card: styled.div `
      ${fill_vertical_cross_center};
      gap: 4px;
    `,
        bar: styled.div `
      ${fill_vertical_cross_center};
      gap: 2px;
    `,
    },
};

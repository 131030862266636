import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { useSnapshot } from 'valtio';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
import { useState, useEffect } from 'react';
import useMedia from '~/hooks/useMedia';
import { Lung88988Stock_SidePane_ForOnlyMobile } from '~/pages/lung88988_stock/_private/Component/lung88988Stock_SidePane_ForOnlyMobile';
export const Styleds = {
    SideBar: styled.div `
    ${flex.v.crossCenter};
    width: 100%;
    height: calc(100% - 0px);
    background-color: #1e1e1e;
    border-radius: 5px;
    padding: 8px;
    font-size: 16px;
    gap: 4px;
  `,
    SidebarTitle: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    background-color: #3a3a3a;
    font-size: 16px;
    border-radius: 5px;
  `,
    TrendChartContainer: styled.div `
    width: 100%;
    height: 170px;
  `,
    StrategyButtonGroup: styled.div `
    ${flex.h.allCenter}
    width:100%;
    height: 26px;
    gap: 4px;
  `,
    PositionButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 26px;
    gap: 4px;
  `,
    ScreenerTypeButtonGroup: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 30px;
    gap: 4px;
  `,
    SymbolListContainer: styled.div `
    width: 100%;
    height: calc(100% - 400px);
    border: 1px solid #555555;
    border-radius: 3px;
  `,
    SymbolListContainerForOnlyMobile: styled.div `
    width: 100%;
    height: calc(100% - 30px);
    border: 1px solid #555555;
    border-radius: 3px;
  `,
    DataChartContainer: styled.div `
    width: 100%;
    height: 60px;
  `,
    SymbolNameContainer: styled.div `
    ${flex.h.allCenter}
    width: 100%;
    height: 30px;
    background-color: #333333;
    border-radius: 5px;
  `,
    SymbolInfoContainer: styled.div `
    ${flex.h.allCenter};
    width: 100%;
    height: 150px;
  `,
    SymbolRankingGroup: styled.div `
    width: 100%;
    height: 70px;
  `,
    WatchListAddContainer: styled.div `
    ${flex.h.allCenter};
    ${pureGrid};
    width: 100%;
    height: 30px;
    grid-template-columns: 40% 60%;
  `,
    Charting() {
        const me = useSnapshot(fr_me);
        const [moblieUser, setMoblieUser] = useState(false);
        const { isPc, isPhone } = useMedia();
        useEffect(() => {
            const tutorialProduct = me?._ourMe?.subscriptions.find(s => s.agentName === 'lung889881688' && s.productName === 'web_stock_moblie');
            if (tutorialProduct && !tutorialProduct.isExpired) {
                // 如果是手機專用版使用者就顯示當沖策略
                setMoblieUser(true);
            }
            else {
                setMoblieUser(false);
            }
        }, [me]);
        if (isPhone && moblieUser) {
            return (<div css={css `
            display: grid;
            height: 100%;
            grid-template-rows: calc(100% - 350px) 350px;
          `}>
          <div css={css `
              ${flex.v.allCenter};
              margin-top: 4px;
              height: calc(100% - 8px);
              border-radius: 5px;
            `}>
            <Charting />
          </div>
          <div css={css `
              ${flex.v.allCenter};
              margin-top: 4px;
              height: 100%;
              border-radius: 5px;
            `}>
            <Lung88988Stock_SidePane_ForOnlyMobile />
          </div>
        </div>);
        }
        return (<div css={css `
          ${flex.v.allCenter};
          margin-top: 4px;
          height: calc(100% - 8px);
          border-radius: 5px;
        `}>
        <Charting />
      </div>);
    },
};

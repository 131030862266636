import { memo } from 'react';
import styled from '@emotion/styled';
export const BitPixelBorder = memo(function bitPixelBorder(props) {
    const { backgroundColor = 'rgb(255, 215, 137)' } = props;
    return (<styleds.box>
        <styleds.lineTop backgroundColor={backgroundColor}/>
        <styleds.lineBottom backgroundColor={backgroundColor}/>
        <styleds.lineLeft backgroundColor={backgroundColor}/>
        <styleds.lineRight backgroundColor={backgroundColor}/>
        {props.children}
      </styleds.box>);
});
const lineWidth = '4px';
const boxLine = (Color) => `
  position: absolute;
  background: ${Color};
`;
const styleds = {
    box: styled.div `
    position: relative;
    width: 95%;
    /* height: 100%; */
    margin: 8px;
    margin-bottom: 10px;
  `,
    lineTop: styled.div `
    ${({ backgroundColor }) => boxLine(backgroundColor)}
    width: 100%;
    height: ${lineWidth};
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: ${lineWidth};
      height: ${lineWidth};
      background: ${({ backgroundColor }) => backgroundColor};
    }
    top: -${lineWidth};
    &::before {
      left: 0;
      bottom: -${lineWidth};
    }
    &::after {
      right: 0;
      bottom: -${lineWidth};
    }
  `,
    lineBottom: styled.div `
    ${({ backgroundColor }) => boxLine(backgroundColor)}
    width: 100%;
    height: ${lineWidth};
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: ${lineWidth};
      height: ${lineWidth};
      background: ${({ backgroundColor }) => boxLine(backgroundColor)};
    }
    bottom: -${lineWidth};
    &::before {
      left: 0;
      top: -${lineWidth};
    }
    &::after {
      right: 0;
      top: -${lineWidth};
    }
  `,
    lineLeft: styled.div `
    ${({ backgroundColor }) => boxLine(backgroundColor)}
    width: ${lineWidth};
    height: 100%;
    left: -${lineWidth};
  `,
    lineRight: styled.div `
    ${({ backgroundColor }) => boxLine(backgroundColor)}
    width: ${lineWidth};
    height: 100%;
    right: -${lineWidth};
  `,
};

import { Progress } from '@mantine/core';
import { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import styled from '@emotion/styled';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { store } from '~/pages/heineken_template/_private/store';
import { useAdvanceDeclineResource2 } from '~/modules/screener/containers/useStockScreenerResource';
const MarketItem = memo(function MarketItem(props) {
    const data = props.data;
    return (<div css={css `
        width: 100%;
        display: grid;
        grid-template-rows: 20px 22px 8px;
        font-size: 13px;
        padding: 2px 4px;
      `}>
      <div>{data.label}</div>
      <div css={css `
          ${fill_horizontal_cross_center};
          align-items: end;
          ${jc.spaceBetween};
        `}>
        <span>{data.count}</span>
        <span css={css `
            font-size: 13px;
            color: ${data.color};
          `}>
          {data.percent.toFixed(1)}%
        </span>
      </div>
      <div>
        <Progress css={css `
            margin-top: 4px;
          `} value={100} color={data.color} size={2} radius={'xs'}/>
      </div>
    </div>);
});
export const MarketView = memo(function MarketView(props) {
    const market = props.market;
    const marketChinses = market === 'TSEA' ? '上市' : market === 'OTCA' ? '上櫃' : '上市櫃';
    const origindata = useAdvanceDeclineResource2();
    const riseData = origindata.data?.find(s => s.name === marketChinses);
    const riseCount2 = riseData?.count || {};
    // 計算大於零和小於零的總和
    const riseCount = Object.entries(riseCount2)
        .filter(([k, v]) => parseInt(k) > 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0);
    const fallCount = Object.entries(riseCount2)
        .filter(([k, v]) => parseInt(k) < 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0);
    const flatCount = Object.entries(riseCount2)
        .filter(([k, v]) => parseInt(k) === 0)
        .map(([k, v]) => v)
        .reduce((a, b) => a + b, 0);
    const overallCount = fallCount + flatCount + riseCount;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const data = [
        {
            label: '上漲家數',
            count: riseCount,
            percent: (riseCount / overallCount) * 100,
            color: '#f7372c',
        },
        {
            label: '平盤家數',
            count: flatCount,
            percent: (flatCount / overallCount) * 100,
            color: '#acacac',
        },
        {
            label: '下跌家數',
            count: fallCount,
            percent: (fallCount / overallCount) * 100,
            color: '#2cd88d',
        },
    ];
    //單一呼叫signalr
    useEffect(() => {
        signalrStore2.addQuote(market);
        return () => {
            signalrStore2.removeQuote(market);
        };
    }, [market]);
    const value = useSnapshot(signalrStore2.values.quote)[market];
    const quoteChanges = processQuoteToReadable(value);
    const close = value?.close ?? 0;
    const change = quoteChanges.closeChange ?? 0;
    const changePercent = quoteChanges.closeChangePercent ?? 0;
    const color = change > 0 ? '#f7372c' : change < 0 ? '#2cd88d' : '#ffffff';
    const changeSymbol = change > 0 ? '+' : '';
    return (<div css={css `
        display: grid;
        grid-template-rows: 38px 26px 60px;
        height: 100%;
        padding: 4px;
        background-color: #2d323c;
        border-radius: 6px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          background-color: #3a3f4b;
        }
      `} onClick={() => {
            store.charting.changeSymbol(market);
        }}>
      <div css={css `
          ${fill_horizontal_cross_center};
          ${jc.spaceBetween};
        `}>
        <span css={css `
            font-size: 15px;
            font-weight: 600;
          `}>
          {dictionary[market]} {market}
        </span>

        <div css={css `
            ${fill_horizontal_cross_center};
            width: auto;
            height: 20px;
            color: ${color};
            font-size: 13px;
            gap: 12px;
          `}>
          <span css={css `
              font-size: 14px;
            `}>
            {close}
          </span>
          <span>
            {changeSymbol}
            {change}
          </span>
          <span>
            {changeSymbol}
            {changePercent}%
          </span>
        </div>
      </div>

      <div css={css `
          width: 100%;
          height: 100%;
          padding: 2px;
        `}>
        <Progress css={css `
            margin-top: 0rem;
          `} mt='md' size={18} sections={[
            {
                value: data[0].percent,
                color: '#ce423a',
                label: data[0].percent.toFixed(1) + '%',
            },
            {
                value: data[1].percent,
                color: '#acacac',
                label: data[1].percent.toFixed(1) + '%',
            },
            {
                value: data[2].percent,
                color: '#41906b',
                label: data[2].percent.toFixed(1) + '%',
            },
        ]}/>
      </div>
      <div css={css `
          ${fill_horizontal_all_center};
          width: 100%;
          height: 100%;
          gap: 8px;
          border-radius: 4px;
        `}>
        {data.map((item, index) => {
            return (<MarketItem key={index} data={item}/>);
        })}
      </div>
    </div>);
});
const styleds = {
    container: styled.div ``,
};
